import React, { useContext, useState } from "react";
import { useEffect } from "react";
import "./TermsConditions.css";
import "./TermsConditions-responsive.css";
import Layout from "../../components/Layout";
import { Footer } from "../../components/Footer";
import TagManager from 'react-gtm-module'

const TermsConditions = () => {
    
    useEffect(() => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'pageview',
            userProject: 'skillity',
            page: `terms-and-conditions`
          }
        });
      }, []);

    return (
        <div>
            <Layout />
            <div className="cli--tal-pro-search-section">
                <div className="container-fluid">
                    <div className="container-fluid container-section">
                        <div className="custom--container tal--pro-search">
                            <div className="breadcrumb--area-dark" data-aos="fade-down">
                                <div className="breadcrumb--item-dark">
                                    <a href="/client-home">Home</a>
                                </div>
                                <div className="breadcrumb--item-dark">
                                    <p>Terms And Conditions</p>
                                </div>
                            </div>

                            <div className="terms-con-section">
                                <div className="terms-con-area">
                                    <div className="terms-con-title"><span>Terms & Conditions</span></div>

                                    <div className="terms-con-note-area m-t-35">
                                        <div className="terms-con-sub-title">
                                            TERMS OF USE OF WEBSITE
                                        </div>
                                        <div className="terms-con-updated mt-4">
                                            Date Of Last Revision&nbsp;:&nbsp;<span>03-04-2024</span>
                                        </div>
                                        <div className="terms-con-note mt-4">
                                            * Please read these “Terms and Conditions” carefully. By using this website, you agree to be bound by all of these terms and conditions.
                                        </div>
                                    </div>

                                    <div className="terms-con-group m-t-35 mt-sm-5">
                                        <div className="terms-con-head">01. Introduction</div>
                                        <p className="terms-con-content">
                                            These Terms and Conditions ("Terms & Conditions") govern your use of www.skillety.com (the “Website”) owned by M/s. Skillety Technologies Private Limited(the “Owner”). These Terms & Conditions include and incorporate the references, policies and guidelines. Owner reserves the right to change or revise the Terms & Conditions at any time by posting any changes or revised Terms & Conditions on this website. The changed or revised Terms & Conditions shall be effective from the moment it is posted on the Website. Your use of the website following the posting any such changes or of a revised Terms & Conditions shall constitute your acceptance of any such changes or revisions. The Owner encourages you to review these Terms & Conditions whenever you visit the Website to make sure that you understand the terms and conditions governing this website. These Terms & Conditions do not alter in any way the terms and conditions of any other written agreement you may have with the Owner for other products or services. If you do not agree to these Terms & Conditions (including any referenced policies or guidelines), please immediately terminate your use of the Website.
                                        </p>
                                        <p className="terms-con-content">
                                            For the purpose of these Terms & Conditions, wherever the context so requires “You” or “User” shall mean any natural or legal person who has agreed to use this Website by providing registration data while registering on the Website as a registered user. A user may surf this Website without registering on the Website but they might not have full access of the Website.
                                        </p>
                                        <p className="terms-con-content">
                                            The premier platform for connecting both employers and candidates through our comprehensive CV database and recruitment services. Skillety is designed to streamline the hiring process for employers and empower candidates in their job search journey.
                                        </p>
                                        <p className="terms-con-content">
                                            For Employers/Clients, Skillety offers a range of services tailored to meet your recruitment needs. You can register, create company profiles, access our extensive CV database, manage services via a user-friendly dashboard, conduct interviews, perform background verifications, and more. Our goal is to provide you with the tools and resources necessary to find the best talent efficiently and effectively.
                                        </p>
                                        <p className="terms-con-content">
                                            For Talent/Candidates/Job Seekers, Skillety provides a user-friendly platform to register, submit your details, and connect with potential employers. You can browse job listings, apply for positions, and showcase your skills and experience to attract employers. Our aim is to support you in finding the right opportunities to advance your career. Please note that while basic registration and job application services are currently offered free of charge, paid services, including but not limited to Resume Writing, Star Rating, Verified CV, etc., are anticipated to be introduced in the near future.
                                        </p>
                                        <p className="terms-con-content">
                                            At Skillety, we are committed to providing a seamless and efficient experience for both employers and candidates. By facilitating meaningful connections and fostering successful professional relationships, we strive to create value for all users of our platform.
                                        </p>
                                    </div>

                                    <div className="terms-con-group">
                                        <div className="terms-con-head">
                                            02. Acceptance of Terms
                                        </div>
                                        <p className="terms-con-content-sub">
                                            By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use the Services. These Terms apply to all visitors, users, and others who access or use the Services.
                                        </p>
                                    </div>

                                    <div className="terms-con-group">
                                        <div className="terms-con-head">
                                            03. Use of Services
                                        </div>
                                        <p className="terms-con-content">
                                            You must be 18 years or older to use our Services. By using our Services, you represent and warrant that you are at least 18 years old.
                                        </p>
                                        <p className="terms-con-content">
                                            You agree to use the Services only for lawful purposes and in accordance with these Terms and any applicable laws and regulations.
                                        </p>
                                        <p className="terms-con-content">
                                            You agree not to use the Services in any way that could damage, disable, overburden, or impair our servers or networks or interfere with any other party's use and enjoyment of the Services.
                                        </p>
                                        <p className="terms-con-content">
                                            You agree not to attempt to gain unauthorized access to any portion or feature of the Services or any other systems or networks connected to the Services.
                                        </p>
                                        <p className="terms-con-content">
                                            We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders at our sole discretion.
                                        </p>
                                    </div>

                                    <div className="terms-con-group">
                                        <div className="terms-con-head">
                                            04. Membership Eligibility
                                        </div>
                                        <p className="terms-con-content">
                                            You affirm that you are either more than 18 years of age, or an emancipated minor, or possess legal parental or guardian consent, and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in these, and to abide by and comply with these Terms & Conditions.
                                        </p>

                                        <p className="terms-con-content">
                                            You agree that you have provided true, accurate, current and complete information to the Owner in connection with any use or access of the Service.
                                        </p>
                                    </div>

                                    <div className="terms-con-group">
                                        <div className="terms-con-head">
                                            05. Account and Registration Obligations
                                        </div>
                                        <p className="terms-con-content-sub">
                                            If you use this Website, you shall be responsible for maintaining the confidentiality of your Account, including but not limited to password and you shall be responsible for all activities that occur from your account. You agree that if you provide any information that is untrue, inaccurate, not current or incomplete or Website has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with these Terms & Conditions, Website shall have the right to indefinitely suspend or terminate or block access of your membership on the Website and refuse to provide you with access to the Website.
                                        </p>
                                    </div>

                                    <div className="terms-con-group">
                                        <div className="terms-con-head">
                                            06. Subscriptions - Employer’s Account Users
                                        </div>
                                        <p className="terms-con-content">
                                            Skillety offers subscription-based services to registered employer users, including access to the CV database and additional recruitment services. By utilizing these services, users agree to abide by the terms and conditions outlined in these Terms & Conditions and any specific terms related to the purchased subscriptions.
                                        </p>
                                        <p className="terms-con-content">
                                            Payment for subscription services must be made in full before access is granted. Skillety reserves the right to change the prices of these services at any time without prior notice.
                                        </p>
                                        <p className="terms-con-content">
                                            Skillety does not have a refund policy for subscription services. However, if any purchased inventory is not fully utilized within the validity period, unused inventory will be carried over to the Renewal Package.
                                        </p>
                                        <p className="terms-con-content">
                                            Registration and basic job search functionalities are free for Talent/Candidates/Job Seekers. Subscription-based services, such as CV enhancement tools or priority listing, are available for additional fees. The features and pricing of these paid services will be clearly outlined on the platform.
                                        </p>
                                    </div>

                                    <div className="terms-con-group">
                                        <div className="terms-con-head">
                                            07. Payment Terms and Packages for Employers
                                        </div>
                                        <p className="terms-con-content-sub">
                                            Skillety offer three types of packages: Quick Buy (Pre-defined) Packages, DIY (Do It Yourself) Packages, and Value- Added Services packages.
                                        </p>
                                        <div className="term-con-table-area">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-lg">
                                                    <thead>
                                                        <tr>
                                                            <th>Package Type</th>
                                                            <th>Features</th>
                                                            <th>Test</th>
                                                            <th>Start</th>
                                                            <th>Scale</th>
                                                            <th>Grow</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>CV Views</td>
                                                            <td></td>
                                                            <td>20</td>
                                                            <td>100</td>
                                                            <td>300</td>
                                                            <td>700</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Login ID</td>
                                                            <td></td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                            <td>1</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Active Jobs</td>
                                                            <td></td>
                                                            <td>1</td>
                                                            <td>5</td>
                                                            <td>10</td>
                                                            <td>15</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Validity in Days</td>
                                                            <td></td>
                                                            <td>1</td>
                                                            <td>7</td>
                                                            <td>15</td>
                                                            <td>30</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Real Price</td>
                                                            <td></td>
                                                            <td>1200</td>
                                                            <td>2000</td>
                                                            <td>3500</td>
                                                            <td>6000</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Inaugural Offer Price</td>
                                                            <td></td>
                                                            <td>199</td>
                                                            <td>999</td>
                                                            <td>2499</td>
                                                            <td>4999</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="term-con-table-title">
                                            DIY (Do It Yourself) Package
                                        </div>

                                        <div className="term-con-table-area">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-lg">
                                                    <thead>
                                                        <tr>
                                                            <th>Services</th>
                                                            <th className="text-center">Quantity</th>
                                                            <th className="text-right">Total Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>CV Views</td>
                                                            <td className="text-center">0</td>
                                                            <td className="text-right">-</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Login IDs</td>
                                                            <td className="text-center">0</td>
                                                            <td className="text-right">-</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Validity (in months)</td>
                                                            <td className="text-center">0</td>
                                                            <td className="text-right">-</td>
                                                        </tr>

                                                        <tr>
                                                            <td>DIY Package Price</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-right">0.00</td>
                                                        </tr>

                                                        <tr>
                                                            <td>GST (18%)</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-right">0.00</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Total Amount Payable</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-right">0.00</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="term-con-table-title">
                                            Value Added Services
                                        </div>

                                        <div className="term-con-table-area">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-lg">
                                                    <thead>
                                                        <tr>
                                                            <th>Services</th>
                                                            <th className="text-center">Quantity</th>
                                                            <th className="text-right">Total Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Online Technical Assessment</td>
                                                            <td className="text-center">0</td>
                                                            <td className="text-right">-</td>
                                                        </tr>

                                                        <tr>
                                                            <td>L1 Interview (0-8 yrs)</td>
                                                            <td className="text-center">0</td>
                                                            <td className="text-right">-</td>
                                                        </tr>

                                                        <tr>
                                                            <td>L1 Interview (8-15 yrs)</td>
                                                            <td className="text-center">0</td>
                                                            <td className="text-right">-</td>
                                                        </tr>

                                                        <tr>
                                                            <td>L1 Interview (>15 yrs)</td>
                                                            <td className="text-center">0</td>
                                                            <td className="text-right">-</td>
                                                        </tr>

                                                        <tr>
                                                            <td>BGV Comprehensive</td>
                                                            <td className="text-center">0</td>
                                                            <td className="text-right">-</td>
                                                        </tr>

                                                        <tr>
                                                            <td>GST (18%)</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-right">0.00</td>
                                                        </tr>

                                                        <tr>
                                                            <td>Total Amount Payable</td>
                                                            <td className="text-center"></td>
                                                            <td className="text-right">0.00</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="term-con-table-title">
                                            Payment Terms
                                        </div>

                                        <div className="terms-con-list-area">
                                            <ul>
                                                <li className="terms-con-list-item">
                                                    When purchasing a package or service from Skillety, the applicable fees must be paid in full before accessing the services.
                                                </li>
                                                <li className="terms-con-list-item">
                                                    Skillety does not have a refund policy.
                                                </li>
                                                <li className="terms-con-list-item">
                                                    If the total purchased inventory is not fully utilized by an Employer within the validity period, the unused inventory will be carried over to the Renewal Package.
                                                </li>
                                                <li className="terms-con-list-item">
                                                    When a client buys the initial/first package, his account is created in Skillety for life unless he purposely deletes his account by himself.
                                                </li>
                                                <li className="terms-con-list-item">
                                                    In this account, he can buy any service/value-added service at any point in time.
                                                </li>
                                                <li className="terms-con-list-item">
                                                    There is no validity for VAS.
                                                </li>
                                                <li className="terms-con-list-item">
                                                    For Clients, all Jobs will be valid until the validity of his package expires. If he renews it, it will again become active.
                                                </li>
                                                <li className="terms-con-list-item">
                                                    For Clients, the services and VAS will be accessible only if they have a live package running with Skillety. If expired, they can log in, but cannot use any service/VAS.
                                                </li>
                                                <li className="terms-con-list-item">
                                                    Clients can buy any single service or any combo from the DIY packages. So, if a Client has runout on the CV Views he purchased, at any point of time, he can buy only CV Views for whatever period he wishes.
                                                </li>
                                                <li className="terms-con-list-item">
                                                    The test package is equivalent to a demo. They can buy/upgrade to Test, Start, Scale, or Grow or DIY. The system will track the user through their Email and Mobile number.
                                                </li>
                                                <li className="terms-con-list-item">
                                                    GST will be charged extra as applicable.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="terms-con-group">
                                        <div className="terms-con-head">
                                            08. Third Party Information on the Website
                                        </div>
                                        <p className="terms-con-content">
                                            As some information appearing on this Website is provided by third parties, the Owner will have no liability in respect of any loss or damage arising from third party information which appears on the Website including the manner in which the information is displayed or information which may be -
                                        </p>

                                        <div className="terms-con-list-area">
                                            <ul>
                                                <li className="terms-con-list-item">
                                                    Out of date.
                                                </li>

                                                <li className="terms-con-list-item">
                                                    Inaccurate.
                                                </li>

                                                <li className="terms-con-list-item">
                                                    Duplicate.
                                                </li>

                                                <li className="terms-con-list-item">
                                                    Impersonation by a person to be another person.
                                                </li>
                                            </ul>
                                        </div>

                                        <p className="terms-con-content">
                                            The Owner is not an agent for any of the Merchants listed on Website and the Owner has no responsibility for and no liability whatsoever in respect of the conduct of a Merchant or quality of service provided by a Merchant.
                                        </p>
                                    </div>

                                    <div className="terms-con-group">
                                        <div className="terms-con-head">
                                            09. Links to Other Sites
                                        </div>
                                        <p className="terms-con-content">
                                            The Website may include links to other websites. Some of these websites may contain materials that are objectionable, unlawful, or inaccurate. These links are provided for convenience only and Website does not endorse these websites or products and services they provide.
                                        </p>
                                    </div>

                                    <div className="terms-con-group">
                                        <div className="terms-con-head">
                                            10. Restrictions on use
                                        </div>
                                        <p className="terms-con-content">
                                            In addition to these Terms & Conditions, user shall not -
                                        </p>

                                        <div className="terms-con-list-area">
                                            <ul>
                                                <li className="terms-con-list-item">
                                                    Use the third party links to sites without agreeing to their website terms and conditions.
                                                </li>

                                                <li className="terms-con-list-item">
                                                    Post links to third party websites or use their logo, company name etc. without their prior written permission.
                                                </li>

                                                <li className="terms-con-list-item">
                                                    Use the services for spamming and other illegal purposes.
                                                </li>

                                                <li className="terms-con-list-item">
                                                    Impersonate any person or entity, falsely claim or otherwise misrepresent an affiliation with any person or entity, or access the accounts of others without their permission, forge another persons' digital signature, misrepresent the source, identity, or access the accounts of others without permission, forge another persons' digital signature, misrepresent the source, identity, or content of information transmitted via the services, perform any other similar fraudulent activity or otherwise purchase product or service which we reasonably believe to be potentially fraudulent.
                                                </li>

                                                <li className="terms-con-list-item">
                                                    Infringe our or any third party's Intellectual Property rights, rights of publicity or privacy.
                                                </li>

                                                <li className="terms-con-list-item">
                                                    Use the service if you are below the age of 18 without an adult supervision or in any event use the services if you are under the age of 13 even with an adult supervision and in accordance with applicable law.
                                                </li>

                                                <li className="terms-con-list-item">
                                                    Post or transmit any message, data, image or program which is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever, or unlawfully threatening or unlawful harassing including  but not limited to "Indecent Representation of Women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986.
                                                </li>

                                                <li className="terms-con-list-item">
                                                    Refuse to cooperate in any investigation or provide confirmation of identity or any other information as asked by the Owner.
                                                </li>

                                                <li className="terms-con-list-item">
                                                    Remove, circumvent, disable, damage or otherwise interfere with security-related features of the service and the Website or features that enforce limitations in the use of the services.
                                                </li>

                                                <li className="terms-con-list-item">
                                                    Use the service in any manner that could damage, disable, overburden, or impair it, including without limitation, using the services in an automated manner;
                                                </li>

                                                <li className="terms-con-list-item">
                                                    Use any robot, spider, other automatic device, or manual process to monitor or copy the Website without prior written permission;
                                                </li>

                                                <li className="terms-con-list-item">
                                                    Interfere or disrupt this Website or networks connected to this Website;
                                                </li>

                                                <li className="terms-con-list-item">
                                                    Forge headers or manipulate identifiers or other data in order to disguise the origin of any content transmitted through our Website or to manipulate your presence on your Website;
                                                </li>

                                                <li className="terms-con-list-item">
                                                    Use Website to collect or obtain personal information, including but not limited to financial information, about other users of the Website.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="terms-con-group">
                                        <div className="terms-con-head">11. Intellectual Property Rights</div>
                                        <p className="terms-con-content">
                                            The services and Website are owned and operated by M/s Skillety Technologies Private Limited. The visual interfaces, graphics, design, compilation, information, computer code (including source code and object code), products, software, services, and all other elements of the services and the Website provided by the Owner ("Materials") are protected by Indian Copyright, trade dress, Patent and Trademark laws, international conventions and all other Intellectual Property and proprietary rights and applicable laws. As between you and the Owner, all Materials, Trademarks, service marks, and trade names contained on the Website are the Property of the Owner and/or third party licensors or suppliers.
                                        </p>

                                        <p className="terms-con-content">
                                            You agree not to remove, obscure, or alter the Owner's or any third party's Copyright, Patent, Trademark, or other proprietary right notices affixed to or contained within or accessed in conjunction with or through the services. Except as expressly authorized by the Owner, you agree not to sell, license, distribute, copy, modify, publicly perform or display, transmit, publish, edit, adapt, create derivative works from, or otherwise make unauthorized use of the Materials. The Owner reserves all rights not expressly granted in these Terms & Conditions.
                                        </p>
                                    </div>

                                    <div className="terms-con-group">
                                        <div className="terms-con-head">12. Disclaimer of Warranties & Liability</div>
                                        <p className="terms-con-content">
                                            The Website, services, content, user content and any third party content are provided by the Owner on an "As is" basis without warranty of any kind, express, implied, statutory or otherwise, including the implied warranties of title, non-infringement, Merchantability or fitness for a particular purpose, without limiting the foregoing, the Owner makes no warranty that -
                                        </p>

                                        <div className="terms-con-list-area">
                                            <ul>
                                                <li className="terms-con-list-item">
                                                    The Website or the services will meet your requirements or your use of the Website or the services will be uninterrupted, timely, secure or error-free.
                                                </li>

                                                <li className="terms-con-list-item">
                                                    The results that may be obtained from the use of the Website or services will be effective accurate or reliable.
                                                </li>

                                                <li className="terms-con-list-item">
                                                    The quality of the Website or services will meet your expectations; or
                                                </li>

                                                <li className="terms-con-list-item">
                                                    Any errors or defects in the Website through the Website/Content or from use of the services shall create any warranty not expressly stated in these Terms & Conditions.
                                                </li>
                                            </ul>
                                        </div>

                                        <p className="terms-con-content">
                                            To the maximum extent permitted by applicable law, the Owner will have no liability related to user content and/or third party content arising under Intellectual Property Rights, libel, privacy, publicity, obscenity or other laws, the Owner disclaims all liability with respect to the misuse, loss, modification or unavailability of any user content and/or third party content.
                                        </p>

                                        <p className="terms-con-content">
                                            12.3.	You expressly understand and agree that, to the maximum extent permitted by applicable law the Owner will not be liable for any loss that you may incur as a consequence of unauthorized use of your account or account information in connection with the Website or any services, either with or without your knowledge, the Owner has endeavoured to ensure that all the information on the Website is correct, but the Owner neither warrants nor makes any representations regarding the quality, accuracy or completeness of any data, information, software, products, functionalities, services and related graphics obtained through the Website, or otherwise arising out of the use of this Website, whether based on contract, tort, negligence, strict liability or otherwise, further, the Owner shall not be held responsible for non-availability of the Website during periodic maintenance operations or any unplanned suspension of access to the Website that may occur due to technical reasons or any other reasons beyond the Owner's control. The user understands and agrees that any material or data downloaded or otherwise obtained through the Website is done entirely at their own discretion and risk and they will be solely responsible for any damage to their computer systems or loss of data that results from the download of such material or data. The Owner accepts no liability for any errors or omissions, with respect to any information provided to you whether on behalf of itself or third parties.
                                        </p>

                                    </div>

                                    <div className="terms-con-group">
                                        <div className="terms-con-head">13. Indemnification and Limitation of Liability </div>
                                        <p className="terms-con-content">
                                            You agree to indemnify, defend and hold harmless the Owner, its subsidiaries, affiliates, vendors, agents and their respective directors, officers, employees, contractors and agents (herein after individually and collectively referred to as "indemnified parties") from and against any and all losses, liabilities, claims, suits, proceedings, penalties, interests, damages, demands, costs and expenses (including legal and other statutory fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by the indemnified parties that arise out of, result from, or in connection with (i) Your breach of the Agreement(s); or (ii) any claims made by any third party due to, or arising out of, or in connection with, Your use of the Website; or (iii) any claim that any Third Party Content, content, information or materials provided by You caused damage to a third party; or (iv) Your violation of any rights of another, including any Intellectual Property rights.
                                        </p>

                                        <p className="terms-con-content">
                                            The Owner may notify you of any claims which you shall be liable to indemnify against. You will then be required to consult with the Owner regarding the course of action to be undertaken in defending such a claim. Further, you shall not compromise or settle any claim or admit any liability or wrongdoing on the part of the Owner without the express prior written consent of the Owner which can be withheld or denied or conditioned by the Owner in its sole discretion.
                                        </p>

                                        <p className="terms-con-content">
                                            Notwithstanding anything to contrary in the agreement(s), M/s Skillety Technologies Private Limited's entire and aggregate liability to you under and in relation to these Terms & Conditions or otherwise shall not exceed the greater of Indian Rupees One Hundred (INR 100) or the amount of fees, if any, paid by you to the Owner under the relevant order to which the cause of action for the liability relates.
                                        </p>

                                        <p className="terms-con-content">
                                            By purchasing and using the subscription services offered by Skillety, the registered employer category user agrees to indemnify and hold harmless Skillety, its affiliates, officers, directors, employees, and agents from and against any claims, losses, damages, liabilities, and expenses (including legal fees) arising out of or in connection with the user's purchase or use of the subscription services.
                                        </p>

                                        <p className="terms-con-content">
                                            Notwithstanding anything to contrary in the agreement(s), in no event shall the Owner, its subsidiaries or affiliates and their respective officers, directors, employees, partners or suppliers be liable to You for any special, incidental, indirect, consequential, exemplary or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable or whether or not the Owner has been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortious action, or any other claim arising out of or in connection with Your use of or access to the Website, services or content.
                                        </p>
                                    </div>

                                    <div className="terms-con-group">
                                        <div className="terms-con-head">14.	Governing Law and Jurisdiction</div>
                                        <p className="terms-con-content">
                                            These Terms & Conditions and all transactions entered into on or through the Website and the relationship between you and the Owner shall be governed in accordance with the laws of India without reference to conflict of laws principles.
                                        </p>

                                        <p className="terms-con-content">
                                            You agree that all claims, differences and disputes arising under or in connection with or in relation hereto the Website, these Terms & Conditions, the agreement(s) or any transactions entered into on or through the Website or the relationship between you and the Owner shall be subject to the exclusive jurisdiction of the Courts at Secunderabad, Telangana and you hereby accede to and accept the jurisdiction of such Courts. Each party hereby irrevocably waives any objection which such party may now or hereafter have to the laying of improper venue or forum non convenient. Each party agrees that a judgment in any such action or proceeding may be enforced in other jurisdictions by suit on the judgment or in any manner provided by law. Any and all service of process and any other notice in any such suit, action or proceeding with respect to this agreement shall be effective against a party if given as provided herein.
                                        </p>
                                    </div>

                                    <div className="terms-con-group">
                                        <div className="terms-con-head">15.	General Provisions</div>
                                        <p className="terms-con-content">
                                            Notice - All notices with respect to these Terms & Conditions from the Owner will be served to you by email or by general notification on the Website. Any notice provided to the Owner pursuant to these Terms & Conditions should be sent to Grievance Officer at Plot No. 45, Sarvasukhi Colony, West Marredpally, Secunderabad,, Hyderabad, Telangana, 500026.
                                        </p>

                                        <p className="terms-con-content">
                                            Assignment - You cannot assign or otherwise transfer the agreements, or any rights granted hereunder or any obligations, to any third party and any such assignment or transfer or purported assignment or transfer shall be void ab initio. M/s Skillety Technologies Private Limited's rights and/or obligations under the Terms & Conditions are freely assignable or otherwise transferable by the Owner to any third parties without the requirement of seeking your prior consent. The Owner may inform you of such assignment or transfer in accordance with the notice requirements under the agreement. The Owner shall have right to transfer your Account and Account Information to a third party who purchases M/s Skillety Technologies Private Limited's business as conducted under the Website.
                                        </p>

                                        <p className="terms-con-content">
                                            Severability - If, for any reason, a Court of competent jurisdiction finds any provision of these Terms & Conditions, or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to give effect to the intent of the parties as reflected by that provision, and the remainder of the Terms & Conditions shall continue in full force and effect. The Owner may amend in a reasonable manner such provision to make it enforceable and such amendment will be given effect in accordance with the amendment terms of these Terms & Conditions.
                                        </p>

                                        <p className="terms-con-content">
                                            Waiver - Any failure or delay by a party to enforce or exercise any provision of the Terms & Conditions, or any related right, shall not constitute a waiver by such party of that provision or right. The exercise of one or more of a party's rights hereunder shall not be a waiver of, or preclude the exercise of, any rights or remedies available to such party under these Terms & Conditions or in law or at equity. Any waiver by a party shall only be made in writing and executed by a duly authorized officer of such party.
                                        </p>

                                        <p className="terms-con-content">
                                            Principal to Principal Relationship - You and the Owner are independent contractors, and nothing in these Terms & Conditions will be construed to create a partnership, joint venture, association of persons, agency (disclosed or undisclosed), franchise, sales representative, or employment relationship between you and the Owner. As an abundant caution, it is clarified that the Owner shall not have any right to conclude any contract for sale or purchase of Products for and / or on your behalf and both you and the Owner have entered this agreement on principal to principal basis.
                                        </p>

                                        <p className="terms-con-content">
                                            Force Majeure - If performance of any service or obligation under these Terms & Conditions or other agreement by M/s. Skillety Technologies Private Limited is, or other third parties in fulfilment of any purchase or sale transaction (for eg: logistics service provider, fulfilment centre, payment gateways, etc.) are, prevented, restricted, delayed or interfered with by reason of labour disputes, strikes, acts of God, floods, lightning, severe weather, shortages of materials, rationing, utility or communication failures, earthquakes, war, revolution, acts of terrorism, civil commotion, acts of public enemies, blockade, embargo or any law, order, proclamation, regulation, ordinance, demand or requirement having legal effect of any government or any judicial authority or representative of any such government, or any other act whatsoever, whether similar or dissimilar to those referred to in this clause, which are beyond the reasonable control of the Owner or its third parties performing such services as sub-contractor to the Owner and could not have been prevented by reasonable precautions (each, a "Force Majeure Event"), then the Owner shall be excused from such performance to the extent of and during the period of such Force Majeure Event. The Owner shall exercise all reasonable commercial efforts to continue to perform its obligations hereunder.
                                        </p>

                                        <p className="terms-con-content">
                                            Grievance Officer - In compliance with Information Technology Act, 2000 and the rules made thereunder, the Grievance Officer of M/s. Skillety Technologies Private Limited for the purpose of these Terms & Conditions shall be
                                        </p>
                                    </div>

                                    <div className="terms-con-note-area">
                                        <div className="terms-con-updated mt-0">
                                            Name&nbsp;:&nbsp;<span>Mrs. Supriya Das</span>
                                        </div>
                                        <div className="terms-con-updated">
                                            Email Address&nbsp;:&nbsp;<span>supriya@skillety.com</span>
                                        </div>
                                        <div className="terms-con-updated">
                                            Address&nbsp;:&nbsp;<span>Plot No. 45, Sarvasukhi Colony, West Marredpally, Secunderabad, Hyderabad, Telangana, 500026.</span>
                                        </div>
                                        <div className="terms-con-updated">
                                            Contact Number&nbsp;:&nbsp;<span>9731021066</span>
                                        </div>
                                        <div className="terms-con-note mt-5">
                                            * The Owner may change the aforesaid details from time to time.
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
export default TermsConditions;
