import { createContext, useState, useCallback} from "react";
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import useRazorpay from "react-razorpay";


const AuthContext = createContext();

export const AuthContextProvider = ({children}) => {
  const fromLogout = new URLSearchParams(window.location.search).get('param');
  const jobId = new URLSearchParams(window.location.search).get('jobId');
    const navigate = useNavigate();
    const location = useLocation()
    const {results, filterData} = location.state || {};
    const [errorMsg, setErrorMsg] = useState("");
    const [eventDetail, setEventDetail] = useState([]);
    const [eventImg, setEventImg] = useState();
    const [blogDetail, setBlogDetail] = useState([]);
    const [videoDetail, setVideoDetail] = useState([]);
    const [podcastDetail, setPodcastDetail] = useState([]);
    const [newsDetail, setNewsDetail] = useState([]);
    const [candidateImg, setCandidateImg] = useState();
    const [clientImg, setClientImg] = useState();
    const [packageSelectionDetail, setPackageSelectionDetail] = useState();
    const [result, setResult] = useState();
    const [spinStatus, setSpinStatus] = useState(false);

    const loginId = uuidv4();
    const [packageLogInUserData, setPackageLogInUserData] = useState(null);
    const [paymentSucess, setPaymentSucess] = useState(false);
    const [searchLoading, setsearchLoading] = useState(false);

    const [Razorpay, isLoaded] = useRazorpay();
    const [proccedToPayButton, setProceedToPayButton] = useState(false);
    const [allTestimonials, setAllTestimonials] = useState(null);
  
    //for show success message for payment
  function showSuccessMessage(message) {
    Swal.fire({
      title: 'Success!',
      text: message,
      icon: 'success',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
    });
  }

  //for show error message for payment
  function showErrorMessage(message) {
    Swal.fire({
      title: 'Error!',
      text: message,
      icon: 'error',
      confirmButtonColor: '#d33',
      confirmButtonText: 'OK',
    });
  }

  
   
    //user login request
    const loginUser = async (userData, data=null) => {
        try {
          setSpinStatus(true);
          const response = await axios.post(`${process.env.REACT_APP_BASE_API}/${userData[1]}`, userData[0], {
            headers: {
              'Content-Type': 'application/json',
            },
          });
            
            const result = response.data;
    
            if (result.accessToken) {
                console.log(result);
                setSpinStatus(false);
                if (userData[1] === "staff") {
                    localStorage.setItem("staffToken", JSON.stringify(result.accessToken));
                    window.open(`${process.env.REACT_APP_ADMIN_BASE_URL}/recruiter-dashboard/${result.accessToken}`, '_blank');
                } else if (userData[1] === "admin") {
                    localStorage.setItem("adminToken",  JSON.stringify(result.accessToken));
                    navigate("/admin-dashboard");
                } else if (userData[1] === "login-Candidate") {
                    localStorage.setItem("candidateToken",  JSON.stringify(result.accessToken));
                    // window.open(`${process.env.REACT_APP_ADMIN_BASE_URL}/${result.accessToken}`, '_blank');
                    (results || fromLogout) ? navigate("/") : filterData ? navigate("/job-search", { state: {filterData}}) : jobId ?  navigate(`/job-detail/${jobId}`) : navigate(-1);
                } else if (userData[1] === "login-Client") {
                    localStorage.setItem("clientToken",  JSON.stringify(result.accessToken));
                    // window.open(`${process.env.REACT_APP_ADMIN_BASE_URL}/client-dashboard/${result.accessToken}`, '_blank');
                    // window.open(`${process.env.REACT_APP_ADMIN_BASE_URL}/post-job/${result.accessToken}`, '_blank');
                   if(data === "fromPackage"){
                    setPackageLogInUserData(result);
                   }else{

                     (results || fromLogout) ? navigate("/client-home") : filterData ? navigate("/talent-profile-search", { state: {filterData}}) : navigate(-1);
                   }
                  // (results || fromLogout) ? navigate("/client-home") : filterData ? navigate("/talent-profile-search", { state: {filterData}}) : navigate(-1);
                }
                
            } else {
                console.log(result);
                // window.location.reload();
            }
        } catch (error) {
            console.log(error.response.data);
            setErrorMsg(error.response.data.message);
            setSpinStatus(false);
            // window.location.reload();
        }
    }

    const getProtectedData = async (token) => {
      // Check if localStorage has any key-value pairs
      if (localStorage.getItem("candidateToken") || localStorage.getItem("clientToken")) {
          try {
              const response = await axios.get(`${process.env.REACT_APP_BASE_API}/protected`, {
                  headers: {
                      Authorization: `Bearer ${token}`,
                      Accept: 'application/json'
                  }
              });
              return response.data;
          } catch (error) {
              throw error;
          }
      } else {
          return new Promise((resolve, reject) => {
            const unsubscribe = onAuthStateChanged(auth, (user) => {
              if (user) {
                  console.log(user);
                  user.getIdToken().then((userToken) => {
                      axios.get(`${process.env.REACT_APP_BASE_API}/protected`, {
                          headers: {
                              Authorization: `Bearer firebase:${userToken}`,
                              Accept: 'application/json'
                          }
                      }).then((response) => {
                          console.log(response.data);
                          resolve({ responseData: response.data, userToken }); // Resolve with both response data and userToken
                      }).catch((error) => {
                          reject(error); // Reject with the error
                      });
                  });
              } else {
                //    navigate("/candidate-login");
              }
          });
          
  
              return () => {
                  // Unsubscribe from the auth listener on component unmount
                  unsubscribe();
              };
          });
      }
    };
  
    //candidate register
    const candidateReg = async (userData) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_API}/candidate-reg`, userData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const result = response.data;

            if (!result.error) {
                console.log(result);
                setResult(result)
                await new Promise(() => {
                    Swal.fire({
                        title: 'Congratulations!',
                        text: 'Registration completed successfully.',
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                    }).then(() => {
                        navigate("/candidate-login", { state: {results:result} })
                    });
                });
            } else {
                console.log(result);
            }
        } catch (error) {
            console.log(error);
            setResult(error)
            await new Promise(() => {
                Swal.fire({
                    title: 'User Not Registered',
                    text: error.response.data.error,
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK',
                }).then(() => {
                    navigate("/candiate-register")
                });
            });
        }
    };

    const getEventDetail = async() => {
        try{
            const res = await axios.get(`${process.env.REACT_APP_BASE_API}/events`);
            const result = res.data;
            if (!result.error) {
              console.log(result);
              setEventDetail(result);
            } else {
              console.log(result);
            }
        }catch(err){
          console.log(err);
          
        }
    }

    const getEventImg = async() => {
        axios.get(`${process.env.REACT_APP_BASE_API}/event-image`)
        .then(res=>{
            console.log(res.data)
            setEventImg(res.data)
        })
        .catch(err=>console.log(err))
    }

    const getBlogsDetail = async() => {
        try{
            const res = await axios.get(`${process.env.REACT_APP_BASE_API}/blogs`);
            const result = res.data;
            if (!result.error) {
              console.log(result);
              setBlogDetail(result);
            } else {
              console.log(result);
            }
        }catch(err){
          console.log(err);
          
        }
    }

    const getVideoDetail = async() => {
        try{
            const res = await axios.get(`${process.env.REACT_APP_BASE_API}/videos`);
            const result = res.data;
            if (!result.error) {
              console.log(result);
              setVideoDetail(result);
            } else {
              console.log(result);
            }
        }catch(err){
          console.log(err);
          
        }
    }

    const getPodcastDetail = async() => {
        try{
            const res = await axios.get(`${process.env.REACT_APP_BASE_API}/podcasts`);
            const result = res.data;
            if (!result.error) {
              console.log(result);
              setPodcastDetail(result);
            } else {
              console.log(result);
            }
        }catch(err){
          console.log(err);
          
        }
    }

    const getNewsDetail = async() => {
        try{
            const res = await axios.get(`${process.env.REACT_APP_BASE_API}/news`);
            const result = res.data;
            if (!result.error) {
              console.log(result);
              setNewsDetail(result);
            } else {
              console.log(result);
            }
        }catch(err){
          console.log(err);
          
        }
    }

    const getCandidateImg = async() => {
        axios.get(`${process.env.REACT_APP_BASE_API}/candidate-image`)
        .then(res=>{
            console.log(res.data)
            setCandidateImg(res.data)
        })
        .catch(err=>console.log(err))
    }

    const getClientImg = async() => {
        axios.get(`${process.env.REACT_APP_BASE_API}/client-image`)
        .then(res=>{
            console.log(res.data)
            setClientImg(res.data)
        })
        .catch(err=>console.log(err))
    }

    const getClientChoosenPlan = async (id, token) => {
        try {
          const res = await axios.get(`${process.env.REACT_APP_BASE_API}/client-package-plan/${id}`,{
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json'
            }
          }
          );
          const result = res.data;
          if (!result.message) {
            console.log(result);
            setPackageSelectionDetail(result);
            return result;
          } else {
            console.log(result);
            return null;
          }
        } catch (err) {
          console.log(err);
          return null;
        }
      }

      const apiAfterPaymentStatus = (data, redirectURL) => {
        setPaymentSucess(false);
        axios.patch(`${process.env.REACT_APP_BASE_API}/api/payment/update-package-documents-after-payment`, 
              { orderId:data.orderId, paymentId:data.paymentId, documentMongooseId:data.mongooseId, packageType:data.packageType, paymentStatus:data.paymentStatus },
              {
                headers: {
                  Authorization: `Bearer ${data.token}`,
                  Accept: 'application/json'
                },
              } ).then(res=>{
                console.log(res.data);
                showSuccessMessage(`Thank you for choosing the ${data?.packageType} Package. Welcome aboard!`);
                setPaymentSucess(true);
                  setTimeout(()=>{
                    if(redirectURL){
                      window.location.href= `${process.env.REACT_APP_WEBSITE_BASE_URL}${redirectURL}`;
                    }else{
                      window.location.reload();
                    }
                    // window.location.href=`${process.env.REACT_APP_ADMIN_BASE_URL}/client-dashboard/${data.token}?loginId=${loginId}`.
                  },5000);
              }).catch(err=>{
                console.log(err);
                showErrorMessage(err.response.data.error);
              })
      }

      const initPayment = (datas, detail, redirectURL) => { 
        const options = {
          key: process.env.REACT_APP_KEY_ID,
          amount: datas.amount,
          currency: datas.currency,
          name:detail.name,
          description:detail.description,
          image:detail.image,
          order_id: datas.id,
          handler: async (response) => {
            try {
              const verifyUrl = `${process.env.REACT_APP_BASE_API}/api/payment/verify`;
              const { data } = await axios.post(verifyUrl, response);
              console.log(data);
              
              const apiData = {
                orderId:data.orderId,
                paymentId:data.paymentId,
                mongooseId:detail.mongooseId,
                packageType:detail.packageType,
                paymentStatus:"Success",
                token:detail.token
              }
              apiAfterPaymentStatus(apiData, redirectURL);
              
            } catch (error) {
              console.log(error);

              const apiData = {
                orderId:datas.id,
                paymentId:"payment_failed",
                mongooseId:detail.mongooseId,
                packageType:detail.packageType,
                paymentStatus:"Failure",
                token:detail.token
              }

              apiAfterPaymentStatus(apiData, redirectURL);

              showErrorMessage(error.response.data.error);
            }
          },
          prefill: {
            name: "Piyush Garg",
            email: "youremail@example.com",
            contact: "9999999999",
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#714F36",
          },
        };
        // const rzp1 = new window.Razorpay(options);
        // rzp1.open();
        const rzpay = new Razorpay(options);

        // rzpay.on('payment.failed', function(response) {
        //   setProceedToPayButton(true); // Set to true when payment fails (user cancels)
        // });

        rzpay.open();
      };

    const handlePayment = async(detail,redirectURL) => {
        try{
          const orderUrl = `${process.env.REACT_APP_BASE_API}/api/payment/orders`;
          const {data} = await axios.post(orderUrl, {amount: detail.amount});
          console.log(data);
          setsearchLoading(false);
          initPayment(data.data, detail,redirectURL);
        }catch (err) {
          console.log(err);
          setsearchLoading(false);
          showErrorMessage(err.response.data.error)
        }
    }

  //   useEffect(() => {
  //     if (Razorpay && isLoaded) {
  //         const rzpay = new Razorpay({});
  //         rzpay.on('opened', function() {
  //             setProceedToPayButton(false); // Set to false when payment model opens
  //         });
  //     }
  // }, [Razorpay, isLoaded]); // Run this effect when Razorpay or isLoaded changes

  // console.log(proccedToPayButton);

  const getAllTestimonials = async (condition,type) => {
    try{
      const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/testimonial/get-testimonial-from-all-type?condition=${condition}&type=${type}` ,{
        headers: {
          "Content-Type": "application/json",
      }
      })
      console.log(response.data);
      setAllTestimonials(response.data)
  
    }catch (error){
      console.log(error);
      // showErrorMessage(error.response.data.error);
    }
  }


    return<AuthContext.Provider value={{candidateReg, loginUser, getProtectedData, errorMsg, setErrorMsg, eventDetail, getEventDetail, getEventImg, eventImg, blogDetail, getBlogsDetail,videoDetail, getVideoDetail, podcastDetail, getPodcastDetail, newsDetail, getNewsDetail, getCandidateImg, candidateImg, getClientImg, clientImg, getClientChoosenPlan, packageSelectionDetail, result, loginId, spinStatus, filterData, results, fromLogout, handlePayment , paymentSucess, packageLogInUserData, setPackageLogInUserData, searchLoading, setsearchLoading, getAllTestimonials, allTestimonials}}>
            {children}
        </AuthContext.Provider>
}

export default AuthContext;