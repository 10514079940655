import React, { useState, useContext } from 'react';
import { useEffect } from 'react';
import $ from 'jquery';
import './ClientLogin.css';
import './ClientLogin-responsive.css';
import AuthContext from '../../context/AuthContext';
import Layout from '../../components/Layout';
import useTokenRedirect from '../../customhooks/useTokenRedirect';
import axios from 'axios';
import TagManager from 'react-gtm-module'


const ClientLogin = () => {    
    useTokenRedirect();
    const { loginUser, errorMsg, setErrorMsg, spinStatus } = useContext(AuthContext);

    const [credentials, setcredentials] = useState({
        userId: "",
        password: "",
    })
    const [clientInActiveStatus, setClientInActiveStatus] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setcredentials({ ...credentials, [name]: value });
        setErrorMsg("");
    }

    useEffect(()=>{
        
        const checkingValue = {
          value: credentials.userId
        }
    
        if(credentials.userId){
          setClientInActiveStatus(false);
          axios.post(`${process.env.REACT_APP_BASE_API}/check-user-active-status`, checkingValue)
            .then(res=>{
              console.log(res.data);
              setClientInActiveStatus(res.data.inActive);
            }).catch(err=>{
              console.log(err);
            })
        }
    
      },[credentials.userId]);

    const handleSubmit = (event) => {
        setErrorMsg("");
        event.preventDefault();
        loginUser([credentials, "login-Client"]);
    }

    useEffect(() => {
        $(document).ready(function () {
            // Function to toggle password visibility
            function togglePasswordVisibility(inputId, iconId) {
                var passwordInput = $('#' + inputId);
                var passwordIcon = $('#' + iconId);

                // Toggle password visibility
                if (passwordInput.attr('type') === 'password') {
                    passwordInput.attr('type', 'text');
                    passwordIcon.removeClass('bi-eye-slash').addClass('bi-eye');
                } else {
                    passwordInput.attr('type', 'password');
                    passwordIcon.removeClass('bi-eye').addClass('bi-eye-slash');
                }
            }

            // Toggle password visibility when the eye icons are clicked
            $('#togglePassword').click(function () {
                togglePasswordVisibility('password', 'togglePassword');
            });

            // Hide the eye icons when the password fields are empty
            $('input[type="password"]').on('input', function () {
                var inputId = $(this).attr('id');
                var iconId = 'toggle' + inputId.charAt(0).toUpperCase() + inputId.slice(1);

                if ($(this).val().trim() === '') {
                    $('#' + iconId).hide();
                } else {
                    $('#' + iconId).show();
                }
            });
        });
    }, []);

    useEffect(() => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'pageview',
            userProject: 'skillity',
            page: `client-login`
          }
        });
      }, []);

    return (
        <div>
            <Layout newNavBarClientLogin={true} />
            <div className='cli--signup-section'>
                <div className='container-fluid'>
                    <div className='container-fluid container-section'>
                        {/* <div className="custom--container"> */}
                        <div className="row custom-column-reverse">
                            <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="cli--signup-form-area cli--login-form-area" data-aos="fade-up">
                                    {/* <h5 className="cli--signup-title" data-aos="fade-left">Welcome back</h5> */}

                                    <h6 className='cli--signup-sub-title'>Welcome back! Please enter your details</h6>

                                    {/* <div className="cli--login-with-google-btn-area">
                                        <a href="#" className='cli--login-with-google-btn'>
                                            <img src="assets/img/signup/google-icon.png" className='google-icon' alt="" />
                                            <span>Log in with Google</span>
                                        </a>
                                    </div> */}

                                    <form action="" className='cli--signup-form' onSubmit={handleSubmit}>

                                        <div className='cli--signup-form-group'>
                                            <input type="text" id='user_id' name="userId" placeholder="Enter your Email or Phone number" className='cli--signup-form-input' value={credentials.userId} onChange={handleInputChange} />
                                            <label htmlFor="user_id" className='cli--signup--form-label'>User Id</label>
                                        <small className='text-danger'>{(clientInActiveStatus && credentials.userId) ? "Your account is freezed!. Please contact Skillety support for more details." :""}</small>
                                        </div>

                                        <div className='cli--signup-form-group'>
                                            <i class="bi bi-eye-slash toggle-eye" id="togglePassword"></i>
                                            <input type="password" id='password' name="password" placeholder="Enter your password" className='cli--signup-form-input' value={credentials.password} onChange={handleInputChange} required
                                            // onPaste={(e)=>e.preventDefault()}
                                            />
                                            <label htmlFor="password" className='cli--signup--form-label'>Password</label>
                                        </div>

                                        {errorMsg ?
                                            <p className='log-error-msg'>{errorMsg && errorMsg}</p>
                                            : null
                                        }

                                        <div className="cli--login-forgot-remember-area">
                                            <div className="cli--login-remember-area">
                                                <label className="cli--login-remember-checkbox">
                                                    <input type="checkbox" />
                                                    <span className="cli--login-remember-checkmark"></span>
                                                    Remember me
                                                </label>
                                            </div>
                                            <div className="cli--login-forgot-area">
                                                <a href="/forgot-password/Client" className='cli--login-forgot'>Forgot Password</a>
                                            </div>
                                        </div>

                                        <div className="cli--create-account-btn-area">
                                            <button type='submit' className='cli--create-account-btn client'>
                                                {spinStatus &&
                                                    <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#FFF"></path>
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"></path>
                                                    </svg>
                                                }
                                                Log In
                                            </button>
                                        </div>

                                    </form>
                                    <div className="cli--login-no-account-area">
                                        <span className='cli--login-no-account'>Don’t have an account?&nbsp;</span>
                                        <a href="/client-register" className='cli--login-no-account-signup text-undeline'>Sign up</a>
                                    </div>

                                </div>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="cli--signup-img-area">
                                    <img src="assets/img/signup/signup-img.jpg" data-aos="fade" data-aos-delay="300" className='cli--signup-img cli--login-img' loading='lazy' alt="" />
                                </div>
                            </div>
                        </div>

                        <div className='cli--copyright-area'>
                            <i class="bi bi-c-circle me-2"></i>
                            <span className='cli--copyright'>2023 - Skillety Technologies Private Limited, All Rights Reserved.</span>
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientLogin;