import React, { useState, useContext, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import $ from 'jquery';
import './ClientRegister.css';
import './ClientRegister-responsive.css';
import Layout from '../../components/Layout';
import { Footer } from '../../components/Footer';
import GoogleAuth from '../../components/GoogleAuth';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import TagManager from 'react-gtm-module';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const ClientRegister = () => {
    const clientToken = JSON.parse(localStorage.getItem("clientToken"));
    const navigate = useNavigate();
    const [profile, setProfile] = useState([]);
    const [isAgreed, setIsAgreed] = useState(false);
    // const initialCredentials = {
    //     name: "",
    //     phone: "",
    //     email: "",
    //     companyName: "",
    //     count: "",
    //     text: "",
    // }
    const initialClientDetails = {
        fullName: "",
        // lastName:"",
        email: "",
        mobile: "",
        // companyName:"",
        GSTIN: "",
        password: "",
        confirmPassword: ""
    }
    const [credentials, setcredentials] = useState(initialClientDetails);
    const [showPassword, setshowPassword] = useState(false);
    const [showLoginPassword, setshowLoginPassword] = useState(false);
    const [showConfirmPassword, setshowConfirmPassword] = useState(false);

    const [industryArray, setIndustryArray] = useState([]);
    const [searchIndustryInput, setSearchIndustryInput] = useState("");
    const [filteredIndustry, setFilteredindustry] = useState([]);
    const [selectedIndustry, setSelectedIndustry] = useState([]);
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const resultAreaRef = useRef(null);
    const [isPhoneValid, setIsPhoneValid] = useState(false);

    const [checkValueResult, setCheckValueResult] = useState({
        email: false,
        mobile: false
    });

    useEffect(() => {
        if (focusedIndex !== -1 && resultAreaRef.current) {
            const focusedElement = resultAreaRef.current.children[focusedIndex];
            if (focusedElement) {
                focusedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            }
        }
    }, [focusedIndex]);

    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [require, setRequire] = useState(false)
    const [spinStatus, setSpinStatus] = useState(false);

    //for show error message for payment
    function showErrorMessage(message) {
        Swal.fire({
            title: 'Error',
            text: message,
            icon: 'info',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
        });
    }

    const handleTogglePassword = () => {
        setshowPassword(!showPassword);
    };

    const handleToggleConfirmPassword = () => {
        setshowConfirmPassword(!showConfirmPassword);
    };

    const handleToggleLoginPassword = () => {
        setshowLoginPassword(!showLoginPassword);
    };

    const getAllIndustry = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BASE_API}/industries`, {
                headers: {
                    Accept: 'application/json'
                }
            });
            const result = res.data;
            if (!result.error) {
                console.log(result);
                setIndustryArray(result);
            } else {
                console.log(result);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        // getAllIndustry();
    }, [])

    useEffect(() => {
        setcredentials((prevCredentials) => ({
            ...prevCredentials,
            fullName: profile.name ? profile.name : "",
            email: profile.email ? profile.email : "",
        }));
    }, [profile]);

    useEffect(() => {
        if (clientToken) {
            navigate("/client-home")
        }
    }, [clientToken])


    const registerUser = async () => {
        const { confirmPassword, ...userData } = credentials;
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_API}/create-quick-client`, userData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const result = response.data;

            if (!result.error) {
                console.log(result);
                setSpinStatus(false);
                await new Promise(() => {
                    Swal.fire({
                        title: 'Congratulations, Successfully Registered!',
                        text: 'We are happy to have you with us.',
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                    }).then(() => {
                        localStorage.setItem("clientToken", JSON.stringify(result.accessToken));
                        navigate("/client-home");
                    })
                });
            } else {
                console.log(result);
            }
        } catch (error) {
            console.log(error);
            setSpinStatus(false);
            showErrorMessage(error.response.data.error);
        }
    };

    const handleIndustrySearch = (e) => {
        const inputValue = e.target.value;
        setSearchIndustryInput(inputValue);
        if (inputValue.length > 0) {
            const industries = industryArray.filter((obj) => {
                return obj.industry.toLowerCase().includes(inputValue.toLowerCase());
            });
            if (industries.length > 0) {
                setFilteredindustry(industries);
                setFocusedIndex(0);
            }
        } else {
            setFilteredindustry([]);
            setFocusedIndex(-1);
        }
    };

    const handleIndustryClick = (industry) => {
        setSelectedIndustry([industry]);
        setSearchIndustryInput("");
        setFilteredindustry([]);
    }

    const handleKeyDown = (e) => {
        if (filteredIndustry.length === 0) return;

        switch (e.key) {
            case 'ArrowUp':
                e.preventDefault();
                setFocusedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
                break;
            case 'ArrowDown':
                e.preventDefault();
                setFocusedIndex((prevIndex) => (prevIndex < filteredIndustry.length - 1 ? prevIndex + 1 : prevIndex));
                break;
            case 'Enter':
                if (focusedIndex !== -1) {
                    handleIndustryClick(filteredIndustry[focusedIndex].industry);
                }
                break;
            default:
                break;
        }
    };

    const handleDeselectIndustry = (industry) => {
        setSelectedIndustry(selectedIndustry.filter(selectIndustry => selectIndustry !== industry));
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setcredentials({ ...credentials, [name]: value });
    }

    useEffect(() => {

        const checkingValue = {
            value: credentials.email
        }

        if (credentials.email) {
            setCheckValueResult({ ...checkValueResult, email: false });
            axios.post(`${process.env.REACT_APP_BASE_API}/check-email-phone-validity`, checkingValue)
                .then(res => {
                    console.log(res.data);
                    setCheckValueResult({ ...checkValueResult, email: res.data.emailExists });
                }).catch(err => {
                    console.log(err);
                })
        }

    }, [credentials.email]);

    useEffect(() => {

        const checkingValue = {
            value: credentials.phone
        }

        if (credentials.phone) {
            setCheckValueResult({ ...checkValueResult, mobile: false });
            axios.post(`${process.env.REACT_APP_BASE_API}/check-email-phone-validity`, checkingValue)
                .then(res => {
                    console.log(res.data);
                    setCheckValueResult({ ...checkValueResult, mobile: res.data.phoneExists });
                }).catch(err => {
                    console.log(err);
                })
        }

    }, [credentials.phone]);

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(credentials);
        if(isPhoneValid){
            if (
                credentials.fullName &&
                credentials.email &&
                credentials.mobile &&
                credentials.password &&
                credentials.confirmPassword &&
                credentials.password.length >= 8 &&
                credentials.password === credentials.confirmPassword &&
                emailRegex.test(credentials.email) &&
                isAgreed
            ) {
                setSpinStatus(true);
                // const updatedCredentials = {
                //     ...credentials,
                //     industry: selectedIndustry[0],
                // };
                // console.log(updatedCredentials);
                registerUser();
    
            } else {
                setRequire(true);
            }
        }
    };

    const handlePhoneNumberChange = (phoneNumber) => {
        setcredentials((prevCredentials) => ({
            ...prevCredentials,
            ['mobile']: phoneNumber,
        }));
        if (phoneNumber) {
            phoneNumber = phoneNumber.toString();
            const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
            setIsPhoneValid(parsedPhoneNumber ? parsedPhoneNumber.isValid() : false);
        } else {
            setIsPhoneValid(false);
        }
    };

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                userProject: 'skillity',
                page: `client-register`
            }
        });
    }, []);

    return (
        <div>
            <Layout />
            <div className='client-register-section-sm'>
                <div className='container-fluid'>
                    <div className='container-fluid container-section'>
                        <div className="custom--container">
                            <div className="breadcrumb--area-dark-sm" data-aos="fade-down">
                                <div className="breadcrumb--item-dark">
                                    <a href="/client-home">Home</a>
                                </div>
                                <div className="breadcrumb--item-dark">
                                    <p>Registration Form</p>
                                </div>
                            </div>

                            <div className="cli--reg-heading-area-sm">
                                <h3 className='cli--reg-heading-sm' data-aos="fade-left">Hi, Welcome to <span>SKILLETY!!!</span></h3>
                                <h4 className='cli--reg-sub-heading-sm' data-aos="fade-left">The most Affordable Job Portal in the Country.</h4>
                            </div>

                            {/* <div className='cli--reg-grab-area'>
                                <h5 className="cli--reg-grab" data-aos="fade-up">Grab your free DEMO in just a few seconds!</h5>
                            </div> */}

                            {/* <div className='login--with-g-area'>
                                <GoogleAuth setProfile={setProfile} />
                            </div> */}

                            <div className="cli--reg-form-area">
                                <div className="con--note-form-area" data-aos="fade-up">
                                    <form action=""
                                    // onSubmit={handleSubmit}
                                    >
                                        <div className="row">
                                            <div className="col-12 col-lg-6 col-md-6 col-sm-6 custom-padding-right">
                                                <div className='reg--form-group-sm custom'>
                                                    <input type="text" id='full_name' name="fullName" value={credentials.fullName} onChange={handleInputChange} placeholder="Enter your full name" className='reg--form-input' required />
                                                    <label htmlFor="full_name" className='reg--form-label'>Your Full Name&nbsp;<span className='is-required'>*</span></label>
                                                    {require && <small className='text-danger text-capitalized form-error-message'>{credentials.fullName === "" && "required"}</small>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 col-md-6 col-sm-6 custom-padding-left">
                                                <div className='reg--form-group-sm'>
                                                    <div className='position-relative'>
                                                        <input type="email" id='email' name="email" value={credentials.email} onChange={handleInputChange} placeholder="Enter your email address" className='reg--form-input' required />
                                                        <label htmlFor="email" className='reg--form-label'>Email ID&nbsp;<span className='is-required'>*</span></label>
                                                    </div>
                                                    {require &&
                                                        <div>
                                                            <small className='text-danger text-capitalized form-error-message'>{credentials.email === "" && "required"}</small>
                                                        </div>
                                                    }
                                                    <div>
                                                        <small className='text-danger'>{credentials.email && !(emailRegex.test(credentials.email)) && "enter valid email address"}</small>
                                                    </div>
                                                    <div>
                                                        <small className='text-danger'>{(checkValueResult.email && credentials.email) ? "Email already exists" : ""}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 col-md-6 col-sm-6 custom-padding-right">
                                                <div className='reg--form-group-sm'>
                                                    <div>
                                                        <label htmlFor="phone_no" className='reg--form-label-custom-mobile'>Mobile Number&nbsp;<span className='is-required'>*</span></label>
                                                        <PhoneInput
                                                            placeholder="Enter your mobile number"
                                                            value={credentials.mobile?credentials.mobile.toString():''}
                                                            defaultCountry="IN"
                                                            className='reg--form-input-mobile'
                                                            required
                                                            name="mobile"
                                                            id='phone_no'
                                                            onChange={(value) => handlePhoneNumberChange(value)} />
                                                        {/* <input type="number" id='phone_no' name="mobile" value={credentials.mobile} onChange={handleInputChange} placeholder="Enter your mobile number" className='reg--form-input' required /> */}

                                                    </div>
                                                    {require &&
                                                        <div>
                                                            <small className='text-danger text-capitalized form-error-message'>{credentials.mobile === "" && "required"}</small>
                                                        </div>
                                                    }
                                                    {!isPhoneValid &&
                                                        <div>
                                                            <small className='text-danger text-capitalized'>Enter valid phone number</small>
                                                        </div>

                                                    }
                                                    <div>
                                                        <small className='text-danger'>{(checkValueResult.mobile && credentials.mobile) ? "Phone Num already exists" : ""}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 col-md-6 col-sm-6 custom-padding-left">
                                                <div className='reg--form-group-sm'>
                                                    <input type="text" id='subject' name="GSTIN" value={credentials.GSTIN} onChange={handleInputChange} placeholder="Enter the GSTIN" className='reg--form-input' required />
                                                    <label htmlFor="subject" className='reg--form-label'>GSTIN&nbsp;<span className='optional-text'>(Optional)</span></label>
                                                    {/* {require && <small className='text-danger text-capitalized form-error-message'>{credentials.companyName === "" && "required"}</small>} */}
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 col-md-6 col-sm-6 custom-padding-right">
                                                <div className='reg--form-group-sm'>
                                                    <div className='position-relative'>
                                                        <i className={`show-password-icon bi ${showPassword ? 'bi-eye-fill' : 'bi-eye-slash-fill'}`} onClick={handleTogglePassword}></i>
                                                        <input type={showPassword ? 'text' : 'password'} id='phone_no' name="password" value={credentials.password} onChange={handleInputChange} placeholder="Enter your password" className='reg--form-input' required />
                                                        <label htmlFor="phone_no" className='reg--form-label'>Password&nbsp;<span className='is-required'>*</span></label>
                                                    </div>
                                                    {require &&
                                                        <div>
                                                            <small className='text-danger text-capitalized form-error-message'>{credentials.password === "" && "required"}</small>
                                                        </div>
                                                    }
                                                    <div>
                                                        <small className='form-required'>{(credentials.password.length < 8 && credentials.password) ? "Password must be atleast 8 characters long" : ""}</small>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 col-md-6 col-sm-6 custom-padding-left">
                                                <div className='reg--form-group-sm'>
                                                    <div className='position-relative'>
                                                        <i className={`show-password-icon bi ${showConfirmPassword ? 'bi-eye-fill' : 'bi-eye-slash-fill'}`} onClick={handleToggleConfirmPassword}></i>
                                                        <input type={showConfirmPassword ? 'text' : 'password'} id='phone_no' name="confirmPassword" value={credentials.confirmPassword} onChange={handleInputChange} placeholder="Confirm the password" className='reg--form-input' required />
                                                        <label htmlFor="phone_no" className='reg--form-label'>Confirm Password&nbsp;<span className='is-required'>*</span></label>
                                                    </div>
                                                    {require &&
                                                        <div>
                                                            <small className='text-danger text-capitalized form-error-message'>{credentials.confirmPassword === "" && "required"}</small>
                                                        </div>
                                                    }
                                                    <div>
                                                        <small className='form-required'>{(credentials.password !== credentials.confirmPassword && credentials.confirmPassword) ? "Password & confirm password must be equal" : ""}</small>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6 col-md-8 col-sm-8 custom-padding-right1">
                                                {/* <div className='reg--form-group-sm' data-aos="fade-up">
                                                    <div className="custom--select-area">
                                                        <label htmlFor="" className='custom--select-label'>Industry</label>
                                                        <select name="" id="" className="custom--select-input">
                                                            <option value="">Option 1</option>
                                                            <option value="">Option 2</option>
                                                            <option value="">Option 3</option>
                                                            <option value="">Option 4</option>
                                                        </select>
                                                    </div>
                                                </div> */}

                                                {/* <div className="custom--select-area" >
                                                    <label htmlFor="industry" className='custom--select-label'>Industry</label>
                                                

                                                    <div className='cand--reg-multi-input-form-group'>

                                                        <div className="container-input-area">
                                                            {selectedIndustry.map(selectIndustry => (
                                                                <span className="form__badge"
                                                                    key={selectIndustry}
                                                                ><span>{selectIndustry}</span>
                                                                    <i className='bi bi-x' onClick={() => handleDeselectIndustry(selectIndustry)}></i>
                                                                </span>
                                                            ))}
                                                            <div className='position-relative container__input_section'>
                                                                <div className="container__input_with_label">
                                                                    {selectedIndustry.length === 0 &&
                                                                        <>
                                                                            <input type="search" className='container__input'
                                                                                id='searchIndustryInput' name='searchIndustryInput'
                                                                                value={searchIndustryInput}
                                                                                onChange={handleIndustrySearch}
                                                                                onKeyDown={handleKeyDown} />
                                                                            <label htmlFor="skill" className={`container__input_label text-gray ${searchIndustryInput ? 'd-none' : ''}`}>Select an industry to get better responses</label>
                                                                        </>
                                                                    }
                                                                </div>
                                                                <div className='search-result-data-area' ref={resultAreaRef}>
                                                                    {filteredIndustry.length > 0 &&
                                                                        filteredIndustry.map((filterIndustry, index) => {
                                                                            return <div className={`search-result-data ${index === focusedIndex ? 'focused' : ''}`} key={filterIndustry._id} onClick={() => handleIndustryClick(filterIndustry.industry)}>
                                                                                {filterIndustry.industry}
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        
                                                        {require && <small className='text-danger text-capitalized'>{selectedIndustry.length === 0 && "required"}</small>}
                                                    </div>

                                                    
                                                </div> */}

                                            </div>
                                            {/* <div className="col-12 col-lg-6 col-md-4 col-sm-4 custom-padding-left">
                                                <div className='reg--form-group-sm'>
                                                    <input type="number" id='subject' name="count" value={credentials.count} min="0" onChange={handleInputChange} placeholder="Enter the headcount" className='reg--form-input' required />
                                                    <label htmlFor="subject" className='reg--form-label'>Headcount&nbsp;<span className='is-required'>*</span></label>
                                                    {require && <small className='text-danger text-capitalized form-error-message'>{credentials.count === "" && "required"}</small>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-12 col-md-12 col-sm-12 custom-padding-left-right mt-0 mt-md-4">
                                                <div className='reg--form-group-sm'>
                                                    <input type="text" id='message' name="text" value={credentials.text} onChange={handleInputChange} placeholder="Enter, How did you know about skillety?" className='reg--form-input' />
                                                    <label htmlFor="message" className='reg--form-label'>How did you know about skillety?&nbsp;<span className='is-required'>*</span></label>
                                                    {require && <small className='text-danger text-capitalized form-error-message'>{credentials.text === "" && "required"}</small>}
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="reg--form-btn-area-sm">
                                            <div className="cli--login-remember-area terms-condition" data-aos="fade-right">
                                                <label className="cli--login-remember-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        checked={isAgreed}
                                                        onChange={() => {
                                                            setIsAgreed(!isAgreed)
                                                        }}
                                                    />
                                                    <span className="cli--login-remember-checkmark"></span>
                                                    <span>By checking this box, you agree to our
                                                        &nbsp;<a href="/privacy-policy" target='_blank'>Privacy Policy</a>,&nbsp;<a href="/terms-and-conditions" target='_blank'>Terms & Conditions.</a>
                                                    </span>
                                                </label>
                                            </div>
                                            <button type='submit' className='reg--form-btn-sub' disabled={!isAgreed} data-aos="fade-left"
                                                onClick={handleSubmit}>
                                                <div className='reg--form-btn client'>
                                                    {spinStatus && <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#FFF"></path>
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#714F36"></path>
                                                    </svg>}
                                                    Register
                                                </div>
                                                <div className='reg--form-arrow-area'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                                        <path d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832" stroke="white" stroke-width="2" />
                                                        <path d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162" stroke="white" stroke-width="2" />
                                                        <path d="M1 26L25.1667 1" stroke="white" stroke-width="2" />
                                                    </svg>
                                                </div>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ClientRegister;