import React, { useContext, useState, useRef, useEffect } from "react";
import $ from "jquery";
import "./TalentsProfileSearch.css";
import "./TalentsProfileSearch-responsive.css";
import Layout from "../../components/Layout";
import { Footer } from "../../components/Footer";
import axios from "axios";
import AuthContext from "../../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import TagManager from 'react-gtm-module'

const TalentsProfileSearch = () => {
  const location = useLocation();
  const [inCommingData, setInCommingData] = useState();
  const [filteringData, setFilteringData] = useState();

  const clientToken = JSON.parse(localStorage.getItem("clientToken"));
  const {
    getProtectedData,
    getClientChoosenPlan,
    packageSelectionDetail,
    getCandidateImg,
    candidateImg,
    loginId
  } = useContext(AuthContext);
  const [employeeId, setEmployeeId] = useState("");
  const [loginClientDetail, setLoginClientDetail] = useState([]);
  const [cvViews, setCvViews] = useState();
  const [candidateDetail, setCandidateDetail] = useState([]);
  const [filteredSearchResults, setFilteredSearchResults] = useState([]);
  const [filteredSearchResultsMsg, setFilteredSearchResultsMsg] = useState("");
  const [searchResult, setSearchResult] = useState(false);
  const [viewedCandidate, setViewedCandidate] = useState([]);

  const [skillArray, setSkillArray] = useState([]);
  const [jobRoleArray, setjobRoleArray] = useState([]);
  const [locationArray, setLocationArray] = useState([]);
  const [educationArray, setEducationArray] = useState([]);
  const [roleArray, setRoleArray] = useState([]);
  const [industryArray, setIndustryArray] = useState([]);
  const [filteredList, setFilteredList] = useState();
  const [filteredLocation, setFilteredLocation] = useState([]);
  const [filteredEducation, setFilteredEducation] = useState([]);
  const [filteredRole, setFilteredRole] = useState([]);
  const [filteredIndustry, setFilteredIndustry] = useState([]);
  const [selectedResults, setSelectedResults] = useState([]);
  const [selectedLocationResults, setSelectedLocationResults] = useState([]);
  const [selectedEducationResults, setselectedEducationResults] = useState([]);
  const [selectedRoleResults, setSelectedRoleResults] = useState([]);
  const [selectedIndustryResults, setSelectedIndustryResults] = useState([]);

  const [recentSearches, setRecentSearches] = useState([]);
  const [checkBoxfilters, setCheckBoxFilters] = useState([]);
  const [x, setX] = useState([0, 25]);
  const [page, setPage] = useState(1);
  const [pageValue, setPageValue] = useState(1);
  const [apiResult, setApiResult] = useState();
  const [searchLoading, setsearchLoading] = useState(false);
  const [resultLoading, setResultLoading] = useState(false);

  const [maxHeight, setMaxHeight] = useState(0);

  const [focusedEducationIndex, setFocusedEducationIndex] = useState(-1);
  const resultEducationAreaRef = useRef(null);

  const [focusedLocationIndex, setFocusedLocationIndex] = useState(-1);
  const resultLocationAreaRef = useRef(null);

  const [focusedKeywordIndex, setFocusedKeywordIndex] = useState(-1);
  const resultKeywordAreaRef = useRef(null);

  useEffect(() => {
    if (focusedEducationIndex !== -1 && resultEducationAreaRef.current) {
      const focusedEducationElement = resultEducationAreaRef.current.children[focusedEducationIndex];
      if (focusedEducationElement) {
        focusedEducationElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }
  }, [focusedEducationIndex]);

  useEffect(() => {
    if (focusedLocationIndex !== -1 && resultLocationAreaRef.current) {
      const focusedLocationElement = resultLocationAreaRef.current.children[focusedLocationIndex];
      if (focusedLocationElement) {
        focusedLocationElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }
  }, [focusedLocationIndex]);

  useEffect(() => {
    if (focusedKeywordIndex !== -1 && resultKeywordAreaRef.current) {
      const focusedKeywordElement = resultKeywordAreaRef.current.children[focusedKeywordIndex];
      if (focusedKeywordElement) {
        focusedKeywordElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
      }
    }
  }, [focusedKeywordIndex]);

  useEffect(() => {
    const calculateMaxHeight = () => {
      const windowHeight = window.innerHeight;
      const maxHeightPercentage = 0.77;
      const calculatedMaxHeight = windowHeight * maxHeightPercentage;
      setMaxHeight(calculatedMaxHeight);
    };

    calculateMaxHeight();
    window.addEventListener('resize', calculateMaxHeight);

    return () => {
      window.removeEventListener('resize', calculateMaxHeight);
    };
  }, []);

  const [filters, setFilters] = useState({
    searchInput: "",
    minExperienceYr: "",
    maxExperienceYr: "",
    minExperienceMonth: "",
    maxExperienceMonth: "",
    location: "",
    currencyType: "₹",
    minSalary: "",
    maxSalary: "",
    education: "",
    role: "",
    days: "",
    // industry: "",
    company: "",
    candidateType: "",
    activeIn: "",
    gender: "",
  });

  // console.log(filters);

  const navigate = useNavigate();

  useEffect(() => {
    // $(document).ready(function () {
    ////change the toggle text and color
    $(".toggleSwitch").change(function () {
      var $label = $(this)
        .closest(".cl-toggle-switch")
        .find(".cl-toggle--switch-label");
      if ($(this).is(":checked")) {
        $label.text("Boolean On").css("color", "#714F36");
      } else {
        $label.text("Boolean Off").css("color", "#B3B3B3");
      }
    });

    ////for tooltip
    $(".info-icon-button").click(function () {
      // Toggle tooltip display on button click
      $(".tooltip").toggleClass("active");
    });
    ////

    ///add multi input fields for company
    $(".cli--tal-search-add-input-button").click(function () {
      // Create a new input area
      var newInputArea = $("<div>", {
        class: "cli-tal-pro-search-filter-multi-input-area",
      });

      // Create an input element
      var inputElement = $("<input>", {
        type: "text",
        name: "company",
        class: "cli-tal-pro-search-filter-input",
        placeholder: "Add company name",
      });

      // Create a close button
      var closeButton = $("<i>", {
        class: "bi bi-x cli-input-close-icon",
      });

      // Add the input and close button to the new input area
      newInputArea.append(inputElement);
      newInputArea.append(closeButton);

      // Append the new input area to the container
      $("#container").append(newInputArea);

      // Use a timeout to trigger the transition after the element is added
      setTimeout(function () {
        newInputArea.addClass("active");
      }, 10);

      // Handle the close button click event
      closeButton.click(function () {
        // Remove the class to trigger the transition
        newInputArea.removeClass("active");

        // Remove the input area after the transition ends
        setTimeout(function () {
          newInputArea.remove();
        }, 300); // Adjust the time to match your transition duration
      });
    });

    $(".cli--tal-search-add-company-input-button").click(function () {
      // Create a new input area
      var newInputArea = $("<div>", {
        class: "cli-tal-pro-search-filter-multi-input-area",
      });

      // Create an input element
      var inputElement = $("<input>", {
        type: "text",
        name: "company",
        class: "cli-tal-pro-search-filter-input",
        placeholder: "Add company name",
      });

      // Create a close button
      var closeButton = $("<i>", {
        class: "bi bi-x cli-input-close-icon",
      });

      // Add the input and close button to the new input area
      newInputArea.append(inputElement);
      newInputArea.append(closeButton);

      // Append the new input area to the container
      $("#containerCompany").append(newInputArea);

      // Use a timeout to trigger the transition after the element is added
      setTimeout(function () {
        newInputArea.addClass("active");
      }, 10);

      // Handle the close button click event
      closeButton.click(function () {
        // Remove the class to trigger the transition
        newInputArea.removeClass("active");

        // Remove the input area after the transition ends
        setTimeout(function () {
          newInputArea.remove();
        }, 300); // Adjust the time to match your transition duration
      });
    });
    ////

    ///add multi input fields for search keyword
    $(".cli--tal-search-keyword-add-input-button").click(function () {
      // Create a new input area
      var newInputArea = $("<div>", {
        class: "cli-tal-pro-search-filter-multi-input-area",
      });

      // Create an input element
      var inputElement = $("<input>", {
        type: "text",
        name: "exclude_keyword",
        class: "cli-tal-pro-search-filter-input",
        placeholder: "Enter the keyword",
      });

      // Create a close button
      var closeButton = $("<i>", {
        class: "bi bi-x cli-input-close-icon",
      });

      // Add the input and close button to the new input area
      newInputArea.append(inputElement);
      newInputArea.append(closeButton);

      // Append the new input area to the container
      $("#container1").append(newInputArea);

      // Use a timeout to trigger the transition after the element is added
      setTimeout(function () {
        newInputArea.addClass("active");
      }, 10);

      // Handle the close button click event
      closeButton.click(function () {
        // Remove the class to trigger the transition
        newInputArea.removeClass("active");

        // Remove the input area after the transition ends
        setTimeout(function () {
          newInputArea.remove();
        }, 300); // Adjust the time to match your transition duration
      });
    });
    ////

    ///add multi input fields for search keyword
    $(".cli--tal-search-skill-add-input-button").click(function () {
      // Create a new input area
      var newInputArea = $("<div>", {
        class: "cli-tal-pro-search-filter-multi-input-area",
      });

      // Create an input element
      var inputElement = $("<input>", {
        type: "text",
        name: "exclude_skill",
        class: "cli-tal-pro-search-filter-input",
        placeholder: "Enter the skill",
      });

      // Create a close button
      var closeButton = $("<i>", {
        class: "bi bi-x cli-input-close-icon",
      });

      // Add the input and close button to the new input area
      newInputArea.append(inputElement);
      newInputArea.append(closeButton);

      // Append the new input area to the container
      $("#container2").append(newInputArea);

      // Use a timeout to trigger the transition after the element is added
      setTimeout(function () {
        newInputArea.addClass("active");
      }, 10);

      // Handle the close button click event
      closeButton.click(function () {
        // Remove the class to trigger the transition
        newInputArea.removeClass("active");

        // Remove the input area after the transition ends
        setTimeout(function () {
          newInputArea.remove();
        }, 300); // Adjust the time to match your transition duration
      });
    });
    ////

    ///add multi input fields for qualification
    const addEducationInputField = () => {
      // Create a new input area
      var newInputArea = $("<div>", {
        class: "cli-tal-pro-search-filter-multi-input-area",
      });

      // Create an input element
      var inputElement = $("<input>", {
        type: "text",
        name: "qualification",
        class: "cli-tal-pro-search-filter-input",
        placeholder: "Enter the PPG/Doctorate Qualification",
      });

      // Create a close button
      var closeButton = $("<i>", {
        class: "bi bi-x cli-input-close-icon",
      });

      // Add the input and close button to the new input area
      newInputArea.append(inputElement);
      newInputArea.append(closeButton);

      // Append the new input area to the container
      $("#container3").append(newInputArea);

      // Use a timeout to trigger the transition after the element is added
      setTimeout(function () {
        newInputArea.addClass("active");
      }, 10);

      // Handle the close button click event
      closeButton.click(function () {
        // Remove the class to trigger the transition
        newInputArea.removeClass("active");

        // Remove the input area after the transition ends
        setTimeout(function () {
          newInputArea.remove();
        }, 300); // Adjust the time to match your transition duration
      });
    };
    ////

    ///add multi input fields for keyword in search page
    $(".cli--tal-search-keyword-add-input-button-search").click(function () {
      // Create a new input area
      var newInputArea = $("<div>", {
        class: "cli-tal-pro-search-filter-multi-input-area",
      });

      // Create an input element
      var inputElement = $("<input>", {
        type: "text",
        name: "qualification",
        class: "cli-tal-pro-search-filter-input",
        placeholder: "Enter the PPG/Doctorate Qualification",
      });

      // Create a close button
      var closeButton = $("<i>", {
        class: "bi bi-x cli-input-close-icon",
      });

      // Add the input and close button to the new input area
      newInputArea.append(inputElement);
      newInputArea.append(closeButton);

      // Append the new input area to the container
      $("#containerSearch").append(newInputArea);

      // Use a timeout to trigger the transition after the element is added
      setTimeout(function () {
        newInputArea.addClass("active");
      }, 10);

      // Handle the close button click event
      closeButton.click(function () {
        // Remove the class to trigger the transition
        newInputArea.removeClass("active");

        // Remove the input area after the transition ends
        setTimeout(function () {
          newInputArea.remove();
        }, 300); // Adjust the time to match your transition duration
      });
    });
    ////

    ///add multi input fields for skills in search page
    $(".cli--tal-search-skill-add-input-button-search").click(function () {
      // Create a new input area
      var newInputArea = $("<div>", {
        class: "cli-tal-pro-search-filter-multi-input-area",
      });

      // Create an input element
      var inputElement = $("<input>", {
        type: "text",
        name: "qualification",
        class: "cli-tal-pro-search-filter-input",
        placeholder: "Enter the PPG/Doctorate Qualification",
      });

      // Create a close button
      var closeButton = $("<i>", {
        class: "bi bi-x cli-input-close-icon",
      });

      // Add the input and close button to the new input area
      newInputArea.append(inputElement);
      newInputArea.append(closeButton);

      // Append the new input area to the container
      $("#containerSearch2").append(newInputArea);

      // Use a timeout to trigger the transition after the element is added
      setTimeout(function () {
        newInputArea.addClass("active");
      }, 10);

      // Handle the close button click event
      closeButton.click(function () {
        // Remove the class to trigger the transition
        newInputArea.removeClass("active");

        // Remove the input area after the transition ends
        setTimeout(function () {
          newInputArea.remove();
        }, 300); // Adjust the time to match your transition duration
      });
    });
    ////

    ///for search filter toggle
    function handleFilterToggle() {
      var expandArea = $(this)
        .closest(".cli-tal-pro-search-filter-content-section")
        .find(".cli-tal-pro-search-filter-expand-area");

      if (expandArea.hasClass("opened")) {
        expandArea.slideUp();
        expandArea.removeClass("opened");
        $(this).removeClass("opened");
      } else {
        expandArea.slideDown();
        expandArea.addClass("opened");
        $(this).addClass("opened");
      }
    }
    ////

    ////for custom select option for days
    var defaultOption = $(".select-options li:first-child");
    $(".select-box span").text(defaultOption.text());

    $(".select-box").on("click", function () {
      var selectBox = $(this);
      var toggleIcon = selectBox.find(".toggle-icon");
      var selectOptions = selectBox.next(".select-options");

      selectOptions.slideToggle(300, function () {
        if (selectOptions.is(":visible")) {
          toggleIcon.css("transform", "rotateX(180deg)");
          selectBox.addClass("active");
        } else {
          toggleIcon.css("transform", "rotateX(0deg)");
          selectBox.removeClass("active");
        }
      });
    });

    $(".select-options li").on("click", function () {
      var selectedValue = $(this).data("value");
      $(".select-box span").text($(this).text());
      $(".select-options").slideUp();
      $(".select-box .toggle-icon").css("transform", "rotateX(0deg)");
      $(".select-box").removeClass("active");

      // You can do something with the selected value here
      console.log("Selected value: " + selectedValue);
    });

    $(document).on("click", function (e) {
      if (!$(e.target).closest(".custom-select").length) {
        $(".select-options").slideUp();
        $(".select-box .toggle-icon").css("transform", "rotateX(0deg)");
        $(".select-box").removeClass("active");
      }
    });
    ////

    // $('.talent--profile-card .tal--pro-card-contact-btn').hover(
    //     function () {
    //         // Check if the checkbox is not checked
    //         if (!$(this).closest('.talent--profile-card').find('.tal--checkbox').prop('checked')) {
    //             // On hover in
    //             var newText = $(this).next('.profile-credits-title').text();
    //             $(this).text(newText);
    //         }
    //     },
    //     function () {
    //         // Check if the checkbox is not checked
    //         if (!$(this).closest('.talent--profile-card').find('.tal--checkbox').prop('checked')) {
    //             // On hover out
    //             var originalText = "View Profile"; // Replace with your original text
    //             $(this).text(originalText);
    //         }
    //     }
    // );

    //navigate to top while press buttons
    $(".tal--pro-slider-btn").on("click", function () {
      $("html, body").animate({ scrollTop: 0 }, "slow");
    });

    $(".custom-pagination-page-link").on("click", function () {
      $("html, body").animate({ scrollTop: 0 }, "slow");
    });

    $(".custom-pagination-btn").on("click", function () {
      $("html, body").animate({ scrollTop: 0 }, "slow");
    });

    $(".cli-tal-pro-search-page-btn").on("click", function () {
      $("html, body").animate({ scrollTop: 0 }, "slow");
    });

    $(".tal--search-submit-btn").on("click", function () {
      $("html, body").animate({ scrollTop: 0 }, "slow");
    });

    //// Attach Event
    $(".cli-tal-pro-search-filter-toggle-area").on("click", handleFilterToggle);
    $(".cli--tal-search-qualification-add-input-button").click(
      addEducationInputField
    );

    // Cleanup function to remove event listeners when the component unmounts
    return () => {
      $(".tal--pro-slider-btn").off("click");
      $("custom-pagination-page-link").off("click");
      $(".custom-pagination-btn").off("click");
      $(".cli-tal-pro-search-page-btn").off("click");
      $(".tal--search-submit-btn").off("click");
      $(".cli-tal-pro-search-filter-toggle-area").off(
        "click",
        handleFilterToggle
      );
      $(".cli--tal-search-qualification-add-input-button").off(
        "click",
        addEducationInputField
      );
    };
    // });
  }, [searchResult]);

  const handleMouseEnter = (event) => {
    const button = event.target;
    const profileCard = button.closest(".talent--profile-card");

    if (!profileCard.querySelector(".tal--checkbox").checked) {
      const newText = button.nextElementSibling.textContent;
      button.textContent = newText;
    }
  };

  const handleMouseLeave = (event) => {
    const button = event.target;
    const profileCard = button.closest(".talent--profile-card");

    if (!profileCard.querySelector(".tal--checkbox").checked) {
      const originalText = "View Profile"; // Replace with your original text
      button.textContent = originalText;
    }
  };

  const handleKeywordSearch = () => {
    if (inCommingData) {
      setPage(1);
      setPageValue(1);
      setsearchLoading(true);
      const filterData = {
        page: 1,
        limit: 25,
        noticePeriod: checkBoxfilters,
        keyWords: selectedResults,
        minExp: filters.minExperienceYr,
        maxExp: filters.maxExperienceYr,
        location: selectedLocationResults,
        currencyType: filters.currencyType,
        minSalary: filters.minSalary,
        maxSalary: filters.maxSalary,
        companyName: filters.company,
        education: selectedEducationResults,
        gender: filters.gender,
        activeIn: filters.activeIn,
        newReg: filters.candidateType
      }

      axios.post(`${process.env.REACT_APP_BASE_API}/filtered-cands`, filterData)
        .then(res => {
          console.log(res.data);
          setSearchResult(true);
          if (res.data.message) {
            setFilteredSearchResultsMsg(res.data.message);
            setInCommingData(null);
          } else {
            setApiResult(res.data);
            setFilteredSearchResults(res.data.data);
            setInCommingData(null);
          }
        }).catch(err => {
          console.log(err);
          showErrorMessage(err.response.data.error);
        })
    }
  };

  useEffect(() => {
    const { keywords, filterData } = location.state || {};
    keywords && setInCommingData(keywords);
    filterData && setFilteringData(filterData);
  }, [location.state, page]);

  useEffect(() => {
    if (inCommingData) {
      setSelectedResults(inCommingData);
    }
  }, [inCommingData]);

  useEffect(() => {
    handleKeywordSearch();
  }, [selectedResults]);

  //for show success message for payment
  function showSuccessMessage(message) {
    Swal.fire({
      title: "Success!",
      text: message,
      icon: "success",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
    });
  }

  //for show error message for payment
  function showErrorMessage(message) {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      confirmButtonColor: "#d33",
      confirmButtonText: "OK",
    });
  }

  const getAllSkills = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_API}/skills`, {
        headers: {
          Authorization: `Bearer ${clientToken}`,
          Accept: "application/json",
        },
      });
      const result = res.data;
      if (!result.error) {
        console.log(result);
        setSkillArray(result);
      } else {
        console.log(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllJobRoles = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_API}/designations`,
        {
          headers: {
            Authorization: `Bearer ${clientToken}`,
            Accept: "application/json",
          },
        }
      );
      const result = res.data;
      if (!result.error) {
        console.log(result);
        setjobRoleArray(result);
      } else {
        console.log(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllLocations = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_API}/locations`,
        {
          headers: {
            Authorization: `Bearer ${clientToken}`,
            Accept: "application/json",
          },
        }
      );
      const result = res.data;
      if (!result.error) {
        console.log(result);
        setLocationArray(result);
      } else {
        console.log(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllEducations = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_API}/educations`,
        {
          headers: {
            Authorization: `Bearer ${clientToken}`,
            Accept: "application/json",
          },
        }
      );
      const result = res.data;
      if (!result.error) {
        console.log(result);
        setEducationArray(result);
      } else {
        console.log(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllRoles = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_API}/roles`, {
        headers: {
          Authorization: `Bearer ${clientToken}`,
          Accept: "application/json",
        },
      });
      const result = res.data;
      if (!result.error) {
        console.log(result);
        setRoleArray(result);
      } else {
        console.log(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllIndustries = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_API}/industries`,
        {
          headers: {
            Authorization: `Bearer ${clientToken}`,
            Accept: "application/json",
          },
        }
      );
      const result = res.data;
      if (!result.error) {
        console.log(result);
        setIndustryArray(result);
      } else {
        console.log(result);
      }
    } catch (err) {
      console.log(err);
    }
  };


  const getAllCandidateDetail = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API}/candidate-Detail`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      const result = response.data;
      if (!result.error) {
        console.log(result);
        setCandidateDetail(result.reverse());
      } else {
        console.log(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllRecentSearch = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API}/recent-search/${employeeId}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      const result = response.data;
      if (!result.error) {
        console.log(result);
        setRecentSearches(result.reverse().slice(0, 10));
      } else {
        console.log(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // getAllCandidateDetail();
    getAllJobRoles();
    getAllSkills();
    getAllLocations();
    getAllEducations();
    getAllRoles();
    getAllIndustries();
    getCandidateImg();
  }, []);

  const getLoginClientDetail = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_API}/client/${employeeId}`,
        {
          headers: {
            Authorization: `Bearer ${clientToken}`,
            Accept: "application/json",
          },
        }
      );
      const result = res.data;
      if (!result.error) {
        console.log(result);
        setLoginClientDetail(result);
      } else {
        console.log(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getViewedCandidates = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_API}/cv-views/${loginClientDetail.companyId}`,
        {
          headers: {
            Authorization: `Bearer ${clientToken}`,
            Accept: "application/json",
          },
        }
      );
      const result = res.data;
      if (!result.error) {
        console.log(result);
        setViewedCandidate(result);
      } else {
        console.log(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (clientToken) {
      const fetchData = async () => {
        try {
          const user = await getProtectedData(clientToken);
          console.log(user);
          setEmployeeId(user.id || user.uid);
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }
  }, [clientToken]);

  useEffect(() => {
    if (employeeId) {
      getLoginClientDetail();
      getAllRecentSearch();
    }
  }, [employeeId]);

  useEffect(() => {
    if (loginClientDetail.companyId) {
      getViewedCandidates();
      const fetchData = async () => {
        try {
          await getClientChoosenPlan(loginClientDetail.companyId);

          if (packageSelectionDetail && packageSelectionDetail.cvViews) {
            setCvViews(packageSelectionDetail.cvViews);
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }
  }, [loginClientDetail]);


  // console.log(filters);

  const handleCheckboxChange = (category) => {
    console.log(category);
    const updatedFilters = checkBoxfilters.includes(category)
      ? checkBoxfilters.filter((filter) => filter !== category)
      : [...checkBoxfilters, category];
    setCheckBoxFilters(updatedFilters);
  };

  const isWithinDays = (dateString, days) => {
    const today = new Date();
    const targetDate = new Date(dateString);
    const difference = Math.abs(targetDate.getTime() - today.getTime());
    const daysDifference = Math.ceil(difference / (1000 * 3600 * 24));
    console.log(daysDifference);
    return parseInt(daysDifference) <= parseInt(days);
  };

  const candidateTestDetail = [
    {
      name: "1",
      activeIn: "Sat Feb 03 2024 12:31:50 GMT+0530 (India Standard Time)",
    },
    {
      name: "2",
      activeIn: "Sat Jan 26 2024 12:31:50 GMT+0530 (India Standard Time)",
    },
  ];

  // const handleSkillSearch = () => {
  //   if (
  //     checkBoxfilters.length > 0 ||
  //     selectedResults.length > 0 ||
  //     selectedLocationResults.length > 0 ||
  //     (filters.minExperienceYr
  //       // && filters.minExperienceMonth
  //     ) ||
  //     (filters.maxExperienceYr
  //       // && filters.maxExperienceMonth
  //     ) ||
  //     (filters.currencyType && (filters.minSalary || filters.maxSalary)) ||
  //     selectedEducationResults.length > 0 ||
  //     // || selectedRoleResults.length > 0
  //     // || filters.industry
  //     filters.company ||
  //     filters.candidateType ||
  //     // || filters.gender
  //     filters.activeIn
  //   ) {
  //     const recentSearch = {
  //       id: employeeId,
  //       ...(filters.days && { days: filters.days }),
  //       ...(selectedResults.length > 0 && { selectedResults }),
  //       ...(selectedLocationResults.length > 0 && { selectedLocationResults }),
  //       ...(filters.minExperienceYr && {
  //         minExperienceYr: filters.minExperienceYr,
  //       }),
  //       // ...(filters.minExperienceMonth && {
  //       //   minExperienceMonth: filters.minExperienceMonth,
  //       // }),
  //       ...(filters.maxExperienceYr && {
  //         maxExperienceYr: filters.maxExperienceYr,
  //       }),
  //       // ...(filters.maxExperienceMonth && {
  //       //   maxExperienceMonth: filters.maxExperienceMonth,
  //       // }),
  //       ...(filters.minSalary && { minSalary: filters.minSalary }),
  //       ...(filters.maxSalary && { maxSalary: filters.maxSalary }),
  //       ...(selectedEducationResults.length > 0 && {
  //         selectedEducationResults,
  //       }),
  //       ...(filters.company && { company: filters.company }),
  //       ...(filters.candidateType && { candidateType: filters.candidateType }),
  //     };

  //     // setX([0, 25]);
  //     setFilteredSearchResultsMsg("");
  //     setSearchResult(true);

  //     //         const filteredResults = candidateDetail
  //     //                 .filter(candidate => {
  //     //                     if (checkBoxfilters.length > 0) {
  //     //                         const anyFilterPresent = checkBoxfilters.includes('Any');
  //     //                         if (anyFilterPresent) {
  //     //                             return true;
  //     //                         }
  //     //                         return checkBoxfilters.includes(candidate.days);
  //     //                     }
  //     //                     return true;
  //     //                 })
  //     //                 .filter(candidate => {
  //     //                     if (selectedResults.length > 0) {
  //     //                         return selectedResults.some(result =>
  //     //                             candidate.skills.includes(result) || candidate.designation.includes(result)
  //     //                         );
  //     //                     }
  //     //                     return true;
  //     //                 })
  //     //                 .filter(candidate => {
  //     //                     if ((filters.minExperienceYr && filters.minExperienceMonth) && (filters.maxExperienceYr && filters.maxExperienceMonth)) {
  //     //                         return ((candidate.year >= (filters.minExperienceYr) && (filters.maxExperienceYr)) && (candidate.month >= (filters.minExperienceMonth && filters.maxExperienceMonth)))
  //     //                     }
  //     //                     return true;
  //     //                 })
  //     //                 .filter(candidate => {
  //     //                     if ((filters.minExperienceYr && filters.minExperienceMonth) && !(filters.maxExperienceYr && filters.maxExperienceMonth)) {
  //     //                         return ((candidate.year >= filters.minExperienceYr) && (candidate.month >= filters.minExperienceMonth))
  //     //                     }
  //     //                     return true;
  //     //                 })
  //     //                 .filter(candidate => {
  //     //                     if ((filters.maxExperienceYr && filters.maxExperienceMonth) && !(filters.minExperienceYr && filters.minExperienceMonth)) {
  //     //                         return ((candidate.year >= filters.maxExperienceYr) && (candidate.month >= filters.maxExperienceMonth))
  //     //                     }
  //     //                     return true;
  //     //                 })
  //     //                 .filter(candidate => {
  //     //                     if (selectedLocationResults.length > 0) {

  //     //                         return (candidate?.preferedlocations.some(loc => selectedLocationResults.includes(loc)) || selectedLocationResults.includes(candidate.location))

  //     //                     }
  //     //                     return true;
  //     //                 })
  //     //                 // .filter(candidate => {
  //     //                 //     if (selectedLocationResults.length > 0 && typeof candidate.location  === "string") {
  //     //                 //         return selectedLocationResults.includes(candidate.location);
  //     //                 //     }
  //     //                 //     return true;
  //     //                 // })
  //     //                 .filter(candidate => {
  //     //                     if (filters.currencyType && filters.minSalary && filters.maxSalary) {
  //     //                         return (
  //     //                             (candidate?.currencyType === filters.currencyType) &&
  //     //                             (
  //     //                                 (
  //     //                                     parseInt(filters.minSalary)<= parseInt(candidate?.minSalary) <= parseInt(filters.maxSalary)
  //     //                                 ) ||
  //     //                                 (
  //     //                                     parseInt(filters.minSalary)<= parseInt(candidate?.maxSalary) <= parseInt(filters.maxSalary)
  //     //                                 ) ||
  //     //                                 (
  //     //                                     (parseInt(filters.minSalary)<= parseInt(candidate?.minSalary) <= parseInt(filters.maxSalary)) && (parseInt(filters.minSalary)<= parseInt(candidate?.maxSalary) <= parseInt(filters.maxSalary))
  //     //                                 )
  //     //                             )
  //     //                         )
  //     //                     }
  //     //                     return true;
  //     //                 })
  //     //                 .filter(candidate => {
  //     //                     if (filters.currencyType && filters.minSalary && !(filters.maxSalary)) {
  //     //                         return ((candidate?.currencyType === filters.currencyType) && (parseInt(candidate?.minSalary) <= parseInt(filters.minSalary)))
  //     //                     }
  //     //                     return true;
  //     //                 })
  //     //                 .filter(candidate => {
  //     //                     if (filters.currencyType && filters.maxSalary && !(filters.minSalary)) {
  //     //                         return ((candidate?.currencyType === filters.currencyType) && (parseInt(candidate.maxSalary) <= parseInt(filters.maxSalary)))
  //     //                     }
  //     //                     return true;
  //     //                 })
  //     //                 .filter(candidate => {
  //     //                     if (selectedEducationResults.length > 0 ) {

  //     //                         return candidate.education.some(edu => selectedEducationResults.includes(edu))

  //     //                     }
  //     //                     return true;
  //     //                 })
  //     //                 // .filter(candidate => {
  //     //                 //     if (selectedRoleResults.length > 0) {
  //     //                 //         return selectedRoleResults.some(result => result === candidate?.role);
  //     //                 //     }
  //     //                 //     return true;
  //     //                 // })
  //     //                 // .filter(candidate => {
  //     //                 //     if (selectedIndustryResults.length > 0) {
  //     //                 //         return selectedIndustryResults.filter(result =>
  //     //                 //             candidate.industry.includes(result)
  //     //                 //         );
  //     //                 //     }
  //     //                 //     return true;
  //     //                 // })
  //     //                 .filter(candidate => {
  //     //                     if (filters.company) {
  //     //                         return candidate.companyName.toLowerCase() === filters.company.toLowerCase()
  //     //                     }
  //     //                     return true;
  //     //                 })
  //     //                 // .filter(candidate => {
  //     //                 //     if (filters.gender) {
  //     //                 //         return candidate.gender === filters.gender
  //     //                 //     }
  //     //                 //     return true;
  //     //                 // })

  //     //                 if (filters.candidateType === "newRegistration") {
  //     //                     filteredResults
  //     //                         .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  //     //                         .slice(0, 10)
  //     //                         .filter(candidate => {
  //     //                             if(filters.activeIn){
  //     //                                 console.log(candidate?.activeIn)
  //     //                                 return isWithinDays(candidate?.activeIn, filters.activeIn)
  //     //                             }
  //     //                             return true;
  //     //                         })
  //     //                 } else if (filters.candidateType === "allCandidates") {
  //     //                      filteredResults
  //     //                         .filter(candidate => {
  //     //                             if(filters.activeIn){
  //     //                                 console.log(candidate?.activeIn)
  //     //                                 return isWithinDays(candidate?.activeIn, filters.activeIn)
  //     //                             }
  //     //                             return true;
  //     //                         })
  //     //                 }

  //     //                 console.log(filteredResults)

  //     //             if (filteredResults.length > 0) {
  //     //                 setFilteredSearchResults(filteredResults);
  //     //                 axios.post(`${process.env.REACT_APP_BASE_API}/recent-search`, recentSearch)
  //     //                     .then(res => {
  //     //                         console.log(res.data)
  //     //                         getAllRecentSearch();
  //     //                     })
  //     //                     .catch(err => console.log(err))
  //     //             } else {
  //     //                 setFilteredSearchResultsMsg("no such candidates found")
  //     //             }

  //     const filterData = {
  //       page: 1,
  //       limit: 25,
  //       noticePeriod: checkBoxfilters,
  //       keyWords: selectedResults,
  //       minExp: filters.minExperienceYr,
  //       maxExp: filters.maxExperienceYr,
  //       location: selectedLocationResults,
  //       currencyType: filters.currencyType,
  //       minSalary: filters.minSalary,
  //       maxSalary: filters.maxSalary,
  //       companyName: filters.company,
  //       education: selectedEducationResults,
  //       gender: filters.gender,
  //       activeIn: filters.activeIn,
  //       newReg: filters.candidateType
  //     }

  //     axios.post(`${process.env.REACT_APP_BASE_API}/filtered-cands`, filterData)
  //     .then(res=>{
  //       console.log(res.data);
  //       setFilteredSearchResults(res.data.data);
  //     }).catch(err=>{
  //       console.log(err);
  //       showErrorMessage(err.response.data.error);
  //       setFilteredSearchResultsMsg(err.response.data.error);
  //     })

  //   //   const filteredResults = candidateDetail
  //   //     .filter((candidate) => {
  //   //       if (checkBoxfilters.length > 0) {
  //   //         const anyFilterPresent = checkBoxfilters.includes("Any");
  //   //         if (anyFilterPresent) {
  //   //           return true;
  //   //         }
  //   //         return checkBoxfilters.includes(candidate.days);
  //   //       }
  //   //       return true;
  //   //     })
  //   //     .filter((candidate) => {
  //   //       if (selectedResults.length > 0) {
  //   //         return selectedResults.some(
  //   //           (result) =>
  //   //             candidate.skills.includes(result) ||
  //   //             candidate.designation.includes(result)
  //   //         );
  //   //       }
  //   //       return true;
  //   //     })
  //   //     .filter((candidate) => {
  //   //       if (
  //   //         filters.minExperienceYr &&
  //   //         // filters.minExperienceMonth &&
  //   //         filters.maxExperienceYr
  //   //         // &&
  //   //         // filters.maxExperienceMonth
  //   //       ) {
  //   //         return (
  //   //           candidate.year >= filters.minExperienceYr &&
  //   //           candidate.year <= filters.maxExperienceYr
  //   //           // &&
  //   //           // candidate.month >= filters.minExperienceMonth &&
  //   //           // candidate.month <= filters.maxExperienceMonth
  //   //         );
  //   //       }
  //   //       return true;
  //   //     })
  //   //     .filter((candidate) => {
  //   //       if (
  //   //         filters.minExperienceYr
  //   //         //  &&
  //   //         // filters.minExperienceMonth 
  //   //         &&
  //   //         !filters.maxExperienceYr
  //   //         // &&
  //   //         // !filters.maxExperienceMonth
  //   //       ) {
  //   //         return (
  //   //           candidate.year >= filters.minExperienceYr
  //   //           // &&
  //   //           // candidate.month >= filters.minExperienceMonth
  //   //         );
  //   //       }
  //   //       return true;
  //   //     })
  //   //     .filter((candidate) => {
  //   //       if (
  //   //         !filters.minExperienceYr
  //   //         // &&
  //   //         // !filters.minExperienceMonth 
  //   //         &&
  //   //         filters.maxExperienceYr
  //   //         // &&
  //   //         // filters.maxExperienceMonth
  //   //       ) {
  //   //         return (
  //   //           candidate.year <= filters.maxExperienceYr
  //   //           //  &&
  //   //           // candidate.month <= filters.maxExperienceMonth
  //   //         );
  //   //       }
  //   //       return true;
  //   //     })
  //   //     .filter((candidate) => {
  //   //       if (selectedLocationResults.length > 0) {
  //   //         return (
  //   //           candidate?.preferedlocations.some((loc) =>
  //   //             selectedLocationResults.includes(loc)
  //   //           ) || selectedLocationResults.includes(candidate.location)
  //   //         );
  //   //       }
  //   //       return true;
  //   //     })
  //   //     .filter((candidate) => {
  //   //       if (filters.currencyType && filters.minSalary && filters.maxSalary) {
  //   //         return (
  //   //           candidate?.currencyType === filters.currencyType &&
  //   //           parseInt(candidate?.minSalary) >= parseInt(filters.minSalary) &&
  //   //           parseInt(candidate?.maxSalary) <= parseInt(filters.maxSalary)
  //   //         );
  //   //       }
  //   //       return true;
  //   //     })
  //   //     .filter((candidate) => {
  //   //       if (filters.currencyType && filters.minSalary && !filters.maxSalary) {
  //   //         return (
  //   //           candidate?.currencyType === filters.currencyType &&
  //   //           parseInt(candidate?.minSalary) <= parseInt(filters.minSalary)
  //   //         );
  //   //       }
  //   //       return true;
  //   //     })
  //   //     .filter((candidate) => {
  //   //       if (filters.currencyType && !filters.minSalary && filters.maxSalary) {
  //   //         return (
  //   //           candidate?.currencyType === filters.currencyType &&
  //   //           parseInt(candidate.maxSalary) <= parseInt(filters.maxSalary)
  //   //         );
  //   //       }
  //   //       return true;
  //   //     })
  //   //     .filter((candidate) => {
  //   //       if (selectedEducationResults.length > 0) {
  //   //         return candidate.education.some((edu) =>
  //   //           selectedEducationResults.includes(edu)
  //   //         );
  //   //       }
  //   //       return true;
  //   //     })
  //   //     .filter((candidate) => {
  //   //       if (filters.company) {
  //   //         return (
  //   //           candidate.companyName.toLowerCase() ===
  //   //           filters.company.toLowerCase()
  //   //         );
  //   //       }
  //   //       return true;
  //   //     });

  //   //   let finalFilteredResults = filteredResults;

  //   //   if (filters.candidateType === "newRegistration") {
  //   //     finalFilteredResults = filteredResults
  //   //       .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  //   //       .slice(0, 10)
  //   //       .filter((candidate) => {
  //   //         if (filters.activeIn) {
  //   //           console.log(candidate?.activeIn);
  //   //           return isWithinDays(candidate?.activeIn, filters.activeIn);
  //   //         }
  //   //         return true;
  //   //       });
  //   //   } else if (filters.candidateType === "allCandidates") {
  //   //     finalFilteredResults = filteredResults.filter((candidate) => {
  //   //       if (filters.activeIn) {
  //   //         console.log(candidate?.activeIn);
  //   //         return isWithinDays(candidate?.activeIn, filters.activeIn);
  //   //       }
  //   //       return true;
  //   //     });
  //   //   }

  //   //   finalFilteredResults = filteredResults.filter((candidate) => {
  //   //     if (filters.activeIn) {
  //   //       console.log(candidate?.activeIn);
  //   //       return isWithinDays(candidate?.activeIn, filters.activeIn);
  //   //     }
  //   //     return true;
  //   //   });

  //   //   console.log(finalFilteredResults);

  //   //   if (finalFilteredResults.length > 0) {
  //   //     setFilteredSearchResults(finalFilteredResults);
  //   //     if (employeeId) {
  //   //       axios
  //   //         .post(
  //   //           `${process.env.REACT_APP_BASE_API}/recent-search`,
  //   //           recentSearch
  //   //         )
  //   //         .then((res) => {
  //   //           console.log(res.data);
  //   //           getAllRecentSearch();
  //   //         })
  //   //         .catch((err) => console.log(err));
  //   //     }
  //   //   } else {
  //   //     setFilteredSearchResultsMsg("No candidates found");
  //   //   }
  //   } else {
  //      showErrorMessage("Choose one filter.");
  //    }
  // };

  useEffect(() => {
    if (page) {
      const filterData = {
        page,
        limit: 25,
        noticePeriod: checkBoxfilters,
        keyWords: selectedResults,
        minExp: filters.minExperienceYr,
        maxExp: filters.maxExperienceYr,
        location: selectedLocationResults,
        currencyType: filters.currencyType,
        minSalary: filters.minSalary,
        maxSalary: filters.maxSalary,
        companyName: filters.company,
        education: selectedEducationResults,
        gender: filters.gender,
        activeIn: filters.activeIn,
        newReg: filters.candidateType
      }

      setsearchLoading(true);

      axios.post(`${process.env.REACT_APP_BASE_API}/filtered-cands`, filterData)
        .then(res => {
          console.log(res.data);
          setApiResult(res.data);
          setFilteredSearchResults(res.data.data);
          setsearchLoading(false);
        }).catch(err => {
          console.log(err);
          showErrorMessage(err.response.data.error);
          setsearchLoading(false);
        })
    }
  }, [page])

  useEffect(() => {
    if (filteringData) {
      const filterData = filteringData

      setsearchLoading(true);
      setPage(filteringData.page);
      setFilters({
        ...filters,
        minExperienceYr: filteringData.minExp,
        maxExperienceYr: filteringData.maxExp,
        currencyType: filteringData.currencyType,
        minSalary: filteringData.minSalary,
        maxSalary: filteringData.maxSalary,
        company: filteringData.companyName,
        gender: filteringData.gender,
        activeIn: filteringData.activeIn,
        candidateType: filteringData.newReg
      });
      setCheckBoxFilters(filterData.noticePeriod);
      setSelectedResults(filteringData.keyWords);
      setSelectedLocationResults(filteringData.location);
      setselectedEducationResults(filteringData.education);

      axios.post(`${process.env.REACT_APP_BASE_API}/filtered-cands`, filterData)
        .then(res => {
          console.log(res.data);
          setSearchResult(true);
          setApiResult(res.data);
          setFilteredSearchResults(res.data.data);
          setsearchLoading(false);
        }).catch(err => {
          console.log(err);
          showErrorMessage(err.response.data.error);
          setsearchLoading(false);
        })
    }
  }, [filteringData])

  const handleSkillSearch = () => {
    if (
      checkBoxfilters.length > 0 ||
      selectedResults.length > 0 ||
      selectedLocationResults.length > 0 ||
      filters.minExperienceYr ||
      filters.maxExperienceYr ||
      (filters.currencyType && (filters.minSalary || filters.maxSalary)) ||
      selectedEducationResults.length > 0 ||
      filters.company ||
      filters.candidateType ||
      filters.gender ||
      filters.activeIn
    ) {
      setPage(1);
      setPageValue(1);
      setsearchLoading(true);
      setFilteredSearchResults([]); // Clear previous search results
      setFilteredSearchResultsMsg(""); // Clear previous search message
      const recentSearch = {
        id: employeeId,
        ...(checkBoxfilters.length > 0 && { checkBoxfilters }),
        ...(selectedResults.length > 0 && { selectedResults }),
        ...(selectedLocationResults.length > 0 && { selectedLocationResults }),
        ...(filters.minExperienceYr && { minExperienceYr: filters.minExperienceYr }),
        ...(filters.maxExperienceYr && { maxExperienceYr: filters.maxExperienceYr }),
        ...(filters.currencyType && { currencyType: filters.currencyType }),
        ...(filters.minSalary && { minSalary: filters.minSalary }),
        ...(filters.maxSalary && { maxSalary: filters.maxSalary }),
        ...(selectedEducationResults.length > 0 && { selectedEducationResults }),
        ...(filters.company && { company: filters.company }),
        ...(filters.candidateType && { candidateType: filters.candidateType }),
        ...(filters.gender && { gender: filters.gender }),
      };
      const filterData = {
        page: 1,
        limit: 25,
        noticePeriod: checkBoxfilters,
        keyWords: selectedResults,
        minExp: filters.minExperienceYr,
        maxExp: filters.maxExperienceYr,
        location: selectedLocationResults,
        currencyType: filters.currencyType,
        minSalary: filters.minSalary,
        maxSalary: filters.maxSalary,
        companyName: filters.company,
        education: selectedEducationResults,
        gender: filters.gender,
        activeIn: filters.activeIn,
        newReg: filters.candidateType
      };

      axios.post(`${process.env.REACT_APP_BASE_API}/filtered-cands`, filterData)
        .then(res => {
          console.log(res.data);
          setSearchResult(true);
          if (res.data.message) {
            setFilteredSearchResultsMsg(res.data.message);
          } else {
            setApiResult(res.data);
            setFilteredSearchResults(res.data.data);
            console.log(res.data.data);
          }

          if (employeeId) {
            axios.post(`${process.env.REACT_APP_BASE_API}/recent-search`, recentSearch)
              .then((res) => {
                console.log(res.data);
                getAllRecentSearch();
              })
              .catch((err) => console.log(err))
              .finally(() => {
                setsearchLoading(false);
              });
          } else {
            setsearchLoading(false);
          }
        })
        .catch(err => {
          console.log(err);
          showErrorMessage(err.response ? err.response.data.error : "An error occurred while fetching data.");
          setsearchLoading(false);
        });
    } else {
      showErrorMessage("Choose one filter.");
    }
  };

  const handleFill = (id) => {
    const selectedSearchResult = recentSearches.find(
      (search) => search._id === id
    );
    if (selectedSearchResult) {
      setFilters({
        ...filters,
        minExperienceYr: selectedSearchResult?.minExperienceYr,
        maxExperienceYr: selectedSearchResult?.maxExperienceYr,
        minSalary: selectedSearchResult?.minSalary,
        maxSalary: selectedSearchResult?.maxSalary,
        company: selectedSearchResult?.company,
        candidateType: selectedSearchResult?.candidateType,
        gender: selectedSearchResult?.gender
      });
      setCheckBoxFilters(selectedSearchResult?.checkBoxfilters);
      setSelectedResults(selectedSearchResult?.selectedResults);
      setSelectedLocationResults(selectedSearchResult?.selectedLocationResults);
      setselectedEducationResults(selectedSearchResult?.selectedEducationResults);
    }
  };

  // const handleSearch = (e) => {
  //   const inputValue = e.target.value.trim();
  //   setFilters({ ...filters, searchInput: inputValue });

  //   if (inputValue.length > 0) {
  //     const lowerCaseInput = inputValue.toLowerCase();

  //     const skillsObj = skillArray.filter((obj) => {
  //       return obj.skill.toLowerCase().startsWith(lowerCaseInput) && obj.skill.length <= 40;
  //     });

  //     const jobRolesObj = jobRoleArray.filter((obj) => {
  //       return obj.designation.toLowerCase().startsWith(lowerCaseInput) && obj.designation.length <= 40;
  //     });

  //     const skills = skillsObj.map(skill => skill.skill);
  //     const jobRoles = jobRolesObj.map(jobRole => jobRole.designation);

  //     function combineArraysUnique(arr1, arr2) {
  //       const combinedSet = new Set([...arr1, ...arr2]);
  //       return Array.from(combinedSet);
  //     }

  //     const combinedResults = combineArraysUnique(skills, jobRoles);

  //     if (combinedResults.length > 0) {
  //       setFilteredList(combinedResults);
  //     } else {
  //       setFilteredList([]);
  //     }
  //   } else {
  //     setFilteredList([]);
  //   }
  // };


  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setFilters({ filters, searchInput: inputValue });

    if (inputValue.length > 0) {
      setFocusedKeywordIndex(0);
    } else {
      setFocusedKeywordIndex(-1);
    }
  };

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const keyword = {
          keyWord: filters.searchInput
        };
        setResultLoading(true);
        const response = await axios.post(`${process.env.REACT_APP_BASE_API}/find-keyword-results`, keyword);
        if (response.data.length === 0) {
          setFilteredList([]);
        } else {
          setFilteredList(response.data);
          setResultLoading(false);
        }
      } catch (error) {
        console.log(error);
        setFilteredList([]);
      }
    };

    if (filters.searchInput.trim() !== '') {
      fetchResults();
    } else if (filters.searchInput === '') {
      setFilteredList([]);
    }
  }, [filters.searchInput]);

  const handleFilteredClick = (clickResult) => {
    console.log(clickResult);
    if (selectedResults.includes(clickResult)) {
      setSelectedResults([...selectedResults]);
      setFilters({ ...filters, searchInput: "" });
      setFilteredList([]);
    } else {
      setSelectedResults([...selectedResults, clickResult]);
      setFilters({ ...filters, searchInput: "" });
      setFilteredList([]);
    }
  };

  const handleLocationSearch = (e) => {
    const inputValue = e.target.value;
    setFilters({ ...filters, location: inputValue });

    if (inputValue.length > 0) {
      const locations = locationArray.filter((obj) => {
        return obj.location.toLowerCase().includes(inputValue.toLowerCase());
      });

      if (locations.length > 0) {
        setFilteredLocation(locations);
        setFocusedLocationIndex(0);
      } else {
        setFilteredLocation([]);
        setFocusedLocationIndex(-1);
      }
    } else {
      setFilteredLocation([]);
      setFocusedLocationIndex(-1);
    }
  };

  const handleFilteredLocationClick = (clickResult) => {
    console.log(clickResult);
    if (selectedLocationResults.includes(clickResult)) {
      setSelectedLocationResults([...selectedLocationResults]);
      setFilters({ ...filters, location: "" });
      setFilteredLocation([]);
    } else {
      setSelectedLocationResults([...selectedLocationResults, clickResult]);
      setFilters({ ...filters, location: "" });
      setFilteredLocation([]);
    }
  };

  const handleEducationSearch = (e) => {
    const inputValue = e.target.value;
    setFilters({ ...filters, education: inputValue });

    if (inputValue.length > 0) {
      const educations = educationArray.filter((obj) => {
        return obj.education.toLowerCase().includes(inputValue.toLowerCase());
      });

      if (educations.length > 0) {
        setFilteredEducation(educations);
        setFocusedEducationIndex(0);
      } else {
        setFilteredEducation([]);
        setFocusedEducationIndex(-1);
      }
    } else {
      setFilteredEducation([]);
      setFocusedEducationIndex(-1);
    }
  };

  const handleFilteredEducationClick = (clickResult) => {
    console.log(clickResult);
    if (selectedEducationResults.includes(clickResult)) {
      setselectedEducationResults([...selectedEducationResults]);
      setFilters({ ...filters, education: "" });
      setFilteredEducation([]);
    } else {
      setselectedEducationResults([...selectedEducationResults, clickResult]);
      setFilters({ ...filters, education: "" });
      setFilteredEducation([]);
    }
  };

  const handleRoleSearch = (e) => {
    const inputValue = e.target.value;
    setFilters({ ...filters, role: inputValue });

    if (inputValue.length > 0) {
      const roles = roleArray.filter((obj) => {
        return obj.role.toLowerCase().includes(inputValue.toLowerCase());
      });

      if (roles.length > 0) {
        setFilteredRole(roles);
      } else {
        setFilteredRole([]);
      }
    } else {
      setFilteredRole([]);
    }
  };

  const handleIndustrySearch = (e) => {
    const inputValue = e.target.value;
    setFilters({ ...filters, industry: inputValue });

    if (inputValue.length > 0) {
      const industries = industryArray.filter((obj) => {
        return obj.industry.toLowerCase().includes(inputValue.toLowerCase());
      });

      if (industries.length > 0) {
        setFilteredIndustry(industries);
      } else {
        setFilteredIndustry([]);
      }
    } else {
      setFilteredIndustry([]);
    }
  };

  const handleFilteredRoleClick = (clickResult) => {
    console.log(clickResult);
    if (selectedRoleResults.includes(clickResult)) {
      setSelectedRoleResults([...selectedRoleResults]);
      setFilters({ ...filters, role: "" });
      setFilteredRole([]);
    } else {
      setSelectedRoleResults([...selectedRoleResults, clickResult]);
      setFilters({ ...filters, role: "" });
      setFilteredRole([]);
    }
  };

  const handleFilteredIndustryClick = (clickResult) => {
    console.log(clickResult);
    if (selectedIndustryResults.includes(clickResult)) {
      setSelectedIndustryResults([...selectedIndustryResults]);
      setFilters({ ...filters, industry: "" });
      setFilteredIndustry([]);
    } else {
      setSelectedIndustryResults([...selectedIndustryResults, clickResult]);
      setFilters({ ...filters, industry: "" });
      setFilteredIndustry([]);
    }
  };

  const handleDeselect = (result) => {
    setSelectedResults(
      selectedResults.filter((selected) => selected !== result)
    );
  };

  const handleDeselectEducation = (education) => {
    setselectedEducationResults(
      selectedEducationResults.filter(
        (selectedEducation) => selectedEducation !== education
      )
    );
  };

  const handleDeselectLocation = (location) => {
    setSelectedLocationResults(
      selectedLocationResults.filter(
        (selectedLocation) => selectedLocation !== location
      )
    );
  };

  const handleDeselectRole = (role) => {
    setSelectedRoleResults(
      selectedRoleResults.filter((selectedRole) => selectedRole !== role)
    );
  };

  const handleDeselectIndustry = (industry) => {
    setSelectedIndustryResults(
      selectedIndustryResults.filter(
        (selectedIndustry) => selectedIndustry !== industry
      )
    );
  };

  //   const handleSkillSearch = () => {
  //     setSearchResult(true);

  //     const searchResults = searchInput
  //       .split(/[,\s]+/)
  //       .filter(result => result.trim());

  //     const filteredObjBySkills = candidateDetail.filter(candidate =>
  //       searchResults.some(searchResult =>
  //         candidate.skills.some(skill =>
  //           skill.toLowerCase().includes(searchResult.toLowerCase())
  //         )
  //       )
  //     );

  //     const filteredObjByDesignation = candidateDetail.filter(candidate =>
  //       searchResults.some(searchResult =>
  //         candidate.designation[0].toLowerCase().includes(searchResult.toLowerCase())
  //       )
  //     );

  //     const mergedResults = [...filteredObjBySkills, ...filteredObjByDesignation];

  //     const filteredObjByExperienceMonth = candidateDetail.filter(candidate =>
  //         candidate.month >= minExperienceMonth &&
  //         candidate.month <= maxExperienceMonth
  //     );

  //     const filteredObjByExperienceYear = candidateDetail.filter(candidate =>
  //         candidate.year >= minExperienceYear &&
  //         candidate.year <= maxExperienceYear
  //     );

  //     const filteredObjByLocation = candidateDetail.filter(candidate =>
  //         candidate.location.toLowerCase().includes(location.toLowerCase())
  //     );

  //     // if(mergedResults.length > 0){
  //     //     setFilteredSearchResults(mergedResults);
  //     // }else{

  //     //     setFilteredSearchResultsMsg("no such candidates found")
  //     // }
  //   }

  // const viewCandidateDetail = async (id, percentage) => {
  //   try {
  //     const packageSelectionDetail = await getClientChoosenPlan(
  //       loginClientDetail.companyId
  //     );
  //     if (clientToken) {
  //       if (packageSelectionDetail) {
  //         if (viewedCandidate.length > 0) {
  //           const alreadyViewedCandidate = viewedCandidate.find(
  //             (cand) => cand.candidateId === id
  //           );
  //           if (alreadyViewedCandidate) {
  //             window.open(
  //               `${process.env.REACT_APP_ADMIN_BASE_URL}/talents/${id}?percentage=${percentage}`,
  //               "_blank"
  //             );
  //           } else {
  //             console.log(viewedCandidate.length);
  //             if (viewedCandidate.length < cvViews) {
  //               const idData = {
  //                 candidateId: id,
  //                 companyId: loginClientDetail.companyId,
  //               };
  //               const response = await axios.post(
  //                 `${process.env.REACT_APP_BASE_API}/cv-views`,
  //                 idData,
  //                 {
  //                   headers: {
  //                     Authorization: `Bearer ${clientToken}`,
  //                     Accept: "application/json",
  //                   },
  //                 }
  //               );

  //               const result = response.data;
  //               console.log(result);
  //               getViewedCandidates();
  //               window.open(
  //                 `${process.env.REACT_APP_ADMIN_BASE_URL}/talents/${id}?percentage=${percentage}`,
  //                 "_blank"
  //               );
  //             } else {
  //               await new Promise(() => {
  //                 Swal.fire({
  //                   title: "Buy Package Plan",
  //                   text: "You reached your max cv-views in your plan, upgrade your plan",
  //                   icon: "info",
  //                   confirmButtonColor: "#3085d6",
  //                   confirmButtonText: "OK",
  //                 }).then(() => {
  //                   window.open(
  //                     `${process.env.REACT_APP_ADMIN_BASE_URL}/package-plans`,
  //                     "_blank"
  //                   );
  //                 });
  //               });
  //             }
  //           }
  //         } else {
  //           const idData = {
  //             candidateId: id,
  //             companyId: loginClientDetail.companyId,
  //           };
  //           const response = await axios.post(
  //             `${process.env.REACT_APP_BASE_API}/cv-views`,
  //             idData,
  //             {
  //               headers: {
  //                 Authorization: `Bearer ${clientToken}`,
  //                 Accept: "application/json",
  //               },
  //             }
  //           );

  //           const result = response.data;
  //           console.log(result);
  //           getViewedCandidates();
  //           window.open(
  //             `${process.env.REACT_APP_ADMIN_BASE_URL}/talents/${id}?percentage=${percentage}`,
  //             "_blank"
  //           );
  //         }
  //       } else {
  //         await new Promise(() => {
  //           Swal.fire({
  //             title: "Buy Package Plan",
  //             text: "",
  //             icon: "info",
  //             confirmButtonColor: "#3085d6",
  //             confirmButtonText: "OK",
  //           }).then(() => {
  //             window.open(
  //               `${process.env.REACT_APP_ADMIN_BASE_URL}/package-plans`,
  //               "_blank"
  //             );
  //           });
  //         });
  //       }
  //     } else {
  //       navigate("/client-login");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const viewCandidateDetail = async (id, percentage) => {
    try {
      if (clientToken) {
        const response = await axios.post(`${process.env.REACT_APP_BASE_API}/cv-views`, {
          candidateId: id,
          companyId: loginClientDetail.companyId,
        }, {
          headers: {
            Authorization: `Bearer ${clientToken}`,
            Accept: `application/json`
          }
        });

        if (response.data.message === "Candidate Viewed" || response.data.message === "The candidate detail already viewed!") {
          let url = `${process.env.REACT_APP_ADMIN_BASE_URL}/talents/${id}?token=${encodeURIComponent(clientToken)}&loginId=${loginId}&employeeId=${employeeId}`;
          if (!isNaN(percentage)) {
            url += `&percentage=${percentage}`;
          }

          window.open(url, "_blank");
        } else {
          Swal.fire({
            title: 'Error!',
            text: '',
            icon: 'info',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
          });
        }
      } else {
        const filterData = {
          page,
          limit: 25,
          noticePeriod: checkBoxfilters,
          keyWords: selectedResults,
          minExp: filters.minExperienceYr,
          maxExp: filters.maxExperienceYr,
          location: selectedLocationResults,
          currencyType: filters.currencyType,
          minSalary: filters.minSalary,
          maxSalary: filters.maxSalary,
          companyName: filters.company,
          education: selectedEducationResults,
          gender: filters.gender,
          activeIn: filters.activeIn,
          newReg: filters.candidateType
        }
        navigate("/client-login", { state: { filterData } });
      }
    } catch (error) {
      console.error(error);
      if (error.response.data.error === "Customized CV Views package expired!" || error.response.data.error === "No CV views remaining in the active package!" || error.response.data.error === "No active package found!") {
        Swal.fire({
          title: 'Buy Package Plan',
          text: error.response.data.error,
          icon: 'info',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK',
        }).then(() => {
          navigate(`/packages?from=${location.pathname}`);
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: '',
          icon: 'info',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  const handleEducationKeyDown = (e) => {
    if (filteredEducation.length === 0) return;

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        setFocusedEducationIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        break;
      case 'ArrowDown':
        e.preventDefault();
        setFocusedEducationIndex((prevIndex) => (prevIndex < filteredEducation.length - 1 ? prevIndex + 1 : prevIndex));
        break;
      case 'Enter':
        if (focusedEducationIndex !== -1) {
          handleFilteredEducationClick(filteredEducation[focusedEducationIndex].education);
        }
        break;
      default:
        break;
    }
  };

  const handleLocationKeyDown = (e) => {
    if (filteredLocation.length === 0) return;

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        setFocusedLocationIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        break;
      case 'ArrowDown':
        e.preventDefault();
        setFocusedLocationIndex((prevIndex) => (prevIndex < filteredLocation.length - 1 ? prevIndex + 1 : prevIndex));
        break;
      case 'Enter':
        if (focusedLocationIndex !== -1) {
          handleFilteredLocationClick(filteredLocation[focusedLocationIndex].location);
        }
        break;
      default:
        break;
    }
  };

  const handleKeywordKeyDown = (e) => {
    if (filteredList.length === 0) return;

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        setFocusedKeywordIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        break;
      case 'ArrowDown':
        e.preventDefault();
        setFocusedKeywordIndex((prevIndex) => (prevIndex < filteredList.length - 1 ? prevIndex + 1 : prevIndex));
        break;
      case 'Enter':
        if (focusedKeywordIndex !== -1) {
          handleFilteredClick(filteredList[focusedKeywordIndex]);
        }
        break;
      default:
        break;
    }
  };

  const handleChangePage = (event) => {
    setPageValue(parseInt(event.target.value));
  };

  const handlePageSubmit = (event) => {
    event.preventDefault();
    const totalPages = apiResult?.totalPages || 1;
    const newPage = Math.min(Math.max(1, pageValue), totalPages);
    setPage(newPage);

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const renderPageNumbers = () => {
    const totalPages = apiResult?.totalPages;
    const currentPage = apiResult?.currentPage;
    const maxPagesToShow = 5;

    if (!totalPages) return null;

    const pages = [];
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i} className={`page-item custom-pagination-page-item ${currentPage === i ? 'active' : ''}`}>
          <a className="page-link custom-pagination-page-link"
            onClick={() => {
              setPage(i);
              setPageValue(i);
            }}
            href="#">{i}</a>
        </li>
      );
    }

    return pages;
  };

  function formatSalary(salary) {
    if (salary >= 1000000) {
      return (salary / 1000000).toFixed(1) + 'M';
    } else if (salary >= 1000) {
      return (salary / 1000).toFixed(0) + 'K';
    } else {
      return salary;
    }
  }

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        userProject: 'skillity',
        page: `talent-profile-search`
      }
    });
  }, []);

  return (
    <div>
      <Layout searchCV={true} />
      <div className="cli--tal-pro-search-section">
        <div className="container-fluid">
          <div className="container-fluid container-section">
            <div className="custom--container tal--pro-search">
              <div className="breadcrumb--area-dark" data-aos="fade-down">
                <div className="breadcrumb--item-dark">
                  <a href="/client-home">Home</a>
                </div>
                <div className="breadcrumb--item-dark">
                  <p>Search Talent</p>
                </div>
              </div>

              {/* Search page section start */}
              {/* {!searchResult ? ( */}
              <div className="talent--profile-search-page-section">
                {!searchResult ? (
                  <div className="cli-tal-pro-search-container">
                    <div className="row">
                      <div className="col-12 col-lg-4 my-auto">
                        <h4 className="cli-tal-pro-search-heading">
                          Search Talent
                        </h4>
                      </div>
                      <div className="col-12 col-lg-8 mx-auto">
                        <div className="cli-tal-pro-search-filter-content">
                          <div className="cli-tal-pro-search-filter-title-area">
                            <h6 className='lg-font'>Keywords</h6>
                          </div>

                          <div className="row">
                            <div className="col-12 col-sm-9 col-xxl-10">
                              <div className="cli--tal-pro-filter-input-area">

                                <div className="container_input_section with-more-radius">
                                  <div className="container_search_icon_area">
                                    <i className="bi bi-search"></i>
                                  </div>
                                  <div className="container-input-area3">

                                    {selectedResults.length > 0 && (
                                      <>
                                        {selectedResults.map(selectResult => (
                                          <span className="form__badge2"
                                            key={selectResult}
                                          ><span>{selectResult}</span>
                                            <i className='bi bi-x' onClick={() => handleDeselect(selectResult)}></i>
                                          </span>
                                        ))}
                                      </>
                                    )}

                                    <div className='position-relative container__input_section'>
                                      <div className="container__input_with_label">
                                        <input type="search" className='container__input2'
                                          name='searcgKeywordInput'
                                          id='searcgKeywordInput'
                                          value={filters.searchInput}
                                          onChange={handleSearch}
                                          onKeyDown={handleKeywordKeyDown} />
                                        <label htmlFor="searcgKeywordInput" className={`container__input_label2 small-text ${filters.searchInput ? 'd-none' : ''}`}>Enter keywords like skills, designation</label>
                                      </div>

                                      {resultLoading &&
                                        <div className='tal-pro-search-result-data-area'>
                                          <div className='text-secondary text-center pl-2 pr-2 pt-3 pb-3 results-load-area'>Results loading. Please wait...</div>
                                        </div>
                                      }

                                      {filters.searchInput &&
                                        <div className='tal-pro-search-result-data-area' ref={resultKeywordAreaRef}>
                                          {Array.isArray(filteredList) &&
                                            filteredList.length > 0 ?
                                            filteredList.map((filterResult, index) => (
                                              <div
                                                className={`tal-pro-search-result-data ${index === focusedKeywordIndex ? 'focused' : ''}`}
                                                key={index}
                                                onClick={() => handleFilteredClick(filterResult)}
                                              >
                                                {filterResult}
                                              </div>
                                            )) : null
                                          }
                                        </div>
                                      }

                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                            <div className="col-12 col-sm-3 col-xxl-2 mt-3 mt-sm-0">
                              <button className='btn search_job-btn text-no-wrap' id="searchButton" onClick={handleSkillSearch}>Search Talent</button>
                            </div>
                          </div>

                          <div className="cli--mark-keyword-area">
                            <label className="cli--mark-keyword-check-input">
                              <input
                                type="checkbox"
                                checked={filters.keywordMandatoryCheck}
                                onChange={() => setFilters({ ...filters, keywordMandatoryCheck: !filters.keywordMandatoryCheck })}
                              />
                              <span className="cli--mark-keyword-checkmark"></span>
                              Mark all keywords as mandatory
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* <p className="tal-head-desc">
                        Welcome to the Talent Search page at Skillety! Dive into
                        a realm where exceptional talent meets opportunity. Our
                        algorithms are tuned in a different way that it gives
                        you accuracy by match percentage and Notice Period
                        duration. Explore, discover, and connect with the talent
                        that transforms visions into realities. Your journey to
                        extraordinary possibilities begins here!{" "}
                      </p> */}

                    </div>
                  </div>
                ) : (
                  <>
                    <div className="cli-tal-pro-search-container">
                      <div className="row">
                        <div className="col-12 col-lg-4 my-auto">
                          <h4 className="cli-tal-pro-search-heading">
                            Search Result Page
                          </h4>
                        </div>
                        <div className="col-12 col-lg-8 mx-auto">
                          <div className="cli-tal-pro-search-filter-content">
                            <div className="cli-tal-pro-search-filter-title-area">
                              <h6 className='lg-font'>Keywords</h6>
                            </div>

                            <div className="row">
                              <div className="col-12 col-sm-9 col-xxl-10">
                                <div className="cli--tal-pro-filter-input-area">

                                  <div className="container_input_section with-more-radius">
                                    <div className="container_search_icon_area">
                                      <i className="bi bi-search"></i>
                                    </div>
                                    <div className="container-input-area3">

                                      {selectedResults.length > 0 && (
                                        <>
                                          {selectedResults.map(selectResult => (
                                            <span className="form__badge2"
                                              key={selectResult}
                                            ><span>{selectResult}</span>
                                              <i className='bi bi-x' onClick={() => handleDeselect(selectResult)}></i>
                                            </span>
                                          ))}
                                        </>
                                      )}

                                      <div className='position-relative container__input_section'>
                                        <div className="container__input_with_label">
                                          <input type="search" className='container__input2'
                                            name='searcgKeywordInput'
                                            id='searcgKeywordInput'
                                            value={filters.searchInput}
                                            onChange={handleSearch}
                                            onKeyDown={handleKeywordKeyDown} />
                                          <label htmlFor="searcgKeywordInput" className={`container__input_label2 small-text ${filters.searchInput ? 'd-none' : ''}`}>Enter keywords like skills, designation</label>
                                        </div>

                                        {resultLoading &&
                                          <div className='tal-pro-search-result-data-area'>
                                            <div className='text-secondary text-center pl-2 pr-2 pt-3 pb-3 results-load-area'>Results loading. Please wait...</div>
                                          </div>
                                        }

                                        {filters.searchInput &&
                                          <div className='tal-pro-search-result-data-area' ref={resultKeywordAreaRef}>
                                            {Array.isArray(filteredList) &&
                                              filteredList.length > 0 ?
                                              filteredList.map((filterResult, index) => (
                                                <div
                                                  className={`tal-pro-search-result-data ${index === focusedKeywordIndex ? 'focused' : ''}`}
                                                  key={index}
                                                  onClick={() => handleFilteredClick(filterResult)}
                                                >
                                                  {filterResult}
                                                </div>
                                              )) : null
                                            }
                                          </div>
                                        }

                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <div className="col-12 col-sm-3 col-xxl-2 mt-3 mt-sm-0">
                                <button className='btn search_job-btn text-no-wrap' id="searchButton" onClick={handleSkillSearch}>Search Talent</button>
                              </div>
                            </div>

                            <div className="cli--mark-keyword-area">
                              <label className="cli--mark-keyword-check-input">
                                <input
                                  type="checkbox"
                                  checked={filters.keywordMandatoryCheck}
                                  onChange={() => setFilters({ ...filters, keywordMandatoryCheck: !filters.keywordMandatoryCheck })}
                                />
                                <span className="cli--mark-keyword-checkmark"></span>
                                Mark all keywords as mandatory
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      className='reg--form-btn-sub client back mb-5'
                      data-aos="fade-left"
                      onClick={() => setSearchResult(false)}
                    >
                      <div class="reg--form-arrow-area client back">
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                          <path d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832" stroke="#FFF" stroke-width="2" />
                          <path d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162" stroke="#FFF" stroke-width="2" />
                          <path d="M1 26L25.1667 1" stroke="#FFF" stroke-width="2" />
                        </svg>
                      </div>
                      <div className='reg--form-btn client back'>Back to Search</div>
                    </button>
                  </>
                )}
                <div className="row row-border-custom">
                  <div className={`col-12 ${!searchResult ? 'col-lg-8 col-xl-8 col-md-8' : 'col-lg-4 col-xl-3 col-md-4 custom_padding_right'} custom-right-border-col mt-4 mt-md-4`}>
                    <div className={`cli-tal-pro-search-filter-area ${searchResult ? "sticky--sidebar without-padding" : ""}`}>
                      <div className="cli-tal-pro-search-filter-head-area">
                        <h6 className="cli-tal-pro-search-filter mb-0">
                          Filters
                        </h6>
                        <img
                          src="assets/img/talent-profile/filter.png"
                          className="cli-tal-pro-filter-img"
                          alt=""
                        />
                      </div>
                      <div className={`cli-tal-pro-search-filter-container ${searchResult ? "with-custom-scroll overflow--x-hidden" : ""}`}
                        style={searchResult ? { maxHeight: `${maxHeight}px`, overflowY: 'auto', paddingRight: '10px' } : {}}>
                        <div className="cli-tal-pro-search-filter-content-section">
                          {/* <div className="cli-tal-pro-search-filter-content">
                            <div class="cli-tal-pro-search-filter-title-area">
                              <div class="info-icon-area">
                                <h6 class="cli-tal-pro-search-filter-title">
                                  Notice period / Availability to join&nbsp;
                                </h6>
                              </div>
                            </div>
                            <div className="tal--search-options-area">

                              <div className="education-type-option">
                                <input
                                  type="checkbox"
                                  className="education-type-input"
                                  id="notice_period_1"
                                  name="notice_period"
                                  checked={checkBoxfilters.includes("Any")}
                                  onChange={() => handleCheckboxChange("Any")}
                                />
                                <label
                                  for="notice_period_1"
                                  className="education-type-label"
                                >
                                  Any
                                </label>
                              </div>

                              <div className="education-type-option">
                                <input
                                  type="checkbox"
                                  className="education-type-input"
                                  id="notice_period_2"
                                  checked={checkBoxfilters.includes(
                                    "0 to 7 days"
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange("0 to 7 days")
                                  }
                                />
                                <label
                                  for="notice_period_2"
                                  className="education-type-label"
                                >
                                  0-07 days
                                </label>
                              </div>

                              <div className="education-type-option">
                                <input
                                  type="checkbox"
                                  className="education-type-input"
                                  id="notice_period_3"
                                  checked={checkBoxfilters.includes(
                                    "8 to 15 days"
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange("8 to 15 days")
                                  }
                                />
                                <label
                                  for="notice_period_3"
                                  className="education-type-label"
                                >
                                  08 to 15 days
                                </label>
                              </div>

                              <div className="education-type-option">
                                <input
                                  type="checkbox"
                                  className="education-type-input"
                                  id="notice_period_4"
                                  checked={checkBoxfilters.includes(
                                    "16 to 30 days"
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange("16 to 30 days")
                                  }
                                />
                                <label
                                  for="notice_period_4"
                                  className="education-type-label"
                                >
                                  16 to 30 days
                                </label>
                              </div>

                              <div className="education-type-option">
                                <input
                                  type="checkbox"
                                  className="education-type-input"
                                  id="notice_period_5"
                                  checked={checkBoxfilters.includes(
                                    "More than 30 days"
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange("More than 30 days")
                                  }
                                />
                                <label
                                  for="notice_period_5"
                                  className="education-type-label"
                                >
                                  Beyond 30 days
                                </label>
                              </div>

                              <div className="education-type-option">
                                <input
                                  type="checkbox"
                                  className="education-type-input"
                                  id="notice_period_6"
                                  checked={checkBoxfilters.includes(
                                    "Currently serving notice period"
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(
                                      "Currently serving notice period"
                                    )
                                  }
                                />
                                <label
                                  for="notice_period_6"
                                  className="education-type-label"
                                >
                                  Currently serving notice Period
                                </label>
                              </div>
                            </div>
                          </div> */}

                          {/* <div className="cli-tal-pro-search-filter-content"> */}
                          {/* <div className="cli-tal-pro-search-filter-title-area"> */}
                          {/* <h6 className="cli-tal-pro-search-filter-title">
                                Keywords
                              </h6> */}
                          {/* <div class="cl-toggle-switch">
                                                                <label class="cl-switch">
                                                                    <input type="checkbox" className="toggleSwitch" />
                                                                    <span></span>
                                                                </label>
                                                                <h6 className='cl-toggle--switch-label'>Boolean Off</h6>
                                                            </div> */}
                          {/* </div> */}
                          {/* {selectedResults.length > 0 && (
                                <div className="cli--tal-pro-badge-area mb-4">
                                  {selectedResults.map((selectResult) => (
                                    <span
                                      className="tal-cand-reg-form-badge"
                                      key={selectResult}
                                      onClick={() =>
                                        handleDeselect(selectResult)
                                      }
                                    >
                                      {selectResult}
                                    </span>
                                  ))}
                                </div>
                              )} */}
                          {/* <div className="cli--tal-pro-filter-input-area">
                              <div className="container_input_section">
                                <div className="container_search_icon_area">
                                  <i className="bi bi-search"></i>
                                </div>
                                <div className="container-input-area3">

                                  {selectedResults.length > 0 && (
                                    <>
                                      {selectedResults.map((selectResult) => (
                                        <span className="form__badge2"
                                          key={selectResult}
                                        ><span>{selectResult}</span>
                                          <i className='bi bi-x' onClick={() => handleDeselect(selectResult)}></i>
                                        </span>
                                      ))}
                                    </>
                                  )}

                                  <div className='position-relative container__input_section'>
                                    <div className="container__input_with_label">
                                      <input type="search" className='container__input2'
                                        name='searchKeywordInput'
                                        id='searchKeywordInput'
                                        value={filters.searchInput}
                                        onChange={handleSearch}
                                        onKeyDown={handleKeywordKeyDown} />
                                      <label htmlFor="searchKeywordInput" className={`container__input_label2 small-text ${filters.searchInput ? 'd-none' : ''}`}>Enter keywords like skills, designation</label>
                                    </div>

                                    {resultLoading &&
                                      <div className='tal-pro-search-result-data-area'>
                                        <div className='text-secondary text-center pl-2 pr-2 pt-3 pb-3 results-load-area'>Results loading. Please wait...</div>
                                      </div>
                                    }

                                    {filters.searchInput &&
                                      <div className="tal-pro-search-result-data-area" ref={resultKeywordAreaRef}>
                                        {Array.isArray(filteredList) &&
                                          filteredList.length > 0 ?
                                          filteredList.map((filterResult, index) => (
                                            <div
                                              className={`tal-pro-search-result-data ${index === focusedKeywordIndex ? 'focused' : ''}`}
                                              key={index}
                                              onClick={() => handleFilteredClick(filterResult)}
                                            >
                                              {filterResult}
                                            </div>
                                          )) : null
                                        }
                                      </div>
                                    }

                                  </div>
                                </div>
                              </div>
                            </div> */}
                          {/* <div className={`cli--mark-keyword-area ${searchResult ? "custom-dire-column" : ""}`}>
                              <label className="cli--mark-keyword-check-input">
                                <input type="checkbox" />
                                <span className="cli--mark-keyword-checkmark"></span>
                                Mark all keywords as mandatory
                              </label>
                            </div> */}
                          {/* </div> */}
                          <div className="cli-tal-pro-search-filter-content">
                            <div className="cli-tal-pro-search-filter-title-area">
                              <h6 className="cli-tal-pro-search-filter-title">
                                Experience (Years)
                              </h6>
                            </div>
                            <div className={`cli-tal-pro-exp-input-area search-page ${searchResult ? "w-100 search-result-page" : ""}`}>
                              <div className={`cli-tal-pro-exp-input-container ${searchResult ? "w-100" : "w-25"}`}>
                                <select
                                  name=""
                                  className="cli-tal-pro-exp-input text-center select w-100"
                                  id=""
                                  value={filters.minExperienceYr}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      minExperienceYr: e.target.value,
                                    })
                                  }
                                >
                                  <option value="" selected>
                                    Min
                                  </option>
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                  <option value="13">13</option>
                                  <option value="14">14</option>
                                  <option value="15">15</option>
                                  <option value="16">16</option>
                                  <option value="17">17</option>
                                  <option value="18">18</option>
                                  <option value="19">19</option>
                                  <option value="20">20</option>
                                  <option value="21">21</option>
                                  <option value="22">22</option>
                                  <option value="23">23</option>
                                  <option value="24">24</option>
                                  <option value="25">25</option>
                                  <option value="26">26</option>
                                  <option value="27">27</option>
                                  <option value="28">28</option>
                                  <option value="29">29</option>
                                  <option value="30">30</option>
                                  <option value="31">31</option>
                                  <option value="32">32</option>
                                  <option value="33">33</option>
                                  <option value="34">34</option>
                                  <option value="35">35</option>
                                  <option value="36">36</option>
                                  <option value="37">37</option>
                                  <option value="38">38</option>
                                  <option value="39">39</option>
                                  <option value="40">40</option>
                                  <option value="41">41</option>
                                  <option value="42">42</option>
                                  <option value="43">43</option>
                                  <option value="44">44</option>
                                  <option value="45">45</option>
                                  <option value="46">46</option>
                                  <option value="47">47</option>
                                  <option value="48">48</option>
                                  <option value="49">49</option>
                                  <option value="50">50</option>
                                </select>
                              </div>

                              <div className={`cli-tal-pro-exp-input-container ${searchResult ? "w-100" : "w-25"}`}>
                                <select
                                  name=""
                                  className="cli-tal-pro-exp-input text-center select w-100"
                                  id=""
                                  value={filters.maxExperienceYr}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      maxExperienceYr: e.target.value,
                                    })
                                  }
                                >
                                  <option value="" selected>
                                    Max
                                  </option>
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                  <option value="13">13</option>
                                  <option value="14">14</option>
                                  <option value="15">15</option>
                                  <option value="16">16</option>
                                  <option value="17">17</option>
                                  <option value="18">18</option>
                                  <option value="19">19</option>
                                  <option value="20">20</option>
                                  <option value="21">21</option>
                                  <option value="22">22</option>
                                  <option value="23">23</option>
                                  <option value="24">24</option>
                                  <option value="25">25</option>
                                  <option value="26">26</option>
                                  <option value="27">27</option>
                                  <option value="28">28</option>
                                  <option value="29">29</option>
                                  <option value="30">30</option>
                                  <option value="31">31</option>
                                  <option value="32">32</option>
                                  <option value="33">33</option>
                                  <option value="34">34</option>
                                  <option value="35">35</option>
                                  <option value="36">36</option>
                                  <option value="37">37</option>
                                  <option value="38">38</option>
                                  <option value="39">39</option>
                                  <option value="40">40</option>
                                  <option value="41">41</option>
                                  <option value="42">42</option>
                                  <option value="43">43</option>
                                  <option value="44">44</option>
                                  <option value="45">45</option>
                                  <option value="46">46</option>
                                  <option value="47">47</option>
                                  <option value="48">48</option>
                                  <option value="49">49</option>
                                  <option value="50">50</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="cli-tal-pro-search-filter-content">
                            <div className="cli-tal-pro-search-filter-title-area">
                              <h6 className="cli-tal-pro-search-filter-title">
                                Location/s
                              </h6>
                            </div>

                            <div className={`cli-tal-pro-search-filter-input-area location ${searchResult ? "w-100" : ""}`}>
                              <div className="container_input_section">
                                {/* <div className="container_search_icon_area">
                                    <i className="bi bi-search"></i>
                                  </div> */}
                                <div className="container-input-area3 no-icon">

                                  {selectedLocationResults.length > 0 && (
                                    <>
                                      {selectedLocationResults.map((selectResult) => (
                                        <span className="form__badge2"
                                          key={selectResult}
                                        ><span>{selectResult}</span>
                                          <i className='bi bi-x' onClick={() =>
                                            handleDeselectLocation(selectResult)
                                          }></i>
                                        </span>
                                      ))}
                                    </>
                                  )}

                                  <div className='position-relative container__input_section'>
                                    <div className="container__input_with_label">
                                      <input type="search" className='container__input2'
                                        name='searcgLocationInput'
                                        id='searcgLocationInput'
                                        value={filters.location}
                                        onChange={handleLocationSearch}
                                        onKeyDown={handleLocationKeyDown} />
                                      <label htmlFor="searcgLocationInput" className={`container__input_label2 small-text ${filters.location ? 'd-none' : ''}`}>Search location/s</label>
                                    </div>

                                    <div className="tal-pro-search-result-data-area" ref={resultLocationAreaRef}>
                                      {filteredLocation.length > 0 &&
                                        filteredLocation.map((filterResult, index) => (
                                          <div
                                            className={`tal-pro-search-result-data ${index === focusedLocationIndex ? 'focused' : ''}`}
                                            key={filterResult._id}
                                            onClick={() =>
                                              handleFilteredLocationClick(
                                                filterResult.location
                                              )
                                            }
                                          >
                                            {filterResult.location}
                                          </div>
                                        ))}
                                    </div>

                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>

                          <div className="cli-tal-pro-search-filter-content">
                            <div className="cli-tal-pro-search-filter-title-area">
                              <h6 className="cli-tal-pro-search-filter-title">
                                Current annual Salary
                              </h6>
                            </div>
                            <div className={`cli-tal-pro-exp-input-area search-page ${searchResult ? "w-100 search-result-page salary" : ""}`}>
                              <div className={`cli--salary-inputs-area ${searchResult ? "search-result-page" : ""}`}>
                                <select
                                  name=""
                                  className={`cli-tal-pro-select-input ${searchResult ? "width-30" : "width-30"}`}
                                  id=""
                                  value={filters.currencyType}
                                  onChange={(e) =>
                                    setFilters({
                                      ...filters,
                                      currencyType: e.target.value,
                                    })
                                  }
                                >
                                  <option value="" disabled>
                                    Select
                                  </option>
                                  <option value="₹" selected>
                                    ₹
                                  </option>
                                  <option value="$">$</option>
                                  <option value="€">€</option>
                                </select>
                                <input
                                  type="number"
                                  className={`cli-tal-pro-exp-input width-70`}
                                  placeholder="Min salary"
                                  value={filters.minSalary}
                                  onChange={(e) => {
                                    const value = e.target.value.slice(0, 10);
                                    setFilters({
                                      ...filters,
                                      minSalary: value
                                    });
                                  }}
                                  onKeyPress={(e) => {
                                    if (e.key === 'e' || e.key === 'E') {
                                      e.preventDefault();
                                    }
                                  }}
                                  maxLength={10}
                                />
                              </div>
                              <input
                                type="number"
                                className={`cli-tal-pro-exp-input text-center ${searchResult ? "width-100" : "width-45"} search-page`}
                                placeholder="Max salary"
                                value={filters.maxSalary}
                                onChange={(e) => {
                                  const value = e.target.value.slice(0, 10);
                                  setFilters({
                                    ...filters,
                                    maxSalary: value
                                  });
                                }}
                                onKeyPress={(e) => {
                                  if (e.key === 'e' || e.key === 'E') {
                                    e.preventDefault();
                                  }
                                }}
                                maxLength={10}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="cli-tal-pro-search-filter-content-section">
                          <div className="cli-tal-pro-search-filter-toggle-area">
                            <h6 className="cli--emploment-detail-head">
                              Employment details
                            </h6>
                            {/* <i class="bi bi-chevron-down"></i> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className=""
                              width="15"
                              height="9"
                              viewBox="0 0 15 9"
                              fill="none"
                            >
                              <path
                                d="M1 1L6.79289 6.79289C7.18342 7.18342 7.81658 7.18342 8.20711 6.79289L14 1"
                                stroke="#714F36"
                                stroke-width="2"
                                stroke-linecap="round"
                              />
                            </svg>
                          </div>
                          <div className="cli-tal-pro-search-filter-expand-area">
                            <div className="expand-area-padding">
                              <div className={`cli-tal-search-filter-form-group mb-0 ${searchResult ? "w-100" : ""}`}>
                                <div className="cli-tal-search-filter-form-label-area">
                                  <label
                                    htmlFor="company"
                                    className="cli-tal-search-filter-form-label"
                                  >
                                    Current/Last Company
                                  </label>
                                </div>
                                <div className="cli-tal-pro-search-filter-input-area">
                                  <input
                                    type="text"
                                    name="company"
                                    className="cli-tal-pro-search-filter-input"
                                    placeholder="Add company name"
                                    value={filters.company}
                                    onChange={(e) =>
                                      setFilters({
                                        ...filters,
                                        company: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="cli-tal-pro-search-filter-content-section">
                          <div className="cli-tal-pro-search-filter-toggle-area">
                            <h6 className="cli--emploment-detail-head">
                              Educational details
                            </h6>
                            {/* <i class="bi bi-chevron-down"></i> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className=""
                              width="15"
                              height="9"
                              viewBox="0 0 15 9"
                              fill="none"
                            >
                              <path
                                d="M1 1L6.79289 6.79289C7.18342 7.18342 7.81658 7.18342 8.20711 6.79289L14 1"
                                stroke="#714F36"
                                stroke-width="2"
                                stroke-linecap="round"
                              />
                            </svg>
                          </div>
                          <div className="cli-tal-pro-search-filter-expand-area">
                            <div className="expand-area-padding">
                              <div className={`cli-tal-search-filter-form-group mb-4 ${searchResult ? "w-100" : ""}`}>
                                <div className="cli-tal-search-filter-form-label-area">
                                  <label
                                    htmlFor="department"
                                    className="cli-tal-search-filter-form-label"
                                  >
                                    Educational Criteria
                                  </label>
                                </div>

                                <div className="cli-tal-pro-search-filter-input-area">

                                  <div className="container_input_section">
                                    <div className="container-input-area3 no-icon">

                                      {selectedEducationResults.length > 0 && (
                                        <>
                                          {selectedEducationResults.map(
                                            (selectResult) => (
                                              <span className="form__badge2" key={selectResult}><span>{selectResult}</span>
                                                <i className='bi bi-x' onClick={() => handleDeselectEducation(selectResult)}></i>
                                              </span>
                                            ))}
                                        </>
                                      )}

                                      <div className='position-relative container__input_section'>
                                        <div className="container__input_with_label">
                                          <input type="search" className='container__input2'
                                            name='department'
                                            id='department'
                                            value={filters.education}
                                            onChange={handleEducationSearch}
                                            onKeyDown={handleEducationKeyDown} />
                                          <label htmlFor="department" className={`container__input_label2 small-text ${filters.education ? 'd-none' : ''}`}>Search educational criteria</label>
                                        </div>

                                        <div className="tal-pro-search-result-data-area" ref={resultEducationAreaRef}>
                                          {filteredEducation.length > 0 &&
                                            filteredEducation.map(
                                              (filterResult, index) => (
                                                <div
                                                  className={`tal-pro-search-result-data ${index === focusedEducationIndex ? 'focused' : ''}`}
                                                  key={filterResult._id}
                                                  onClick={() =>
                                                    handleFilteredEducationClick(
                                                      filterResult.education
                                                    )
                                                  }
                                                >
                                                  {filterResult.education}
                                                </div>
                                              )
                                            )}
                                        </div>

                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>

                              {/* <div className={`cli-tal-search-filter-form-group mb-4 ${searchResult ? "w-100" : ""}`}>
                                <div className="cli-tal-search-filter-form-label-area">
                                  <label
                                    htmlFor="department"
                                    className="cli-tal-search-filter-form-label"
                                  >
                                    Graduation details
                                  </label>
                                </div>

                                <div className="cli-tal-pro-search-filter-input-area">

                                  <div className="container_input_section">
                                    <div className="container-input-area3 no-icon">

                                      {selectedEducationResults.length > 0 && (
                                        <>
                                          {selectedEducationResults.map(
                                            (selectResult) => (
                                              <span className="form__badge2" key={selectResult}><span>{selectResult}</span>
                                                <i className='bi bi-x' onClick={() => handleDeselectEducation(selectResult)}></i>
                                              </span>
                                            ))}
                                        </>
                                      )}

                                      <div className='position-relative container__input_section'>
                                        <div className="container__input_with_label">
                                          <input type="search" className='container__input2'
                                            name='department'
                                            id='department'
                                            value={filters.education}
                                            onChange={handleEducationSearch}
                                            onKeyDown={handleEducationKeyDown} />
                                          <label htmlFor="department" className={`container__input_label2 small-text ${filters.education ? 'd-none' : ''}`}>Enter graduation details</label>
                                        </div>

                                        <div className="tal-pro-search-result-data-area" ref={resultEducationAreaRef}>
                                          {filteredEducation.length > 0 &&
                                            filteredEducation.map(
                                              (filterResult, index) => (
                                                <div
                                                  className={`tal-pro-search-result-data ${index === focusedEducationIndex ? 'focused' : ''}`}
                                                  key={filterResult._id}
                                                  onClick={() =>
                                                    handleFilteredEducationClick(
                                                      filterResult.education
                                                    )
                                                  }
                                                >
                                                  {filterResult.education}
                                                </div>
                                              )
                                            )}
                                        </div>

                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>

                              <div className={`cli-tal-search-filter-form-group mb-0 ${searchResult ? "w-100" : ""}`}>
                                <div className="cli-tal-search-filter-form-label-area">
                                  <label
                                    htmlFor="department"
                                    className="cli-tal-search-filter-form-label"
                                  >
                                    Postgraduation details
                                  </label>
                                </div>

                                <div className="cli-tal-pro-search-filter-input-area">

                                  <div className="container_input_section">
                                    <div className="container-input-area3 no-icon">

                                      {selectedEducationResults.length > 0 && (
                                        <>
                                          {selectedEducationResults.map(
                                            (selectResult) => (
                                              <span className="form__badge2" key={selectResult}><span>{selectResult}</span>
                                                <i className='bi bi-x' onClick={() => handleDeselectEducation(selectResult)}></i>
                                              </span>
                                            ))}
                                        </>
                                      )}

                                      <div className='position-relative container__input_section'>
                                        <div className="container__input_with_label">
                                          <input type="search" className='container__input2'
                                            name='department'
                                            id='department'
                                            value={filters.education}
                                            onChange={handleEducationSearch}
                                            onKeyDown={handleEducationKeyDown} />
                                          <label htmlFor="department" className={`container__input_label2 small-text ${filters.education ? 'd-none' : ''}`}>Enter postgraduation details</label>
                                        </div>

                                        <div className="tal-pro-search-result-data-area" ref={resultEducationAreaRef}>
                                          {filteredEducation.length > 0 &&
                                            filteredEducation.map(
                                              (filterResult, index) => (
                                                <div
                                                  className={`tal-pro-search-result-data ${index === focusedEducationIndex ? 'focused' : ''}`}
                                                  key={filterResult._id}
                                                  onClick={() =>
                                                    handleFilteredEducationClick(
                                                      filterResult.education
                                                    )
                                                  }
                                                >
                                                  {filterResult.education}
                                                </div>
                                              )
                                            )}
                                        </div>

                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div> */}

                            </div>
                          </div>
                        </div>


                        {/* Display Details */}
                        <div className="cli-tal-pro-search-filter-content-section">
                          <div className="cli-tal-pro-search-filter-toggle-area">
                            <h6 className="cli--emploment-detail-head">
                              Display Details
                            </h6>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className=""
                              width="15"
                              height="9"
                              viewBox="0 0 15 9"
                              fill="none"
                            >
                              <path
                                d="M1 1L6.79289 6.79289C7.18342 7.18342 7.81658 7.18342 8.20711 6.79289L14 1"
                                stroke="#714F36"
                                stroke-width="2"
                                stroke-linecap="round"
                              />
                            </svg>
                          </div>
                          <div className="cli-tal-pro-search-filter-expand-area">
                            <div className="expand-area-padding">
                              <div className="cli-tal-pro-search-filter-content mb-0">
                                <div className="cli-tal-pro-search-filter-title-area">
                                  <h6 className="cli-tal-pro-search-filter-title">
                                    Show
                                  </h6>
                                </div>

                                <div className="tal--search-options-area">
                                  <div className="tal--search-option-container">
                                    <input
                                      id="all_candidate"
                                      className="tal--search-radio"
                                      type="radio"
                                      name="show"
                                      value="allCandidates"
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          candidateType: e.target.value,
                                        })
                                      }
                                      checked={filters.candidateType === "allCandidates"}
                                    />
                                    <div className="tal--search-tile">
                                      <label
                                        for="all_candidate"
                                        className="tal--search-tile-label"
                                      >
                                        All candidates
                                      </label>
                                    </div>
                                  </div>

                                  <div className="tal--search-option-container">

                                    <input
                                      id="new_reg"
                                      className="tal--search-radio"
                                      type="radio"
                                      title="Applicants who joined within 30 days"
                                      name="show"
                                      value="newRegistration"
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          candidateType: e.target.value,
                                        })
                                      }
                                      checked={filters.candidateType === "newRegistration"}
                                    />

                                    <div className="tal--search-tile">
                                      <label
                                        for="new_reg"
                                        className="tal--search-tile-label"
                                      >
                                        Registered in the last 30 days
                                      </label>
                                    </div>
                                  </div>

                                </div>
                              </div>

                            </div>
                          </div>
                        </div>


                        {/* Diversity and Additional Details */}
                        <div className="cli-tal-pro-search-filter-content-section">
                          <div className="cli-tal-pro-search-filter-toggle-area">
                            <h6 className="cli--emploment-detail-head">
                              Diversity and Additional Details
                            </h6>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className=""
                              width="15"
                              height="9"
                              viewBox="0 0 15 9"
                              fill="none"
                            >
                              <path
                                d="M1 1L6.79289 6.79289C7.18342 7.18342 7.81658 7.18342 8.20711 6.79289L14 1"
                                stroke="#714F36"
                                stroke-width="2"
                                stroke-linecap="round"
                              />
                            </svg>
                          </div>
                          <div className="cli-tal-pro-search-filter-expand-area">
                            <div className="expand-area-padding">
                              <div className="cli-tal-pro-search-filter-content mb-0">
                                <div className="cli-tal-pro-search-filter-title-area">
                                  <h6 className="cli-tal-pro-search-filter-title">
                                    Gender
                                  </h6>
                                </div>

                                <div className="tal--search-options-area">

                                  <div className="tal--search-option-container">
                                    <input
                                      id="male_cand"
                                      className="tal--search-radio"
                                      type="radio"
                                      name="gender"
                                      value="Male"
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          gender: e.target.value,
                                        })
                                      }
                                      checked={filters.gender === "Male"}

                                    />
                                    <div className="tal--search-tile">
                                      <label
                                        for="male_cand"
                                        className="tal--search-tile-label"
                                      >
                                        Male Candidates
                                      </label>
                                    </div>
                                  </div>

                                  <div className="tal--search-option-container">
                                    <input
                                      id="female_cand"
                                      className="tal--search-radio"
                                      type="radio"
                                      name="gender"
                                      value="Female"
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          gender: e.target.value,
                                        })
                                      }
                                      checked={filters.gender === "Female"}
                                    />
                                    <div className="tal--search-tile">
                                      <label
                                        for="female_cand"
                                        className="tal--search-tile-label"
                                      >
                                        Female Candidates
                                      </label>
                                    </div>
                                  </div>

                                  <div className="tal--search-option-container">
                                    <input
                                      id="other_cand"
                                      className="tal--search-radio"
                                      type="radio"
                                      name="gender"
                                      value="Other"
                                      onChange={(e) =>
                                        setFilters({
                                          ...filters,
                                          gender: e.target.value,
                                        })
                                      }
                                      checked={filters.gender === "Other"}
                                    />
                                    <div className="tal--search-tile">
                                      <label
                                        for="other_cand"
                                        className="tal--search-tile-label"
                                      >
                                        Other Candidates
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="cli-tal-pro-search-page-btn-area">
                          <div className="cli-tal-pro-search-page-days-selection-area">
                            <div className="days-active">Active In</div>

                            <select className="active_in_selectbox"
                              onChange={(e) => setFilters({ ...filters, activeIn: e.target.value })}
                            >
                              <option value="">Select</option>
                              <option value={7}
                                selected={filters.activeIn === 7}>7 days</option>
                              <option value={14}
                                selected={filters.activeIn === 14}>14 days</option>
                              <option value={21}
                                selected={filters.activeIn === 21}>21 days</option>
                              <option value={30}
                                selected={filters.activeIn === 30}>30 days</option>
                            </select>
                          </div>

                          {!searchResult &&
                            <button
                              className="cli-tal-pro-search-page-btn"
                              onClick={handleSkillSearch}
                            >
                              Search Talent
                            </button>
                          }

                        </div>

                        {searchResult &&
                          <div className="clear--all_button-area">
                            <button className="tal--search-submit-btn" onClick={handleSkillSearch}>
                              Submit
                            </button>
                            <button className="clear--all_button" onClick={() => { window.location.reload() }}>
                              Clear all
                            </button>
                          </div>
                        }

                      </div>
                    </div>
                  </div>

                  {!searchResult ? (
                    <div className="col-12 col-lg-4 col-xl-4 col-md-4 custom-border-top-sm mt-4 mt-md-4">
                      <div className="cli-tal-pro-recent-search-section">
                        <div className="cli-tal-pro-recent-search-head-area">
                          <i class="ri-history-line"></i>
                          <h4 className="cli-tal-pro-recent-search-head mb-0">
                            Recent searches{" "}
                          </h4>
                        </div>

                        <div className="cli-tal-pro-recent-search-container">
                          {recentSearches.map((search) => {
                            if (
                              search?.selectedResults?.length > 0 ||
                              search?.selectedLocationResults?.length > 0 ||
                              search?.selectedEducationResults?.length > 0 ||
                              search?.company
                            ) {
                              return (
                                <div
                                  className="cli-tal-pro-recent-search-area"
                                  key={search._id}
                                >
                                  <div className="cli-tal-pro-recent-search-btn-area">
                                    <button
                                      className="cli-tal-pro-recent-search-btn"
                                      onClick={() => handleFill(search._id)}
                                    >
                                      Fill this search
                                    </button>
                                    {/* <button className='cli-tal-pro-recent-search-btn'>Search profile</button> */}
                                  </div>
                                  <div className="cli-tal-pro-recent-search-tags">
                                    <span>
                                      {search?.selectedResults?.length > 0 &&
                                        search?.selectedResults?.join(", ") +
                                        " "}
                                      {search?.selectedLocationResults?.length >
                                        0 &&
                                        search?.selectedLocationResults?.join(
                                          ", "
                                        ) + " "}
                                      {search?.selectedEducationResults
                                        ?.length > 0 &&
                                        search?.selectedEducationResults?.join(
                                          ", "
                                        ) + " "}
                                      {search?.company && search?.company}....
                                    </span>
                                  </div>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12 col-lg-8 col-xl-9 col-md-8 custom-border-top-sm mt-4"
                      id="searchResultPage">
                      <div className="cli--tal-pro-search-results-area pt-lg-4">
                        <div className="page__number_area">
                          <h6> No. of total applicants :{" "}
                            <span>
                              {filteredSearchResultsMsg
                                ? "0"
                                : apiResult?.totalCands}
                            </span>
                          </h6>
                        </div>
                        {filteredSearchResultsMsg ? (
                          <div className="no-data-created-area">
                            <div className="no-data-created">
                              <img
                                src="../assets/img/no-data/no-data-img.png"
                                className="no-data-img"
                                alt=""
                              />
                              <div className="no-data-text">
                                {filteredSearchResultsMsg}
                              </div>
                            </div>
                          </div>
                        ) : filteredSearchResults.length > 0 ? (
                          filteredSearchResults
                            .map((candidate) => {
                              const viewedCandidateForThisCandidate =
                                loginClientDetail.companyId &&
                                viewedCandidate.find(
                                  (cand) => cand.candidateId === candidate.id
                                );
                              const matchingImg = candidateImg
                                ? candidateImg.find(
                                  (img) => img.id === candidate.id
                                )
                                : null;
                              const imgSrc = matchingImg
                                ? matchingImg.image
                                : "assets/img/talents-images/avatar.jpg";

                              const calculateMatchPercentage = (
                                skills1,
                                skills2
                              ) => {
                                const matchingSkills = skills2.filter((skill) =>
                                  skills1.includes(skill)
                                );
                                return (
                                  (matchingSkills.length / skills1.length) * 100
                                );
                              };
                              const candSkill = candidate.skills.map(skill => skill.toLowerCase());
                              const percentage = Math.round(
                                calculateMatchPercentage(selectedResults, [
                                  ...candSkill,
                                  ...candidate.designation[0].toLowerCase(),
                                ])
                              );
                              return (
                                <article
                                  className="talent--profile-card search"
                                  data-aos="fade-left"
                                  key={candidate.id}
                                >
                                  <div className="tal--pro-card-left-area search">
                                    <div className="card-split-line"></div>
                                    <div className="tal--pro-card-name-area">
                                      <label className="tal--pro-card-name-check-container">
                                        <input
                                          type="checkbox"
                                          class="tal--checkbox"
                                          checked={
                                            viewedCandidateForThisCandidate
                                              ? true
                                              : false
                                          }
                                          onChange={(e) => e.preventDefault()}
                                        />
                                        <div className="tal--pro-card-name-checkmark"></div>
                                      </label>
                                      <h6 className="tal--pro-card-name">
                                        {candidate.firstName +
                                          " " +
                                          candidate.lastName}
                                      </h6>
                                    </div>
                                    <div className="tal--pro-card-tags search">
                                      <h6 className="tal--pro-card-exp">
                                        Experience :{" "}
                                        {candidate.year +
                                          " years " +
                                          candidate.month +
                                          " months"}
                                      </h6>
                                      <h6 className="tal--pro-card-exp">
                                        Salary :{" "}
                                        {formatSalary(candidate.minSalary) +
                                          "-" +
                                          formatSalary(candidate.maxSalary)}
                                      </h6>
                                      <h6 className="tal--pro-card-location">
                                        <i class="bx bxs-map"></i>
                                        <span>{candidate.location}</span>
                                      </h6>
                                    </div>
                                    <div className="tal--pro-card-desc-area search">
                                      <div className="row tal--pro-card-desc-row">
                                        <div className="col-12 col-lg-3 col-md-3 custom-padd-right">
                                          <h6 className="tal--pro-card-desc-title">
                                            Previous&nbsp;:
                                          </h6>
                                        </div>
                                        <div className="col-12 col-lg-9 col-md-9 custom-padd-left">
                                          <p className="tal--pro-card-desc text-capitalized">
                                            {candidate.designation[0] +
                                              " " +
                                              "at" +
                                              " " +
                                              candidate.companyName}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row tal--pro-card-desc-row">
                                        <div className="col-12 col-lg-3 col-md-3 custom-padd-right">
                                          <h6 className="tal--pro-card-desc-title">
                                            Education&nbsp;:
                                          </h6>
                                        </div>
                                        <div className="col-12 col-lg-9 col-md-9 custom-padd-left">
                                          <p className="tal--pro-card-desc text-capitalized">
                                            {Array.isArray(candidate?.education) ? candidate.education.join(", ") : ''}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row tal--pro-card-desc-row">
                                        <div className="col-12 col-lg-3 col-md-3 custom-padd-right">
                                          <h6 className="tal--pro-card-desc-title">
                                            College&nbsp;:
                                          </h6>
                                        </div>
                                        <div className="col-12 col-lg-9 col-md-9 custom-padd-left">
                                          <p className="tal--pro-card-desc text-capitalized">
                                            {candidate.college}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row tal--pro-card-desc-row">
                                        <div className="col-12 col-lg-3 col-md-3 custom-padd-right">
                                          <h6 className="tal--pro-card-desc-title">
                                            KeySkill&nbsp;:
                                          </h6>
                                        </div>
                                        <div className="col-12 col-lg-9 col-md-9 custom-padd-left">
                                          <p className="tal--pro-card-desc text-capitalized">
                                            {Array.isArray(candidate?.skills) ? candidate.skills.join(", ") : ''}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row tal--pro-card-desc-row">
                                        <div className="col-12 col-lg-3 col-md-3 custom-padd-right">
                                          <h6 className="tal--pro-card-desc-title">
                                            Profile headline&nbsp;:
                                          </h6>
                                        </div>
                                        <div className="col-12 col-lg-9 col-md-9 custom-padd-left">
                                          <p className="tal--pro-card-desc text-capitalized">
                                            {candidate.profileHeadline}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="tal--pro-card-right-area search">
                                    <div className="tal--pro-card-right-cover-area search">
                                      <div>
                                        <div className="tal--pro-card-profile-img-role-area search">
                                          <img
                                            src={imgSrc}
                                            className="tal--pro-card-profile-img"
                                            alt=""
                                          />
                                          <p className="tal--pro-card-role-name">
                                            {candidate.designation[0]}
                                          </p>
                                        </div>
                                        <div className="tal--pro-card-contact-btn-area search">
                                          <button
                                            className="tal--pro-card-contact-btn search"
                                            onClick={() =>
                                              viewCandidateDetail(
                                                candidate.id,
                                                candidate.percentage
                                              )
                                            }
                                          >
                                            View Profile
                                          </button>
                                        </div>
                                      </div>
                                      <div className="tal--pro-card-ability-number-area search-result">
                                        {selectedResults.length > 0 && candidate.percentage && (
                                          <div className="tal--pro-card-ability-number-left search-result">
                                            <h6 className="tal--pro-card-ability search">
                                              Keywords matched
                                            </h6>
                                            {/* <h2 className="tal--pro-card-percentage search">
                                              {Math.round(percentage)}%
                                            </h2> */}
                                            <h2 className="tal--pro-card-percentage search">
                                              {candidate.percentage}%
                                            </h2>
                                          </div>
                                        )}
                                        <div className="tal--pro-card-ability-number-right search-result">
                                          <h6 className="tal--pro-card-can-join">
                                            Can join in :{" "}
                                          </h6>
                                          <h2 className="tal--pro-card-join-days text-capitalized">
                                            {candidate.days}
                                            <span></span>
                                          </h2>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </article>
                              );
                            })
                        ) :
                          null
                        }

                        {/* <div
                          className="tal--pro-paginate-btn-area"
                          data-aos="fade-up"
                        >
                          <h6 className="tal--pro-total-result-text">
                            No of applicants :{" "}
                            <span>
                              {filteredSearchResultsMsg
                                ? "0"
                                : apiResult?.totalCands}
                            </span>
                          </h6>
                          <div className="tal--pro-slider-btn-sub">
                            {(apiResult?.currentPage !== 1 && apiResult?.totalPages !== 1) && (
                              <button
                                className="tal--pro-slider-btn"
                                onClick={() => setPage(page - 1)}
                              >
                                <svg
                                  className="arrow-left"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  viewBox="0 0 27 27"
                                  fill="none"
                                >
                                  <path
                                    d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832"
                                    stroke="#5C3B2E"
                                    stroke-width="2"
                                  />
                                  <path
                                    d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162"
                                    stroke="#5C3B2E"
                                    stroke-width="2"
                                  />
                                  <path
                                    d="M1 26L25.1667 1"
                                    stroke="#5C3B2E"
                                    stroke-width="2"
                                  />
                                </svg>
                              </button>
                            )}
                            {(apiResult?.currentPage !== apiResult?.totalPages) && (
                              <button
                                className="tal--pro-slider-btn"
                                onClick={() => setPage(page + 1)}
                              >
                                <svg
                                  className="arrow-right"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  viewBox="0 0 27 27"
                                  fill="none"
                                >
                                  <path
                                    d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832"
                                    stroke="#5C3B2E"
                                    stroke-width="2"
                                  />
                                  <path
                                    d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162"
                                    stroke="#5C3B2E"
                                    stroke-width="2"
                                  />
                                  <path
                                    d="M1 26L25.1667 1"
                                    stroke="#5C3B2E"
                                    stroke-width="2"
                                  />
                                </svg>
                              </button>
                            )}
                          </div>
                        </div> */}

                        {!filteredSearchResultsMsg &&
                          <div className="table-pagination-area" data-aos="fade-up">
                            {/* <div className="pagination-btn-area" data-aos="fade-up">
                            {(apiResult?.currentPage !== 1 && apiResult?.totalPages !== 1) && (
                              <button
                                className="tal--pro-slider-btn"
                                onClick={() => setPage(page - 1)}
                              >
                                <svg
                                  className="arrow-left"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  viewBox="0 0 27 27"
                                  fill="none"
                                >
                                  <path
                                    d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832"
                                    stroke="#5C3B2E"
                                    stroke-width="2"
                                  />
                                  <path
                                    d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162"
                                    stroke="#5C3B2E"
                                    stroke-width="2"
                                  />
                                  <path
                                    d="M1 26L25.1667 1"
                                    stroke="#5C3B2E"
                                    stroke-width="2"
                                  />
                                </svg>
                              </button>
                            )}

                            {apiResult &&
                              <div className='pag-page'>
                                <span className='current-page'>{page}</span>&nbsp;/&nbsp;
                                <span className='total-page'>{apiResult?.totalPages}</span>
                              </div>
                            }

                            {(apiResult?.currentPage !== apiResult?.totalPages) && (
                              <button
                                className="tal--pro-slider-btn"
                                onClick={() => setPage(page + 1)}
                              >
                                <svg
                                  className="arrow-right"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  viewBox="0 0 27 27"
                                  fill="none"
                                >
                                  <path
                                    d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832"
                                    stroke="#5C3B2E"
                                    stroke-width="2"
                                  />
                                  <path
                                    d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162"
                                    stroke="#5C3B2E"
                                    stroke-width="2"
                                  />
                                  <path
                                    d="M1 26L25.1667 1"
                                    stroke="#5C3B2E"
                                    stroke-width="2"
                                  />
                                </svg>
                              </button>
                            )}
                          </div> */}

                            <nav aria-label="Page navigation example pt-5">
                              <ul class="pagination justify-content-start">
                                <li className={`page-item ${(apiResult?.currentPage !== 1 && apiResult?.totalPages !== 1) ? "" : "disabled"} `}>
                                  <a className="page-link custom-pagination-page-link" href="#"
                                    onClick={() => {
                                      setPage(page - 1);
                                      setPageValue(page - 1);
                                    }}
                                  >Previous</a>
                                </li>

                                {renderPageNumbers()}

                                <li className={`page-item ${(apiResult?.currentPage !== apiResult?.totalPages) ? "" : "disabled"}`}>
                                  <a className="page-link custom-pagination-page-link" href="#"
                                    onClick={() => {
                                      setPage(page + 1);
                                      setPageValue(page + 1);
                                    }}
                                  >Next</a>
                                </li>
                              </ul>
                            </nav>

                            <div className="custom-pagination-input-area">
                              <p>Page</p>
                              <form onSubmit={handlePageSubmit}>
                                <input
                                  type="number"
                                  className="form-control custom-pagination-input"
                                  value={pageValue}
                                  min={1}
                                  max={apiResult?.totalPages || 1}
                                  onChange={handleChangePage}
                                />
                                <button type="submit" className="d-none">Submit</button>
                              </form>
                              <p>of {apiResult?.totalPages}</p>
                            </div>
                          </div>
                        }

                      </div>
                    </div>
                  )}

                </div>
              </div>

              {searchLoading &&
                <div className="dot-spinner-area">
                  <div className="dot-spinner">
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                    <div className="dot-spinner__dot"></div>
                  </div>
                </div>
              }
              {/* Search results page section end */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default TalentsProfileSearch;
