import React from 'react';
import { useEffect } from 'react';
import $ from 'jquery';
import './FAQ.css';
import './FAQ-Responsive.css';
import { useState } from 'react';
import TagManager from 'react-gtm-module'
import Footer from '../../components/Footer';
import Layout from '../../components/Layout';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const FAQ = () => {
    const [activeFaq, setActiveFaq] = useState(1);
    const [isPageLoading, setPageLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [listings, setListings] = useState([]);
    const [categoryListings, setCategoryListings] = useState([]);

    const getAllFAQCategories = async () => {
        setPageLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_API}/api/faq/get-all-faqs/Client`)
            setCategories(response?.data?.categories);
            setListings(response?.data?.list);
            setActiveFaq(response?.data?.categories[0]?._id);
        } catch (error) {
            console.log(error);
        } finally {
            setPageLoading(false);
        }
    }

    useEffect(() => {
        if (activeFaq) {
            listings.map((item) => {
                if (item._id == activeFaq) {
                    setCategoryListings(item.list);
                }
            })
        }
    }, [activeFaq]);


    useEffect(() => {
        getAllFAQCategories();
    }, []);

    useEffect(() => {
        const handleToggle = (event) => {
            // Identify the parent container of the clicked element. This assumes the structure of your HTML allows this.
            const faqSection = event.currentTarget.parentNode;

            // Toggle the 'opened' class on the FAQ content area to show/hide the content.
            faqSection.querySelector('.faq-expand-area').classList.toggle('opened');

            // Optionally, rotate the arrow or toggle icon if one is present.
            const svgIcon = faqSection.querySelector('svg');
            if (svgIcon) {
                svgIcon.classList.toggle('rotate'); // Ensure your CSS handles the 'rotate' class to rotate the SVG.
            }
        };

        document.querySelectorAll('.faq-toggle-area').forEach(element => {
            element.addEventListener('click', handleToggle);
        });

        return () => {
            document.querySelectorAll('.faq-toggle-area').forEach(element => {
                element.removeEventListener('click', handleToggle);
            });
        };
    }, [categoryListings]);

    useEffect(() => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                userProject: 'skillity',
                page: `candidate-about-us`
            }
        });
    }, []);

    return (
        <>
            {isPageLoading ? (
                <div className="table-skeleton-area">
                    <div className="row">
                        <div className="col-12">
                            <div className="table-data-skeleton-area">
                                <div className="custom-flex-area">
                                    <div>
                                        <div className='pt-3'>
                                            <Skeleton height={25} width={250} />
                                        </div>
                                        <div className='pt-3'>
                                            <Skeleton height={15} width={120} />
                                        </div>
                                    </div>
                                </div>

                                <div className="table-responsive table-scroll-area mt-4 skeleton-table">
                                    <div className="table skeleton-table table-striped table-hover admin-lg-table">
                                        <tr className="skeleton-table-row">
                                            <th className='w-5'>
                                                <Skeleton height={18} width={30} />
                                            </th>
                                            <th className='w-25'>
                                                <Skeleton height={18} width={100} />
                                            </th>
                                            <th className='w-25'>
                                                <Skeleton height={18} width={100} />
                                            </th>
                                            <th className='w-25'>
                                                <Skeleton height={18} width={100} />
                                            </th>
                                            <th className='w-20'>
                                                <Skeleton height={18} width={80} />
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Skeleton height={18} width={30} />
                                            </td>
                                            <td>
                                                <Skeleton height={18} width={100} />
                                            </td>
                                            <td>
                                                <Skeleton height={18} width={100} />
                                            </td>
                                            <td>
                                                <Skeleton height={18} width={100} />
                                            </td>
                                            <td>
                                                <Skeleton height={18} width={80} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Skeleton height={18} width={30} />
                                            </td>
                                            <td>
                                                <Skeleton height={18} width={100} />
                                            </td>
                                            <td>
                                                <Skeleton height={18} width={100} />
                                            </td>
                                            <td>
                                                <Skeleton height={18} width={100} />
                                            </td>
                                            <td>
                                                <Skeleton height={18} width={80} />
                                            </td>
                                        </tr>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (

                <div>
                    <Layout aboutUs={true} />
                    <div>
                        <div className='container-fluid main--section'>
                            <div className='container-fluid container-section'>
                                <div className="faq--bg">
                                    <div className="row">
                                        <div className="col-12 faq--left-cover">
                                            <div className="faq-breadcrumb--area" data-aos="fade-down">
                                                <div className="breadcrumb--item">
                                                    <a href="/">Home</a>
                                                </div>
                                                <div className="breadcrumb--item">
                                                    <p>Faq</p>
                                                </div>
                                            </div>
                                            <div className="faq--head">
                                                <h2 data-aos="fade-left">
                                                    HI, HOW CAN I HELP YOU<br />FAQ's</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-12">
                                            <div className="faq-switches-container">
                                                {categories && categories.length > 0 &&
                                                    categories.map((item, index) => (
                                                        <input
                                                            type="radio"
                                                            id={item._id}
                                                            name="switchFaq"
                                                            value={item.name}
                                                            checked={activeFaq == item._id ? true : false}
                                                            onChange={() => setActiveFaq(item._id)}
                                                        />
                                                    ))
                                                }
                                                {categories && categories.length > 0 &&
                                                    categories.map((item, index) => (
                                                        <label htmlFor={item._id} className={activeFaq == item._id ? 'switch-active' : null}>{item.name}</label>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='faq-body'>
                                    <div className="faq-toggle-content-section">
                                        {categoryListings && categoryListings.length > 0 &&
                                            categoryListings.map((listing, index) => (
                                                <div className="faq-toggle-content-area" >
                                                    <div className="faq-toggle-area">
                                                        <h6 className='faq-toggle-head'>{listing.title}</h6>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className='' width="13" height="8" viewBox="0 0 15 9" fill="none">
                                                            <path d="M1 1L6.79289 6.79289C7.18342 7.18342 7.81658 7.18342 8.20711 6.79289L14 1" stroke="#714F36" stroke-width="2" stroke-linecap="round" />
                                                        </svg>
                                                    </div>
                                                    <div className="faq-expand-area">
                                                        <div className="faq-expand-sub-area">
                                                            <p className='faq-body-text'>
                                                                {listing.value}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            )
            }
        </>
    )
}
export default FAQ;