import React, { useContext, useState } from "react";
import { useEffect } from "react";
import LayoutNew from '../../components/LayoutNew';
import { CandidateFooter } from '../../components/CandidateFooter';
import TagManager from 'react-gtm-module'

const PrivacyPolicyTalent = () => {

    useEffect(() => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'pageview',
            userProject: 'skillity',
            page: `privacy-policy-talent`
          }
        });
      }, []);
      
    return (
        <div>
            <LayoutNew />
            <div className="cli--tal-pro-search-section">
                <div className="container-fluid">
                    <div className="container-fluid container-section">
                        <div className="custom--container tal--pro-search">
                            <div className="breadcrumb--area-dark" data-aos="fade-down">
                                <div className="breadcrumb--item-dark">
                                    <a href="/">Home</a>
                                </div>
                                <div className="breadcrumb--item-dark">
                                    <p>Privacy Policy</p>
                                </div>
                            </div>

                            <div className="terms-con-section">
                                <div className="terms-con-area">
                                    <div className="terms-con-title">
                                        <span>Privacy Policy</span>
                                    </div>

                                    {/* <div className="privacy-initial-area">
                                        <p className="privacy-content">
                                            This privacy notice discloses the privacy practices for
                                            (www.skillety.com). This privacy notice applies solely to
                                            information collected by this website. It will notify you
                                            of the following:
                                        </p>

                                        <div className="privacy-list-area">
                                            <ul>
                                                <li className="privacy-list-item">
                                                    What personally identifiable information is collected
                                                    from you through the website, how it is used and with
                                                    whom it may be shared.
                                                </li>
                                                <li className="privacy-list-item">
                                                    What choices are available to you regarding the use of
                                                    your data.
                                                </li>
                                                <li className="privacy-list-item">
                                                    The security procedures in place to protect the misuse
                                                    of your information.
                                                </li>
                                                <li className="privacy-list-item mb-0">
                                                    How you can correct any inaccuracies in the
                                                    information.
                                                </li>
                                            </ul>
                                        </div>
                                    </div> */}

                                    <div className="privacy-pol-group">
                                        <div className="privacy-pol-head">
                                            I. About Privacy Policy
                                        </div>

                                        <p className="privacy-content">
                                            Welcome to Skillety Technologies Private Limited, referred to as Skillety or the Company. This Privacy Policy elucidates our firm dedication to managing your personal information and delineates the methods and instances of Personally Identifiable Information (PII) collection, usage, sharing, and security, along with your options for accessing and correcting your personal data. This Privacy Policy applies to the data collected on our platforms for registered candidates. For unregistered candidates, it extends to publicly available data/information sourced from various websites.
                                        </p>

                                        <p className="privacy-content">
                                            At Skillety, safeguarding our users' privacy is our top priority. We are committed to ensuring a safe and secure user experience by protecting the personal information, or PII, entrusted to us. Whether shared by registered users on our platform or sourced from publicly available data across websites for unregistered users, maintaining the safety and confidentiality of your PII is paramount. We have implemented comprehensive measures to protect your privacy.
                                        </p>

                                        <p className="privacy-content">
                                            In the context of this Policy, PII encompasses any information that, either alone or when combined with other data, pertains to a specific identifiable individual. Examples of PII include but are not limited to an individual's name, mobile number, email address, and physical address.
                                        </p>

                                        <p className="privacy-content mb-0">
                                            This Privacy Policy is an integral part of the Terms governing the use of our Platform, which can be accessed at <a href={`${process.env.REACT_APP_WEBSITE_BASE_URL}/`} target="_blank" className="is-link">https://www.skillety.com/</a> and our mobile application “<b>Skillety</b>”
                                        </p>
                                    </div>

                                    <div className="privacy-pol-group">
                                        <div className="privacy-pol-head">
                                            II.	Scope
                                        </div>

                                        <p className="privacy-content">
                                            This Privacy Policy pertains to both current and former users of the platform, including unregistered users whose publicly available data we gather from various websites. By accessing and/or utilizing/downloading the mobile app, you acknowledge and consent to the practices outlined in this privacy policy. It is essential to read this Privacy Policy alongside our Terms of Use, available on our platform.
                                        </p>

                                        <p className="privacy-content">
                                            This Privacy Policy is issued and interpreted in accordance with the Information Technology Act, 2000, and its associated rules, including the Information Technology (reasonable security practices and procedures and sensitive personal data or information) Rules, 2011, as amended, along with other relevant laws enacted from time to time. The Company is committed to adhering to the provisions outlined in the Information Technology Act, 2000, and its associated rules. No physical, electronic, or digital signature is required for this Privacy Policy.
                                        </p>

                                        <p className="privacy-content">
                                            This document elucidates the Company’s stance on the collection, utilization, disclosure, and transfer of Your Personally Identifiable Information (PII) concerning the use of the platform, encompassing the transmission of information and content.
                                        </p>

                                        <p className="privacy-content mb-0">
                                            By visiting the Platform or utilizing any Services, content, features, technologies, or functions provided on the Company’s Platform, you signify your acceptance of this Privacy Policy.
                                        </p>
                                    </div>

                                    <div className="privacy-pol-group">
                                        <div className="privacy-pol-head">
                                            III. Our Commitment to Protecting Your Privacy
                                        </div>

                                        <p className="privacy-content">
                                            At Skillety, we hold a deep commitment to safeguarding your privacy. We recognize the paramount importance of upholding the confidentiality and security of your personal information. It is our priority to ensure that you feel safe and secure when utilizing our services, and we take our responsibility to protect your data seriously.
                                        </p>

                                        <p className="privacy-content">
                                            This Privacy Statement outlines our online data collection and usage policies and practices that pertain to this website. It details how and when personal information is collected, utilized, shared, and secured, as well as providing information on your choices regarding the use, access, and correction of your personal data.
                                        </p>

                                        <p className="privacy-content">
                                            Our Privacy Commitment extends to the data collected on the Skillety platforms, as well as the information sourced from publicly available data across websites for unregistered users. These composite profiles are made accessible to potential employers for employment-related considerations.
                                        </p>

                                        <p className="privacy-content">
                                            Registered users have the option to revoke their consent to this policy. To do so, please contact us at policy@skillety.com. Upon revoking consent, your account and profile information will be removed from our website. Unregistered users can also reach out to us at policy@skillety.com or utilize the opt-out link provided on the Skillety platforms.
                                        </p>

                                        <div className="privacy-content-sub-area">
                                            <div className="privacy-content-sub-grp">
                                                <div className="privacy-content-sub-head">
                                                    Skillety Identity
                                                </div>

                                                <p className="privacy-content-sub">
                                                    Skillety, the primary talent platform operating within an expansive online employment marketplace across the globe. Given, our presence in diverse markets, aspects of this Privacy Policy cater to specific requirements outlined in relevant Privacy Laws across various regions. It is imperative that you thoroughly review this Privacy Policy to comprehend all pertinent aspects applicable to you.
                                                </p>
                                            </div>

                                            <div className="privacy-content-sub-grp">
                                                <div className="privacy-content-sub-head">
                                                    Responsibility for your Personal Information
                                                </div>

                                                <p className="privacy-content-sub">
                                                    This section delineates the legal entity within Skillety responsible for Personal Information collected in India and the Gulf region. Skillety assumes this responsibility, clarifying our roles as either controllers or processors of your Personal Information. For Candidates, when you apply to a role advertised on our platform, we process your job application (including any Personal Information provided) on behalf of the respective employer. In this scenario, we act as the data processor while the employer acts as the data controller.
                                                </p>

                                                <p className="privacy-content-sub">
                                                    Skillety is dedicated to safeguarding the privacy of its Users. We are committed to ensuring a secure and safe User experience by protecting the Personal Information (referred to as “PII”) entrusted to us. Safeguarding your PII against misuse is a paramount concern for us. We have taken all necessary and reasonable measures to uphold your privacy and confidentiality.
                                                </p>
                                            </div>

                                            <div className="privacy-content-sub-grp">
                                                <div className="privacy-content-sub-head">
                                                    Types of Personal Information we collect
                                                </div>

                                                <p className="privacy-content-sub">
                                                    This section delineates the legal entity within Skillety responsible for Personal Information collected in India and the Gulf region. Skillety assumes this responsibility, clarifying our roles as either controllers or processors of your Personal Information. For Candidates, when you apply to a role advertised on our platform, we process your job application (including any Personal Information provided) on behalf of the respective employer. In this scenario, we act as the data processor while the employer acts as the data controller.
                                                </p>

                                                <p className="privacy-content-sub">
                                                    Skillety is dedicated to safeguarding the privacy of its Users. We are committed to ensuring a secure and safe User experience by protecting the Personal Information (referred to as “PII”) entrusted to us. Safeguarding your PII against misuse is a paramount concern for us. We have taken all necessary and reasonable measures to uphold your privacy and confidentiality.
                                                </p>

                                                <ul className="privacy-content-sub-ul">
                                                    <li>
                                                        When registering as a Registered User on our platforms (whether as a Candidate or employer), basic information such as name, email address, Education Details, and Employment History is required.
                                                    </li>
                                                    <li>
                                                        PII of Jobseekers/candidates is automatically collected upon registration. Information utilized during job searches and applications is stored to enhance career opportunities on our platform. We are also authorized to gather publicly available data from websites, including employment history, educational background, and skills, to enrich your Skillety profile, accessible to potential employers for employment-related considerations.
                                                    </li>
                                                    <li>
                                                        For Unregistered Users, we compile composite profiles using publicly available data from websites, including employment history, educational background, and skills, made accessible to potential employers for employment-related considerations.
                                                    </li>
                                                </ul>

                                                <p className="privacy-content-sub">
                                                    * NOTE: The information (including Personal Information) we collect is comprehensive and may vary based on regional or country-specific requirements.
                                                </p>
                                            </div>

                                            <div className="privacy-content-sub-grp">
                                                <div className="privacy-content-sub-head">
                                                    Registered User- PI collection
                                                </div>

                                                <div className="pri-pol-table-area">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered table-lg">
                                                            <thead>
                                                                <tr>
                                                                    <th>Objectives</th>
                                                                    <th>Categories of Personal Information</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Essential Information</td>
                                                                    <td>
                                                                        - Name - Gender (region specific) - Date of birth (region specific) - Email Address - Mobile number - Current Location - Curriculum Vitae - Education Details - Employment History
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Communication Details</td>
                                                                    <td>
                                                                        - Email Address - Mobile number - Single sign-on - Google or Facebook logins or LinkedIn (for all users) - Company name - Company Type – Industry Sector – Company Headcount – Business Model - Business address - Billing address - Name, email address/phone number of additional users of the Employer account (for employers) - Other contact data shared by users
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Profile Information</td>
                                                                    <td>
                                                                        - Personal summary - Work experience - Educational background - Language skills - Other skills - Professional qualifications - Salary information (Current and Expected) - Preferred job classifications and locations (for Candidates)
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Usage and Device Details</td>
                                                                    <td>
                                                                        - Activity on the Platform - Internet protocol (IP) address and internet service provider (ISP) - Browser type and device ID - Operating system, date/time stamp, and/or clickstream data - Pages accessed - Computer IP Address - Geo-location information - Computer and connection information - Referral URL - Ad data and any other information (automatically collected through Cookies)
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>External Data</td>
                                                                    <td>
                                                                        - First Name – Last Name -  Email address/es - Mobile phone number/s - Other contact number - Contact address - Workplace - Position - Relationship with the Registered User (with consent of relevant third party)
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Consent for Public Data</td>
                                                                    <td>
                                                                        Authorization for Skillety to source publicly available data across websites for employment history, educational background, skills, and other pertinent information to enrich user profiles, made accessible to potential employers for employment-related considerations
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Location Information</td>
                                                                    <td>
                                                                        - Home address - Contact address - Device location (if permission granted)
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="privacy-content-sub-grp">
                                                <div className="privacy-content-sub-head">
                                                    Unregistered User – PI collection
                                                </div>

                                                <div className="pri-pol-table-area">
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered table-lg">
                                                            <thead>
                                                                <tr>
                                                                    <th>Objectives</th>
                                                                    <th>Categories of Personal Information</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Essential Information</td>
                                                                    <td>
                                                                        - Name - Gender (region specific) - Date of birth (region specific) - Email Address - Mobile number - Current Location - Curriculum Vitae - Education Details - Employment History
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="privacy-content-sub-grp">
                                                <div className="privacy-content-sub-head">
                                                    Sensitive Information
                                                </div>

                                                <p className="privacy-content-sub">
                                                    While we generally do not collect sensitive information, there are instances where we may handle such data:
                                                </p>

                                                <ul className="privacy-content-sub-ul">
                                                    <li>
                                                        Explicit consent from you to collect and process sensitive information.
                                                    </li>
                                                    <li>
                                                        Inclusion of sensitive information in documents or profiles voluntarily provided during the job application process.
                                                    </li>
                                                    <li>
                                                        Authorization to source publicly available data to enrich profiles for employment-related considerations.
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="privacy-content-sub-grp">
                                                <div className="privacy-content-sub-head">
                                                    Legal Basis
                                                </div>

                                                <p className="privacy-content-sub">
                                                    We collect sensitive information based on legal grounds, ensuring compliance with relevant laws and regulations. Your privacy and security are our top priority at Skillety. We strive to maintain transparency and trust in our data handling practices. If you have any concerns or inquiries regarding your privacy, please contact us.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="privacy-pol-group">
                                        <div className="privacy-pol-head">
                                            IV.	Skillety’s Use of Your Personal Information
                                        </div>

                                        <p className="privacy-content">
                                            At Skillety, we are committed to using your personal data ethically and responsibly ensuring your privacy is upheld
                                        </p>

                                        <div className="privacy-content-sub-area">
                                            <div className="privacy-content-sub-grp">
                                                <div className="privacy-content-sub-head">
                                                    How We Use Your Personal Information
                                                </div>

                                                <p className="privacy-content-sub">
                                                    We process your personal data in a transparent and lawful manner, aligning with the purpose of your interaction with our platform. Here's how we may utilize your Personal Information:
                                                </p>

                                                <ul className="privacy-content-sub-ul">
                                                    <li>
                                                        <b>Providing Services and Products:</b> Sending you relevant job alerts, calendar reminders, search results, and recommendations for jobs or candidates.
                                                    </li>
                                                    <li>
                                                        <b>User Protection and Support:</b> Ensuring the safety of our Users and providing assistance through our customer support services.
                                                    </li>
                                                    <li>
                                                        <b>Enhancing User Experience:</b> Utilizing data collected from cookies and similar technologies to improve your experience on our platform, without associating identifiers with sensitive categories.
                                                    </li>
                                                    <li>
                                                        <b>Platform Enhancement:</b> Continuously improving our platform and its features to provide you with better services.
                                                    </li>
                                                    <li>
                                                        <b>Market Research and Surveys:</b> Conducting research and surveys to better understand market trends and enhance our products and services.
                                                    </li>
                                                    <li>
                                                        <b>Marketing and Promotions:</b> Informing you about our products, services, and promotions, including those offered by our affiliates.
                                                    </li>
                                                    <li>
                                                        <b>Crime Prevention:</b> Detecting and preventing fraudulent activities, illegal actions, and violations of Skillety's Terms of Use across different jurisdictions.
                                                    </li>
                                                    <li>
                                                        <b>Identity Verification and Due Diligence:</b> Conducting necessary checks for identity verification, government sanctions screening, and due diligence.
                                                    </li>
                                                    <li>
                                                        <b>Legal Proceedings:</b> Engaging in legal proceedings, including establishing, exercising, or defending legal rights, and seeking professional advice when necessary.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <p className="privacy-content">
                                            Your trust and privacy are of utmost importance to us at Skillety. We are committed to using your personal information responsibly and in accordance with applicable laws and regulations. Should you have any questions or concerns regarding the use of your personal data, please feel free to contact us.
                                        </p>
                                    </div>

                                    <div className="privacy-pol-group">
                                        <div className="privacy-pol-head">
                                            V. Cookie Usage and Tracking
                                        </div>

                                        <p className="privacy-content">
                                            We utilize cookies on our website to enhance user experience, provide personalized content, and analyze website traffic. Cookies are small text files that are stored on your device when you visit our site. By continuing to use our website, you consent to the use of cookies as outlined in our Privacy Policy. Our cookies serve various purposes, including but not limited to:
                                        </p>

                                        <ul className="privacy-content-sub-ul">
                                            <li>
                                                Authentication: Cookies help us recognize you and remember your preferences, facilitating a seamless login and personalized user experience.
                                            </li>
                                            <li>
                                                Analytics: We use cookies to gather data on how visitors interact with our website. This information helps us improve the functionality and content of our site.
                                            </li>
                                            <li>
                                                Advertising and Marketing: Cookies enable us to deliver targeted advertisements and promotional materials based on your interests and browsing behaviour.
                                            </li>
                                            <li>
                                                Session Management: Some cookies are essential for the proper functioning of our website, allowing you to navigate pages and access secure areas.
                                            </li>
                                            <li>
                                                Third-Party Cookies: We collaborate with third-party service providers who may employ cookies on our site for analytics, advertising, and other functionalities. For more information, please refer to their respective privacy policies:

                                                <ul className="privacy-content-sub-ul-sub">
                                                    <li>
                                                        <a href="https://policies.google.com/privacy" className="is-link" target="_blank">Google Privacy Policy,</a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.linkedin.com/legal/privacy-policy" className="is-link" target="_blank">LinkedIn Privacy Policy,</a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.facebook.com/policy.php" className="is-link" target="_blank">Facebook Data Policy,</a>
                                                    </li>
                                                    <li>
                                                        <a href="https://twitter.com/en/privacy" className="is-link" target="_blank">Twitter Privacy Policy,</a>
                                                    </li>
                                                    <li>
                                                        <a href="https://privacycenter.instagram.com/policy/?entry_point=ig_help_center_data_policy_redirect" className="is-link" target="_blank">Instagram Data Policy, </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.youtube.com/static?template=privacy_guidelines" className="is-link" target="_blank">YouTube Privacy Policy, </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://policy.pinterest.com/en" className="is-link" target="_blank">Pinterest Privacy Policy, </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://www.factsuite.com/privacy-policy" className="is-link" target="_blank">FactSuite Privacy Policy, </a>
                                                    </li>
                                                </ul>

                                                You have the option to manage your cookie preferences through your browser settings. However, please note that restricting or disabling certain cookies may impact the functionality and user experience of our website.
                                            </li>
                                        </ul>

                                        <p className="privacy-content">
                                            By using our website, you acknowledge and agree to the use of cookies in accordance with this policy. We may update this policy periodically, so we encourage you to review it regularly for any changes. For more information on how we collect, use, and protect your personal information, please refer to our Privacy Policy.
                                        </p>
                                    </div>

                                    <div className="privacy-pol-group">
                                        <div className="privacy-pol-head">
                                            VI.	Collection of Non- Personal Information
                                        </div>

                                        <p className="privacy-content">
                                            Non-Personal information refers to data that does not specifically identify an individual and cannot be used to contact, locate, or identify a person. This information may include but is not limited to:
                                        </p>

                                        <ul className="privacy-content-sub-ul">
                                            <li>
                                                Information related to the type of device you use to access the platform, such as device model, operating system, browser type, and screen resolution.
                                            </li>
                                            <li>
                                                Data regarding your interactions with the platform, such as the pages visited, actions taken, and the duration and frequency of your visits.
                                            </li>
                                            <li>
                                                Information collected through cookies, pixel tags, and other tracking technologies to enhance user experience, understand user preferences, and analyze website traffic.
                                            </li>
                                            <li>
                                                Data that has been compiled and grouped to provide general insights and trends without identifying any specific individual.
                                            </li>
                                            <li>
                                                General data related to age, gender, geographic location, or other attributes that do not directly identify you.
                                            </li>
                                            <li>
                                                Data that has been stripped of any personal identifiers, rendering it impossible to associate with a specific individual.
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="privacy-pol-group">
                                        <div className="privacy-pol-head">
                                            VII. Compliance and Safeguards
                                        </div>

                                        <p className="privacy-content">
                                            The company’s Privacy Policy and the security controls and practices implemented to protect your personal information shall meet the requirements of section 43A of the Information Technology Act, 2000. Section 43A of the Information Technology Act, 2000 requires organizations to implement reasonable security practices and procedures designed to protect personal information from unauthorized access, use, disclosure, alteration, or destruction.
                                        </p>

                                        <p className="privacy-content">
                                            We implement robust security precautions and measures to protect your data and ensure the confidentiality of your information in accordance with applicable laws. These safeguards are designed to prevent unauthorized access, misuse, alteration, or disclosure of your personal and non-personal data. Our security protocols are regularly updated and aligned with industry standards to provide a safe and secure environment for our users in compliance with applicable laws. However, we cannot guarantee the absolute security of the information you provide to the company, and we are not liable for any unauthorized access, disclosure, alteration, or destruction of your information despite our security measures.
                                        </p>

                                        <p className="privacy-content">
                                            It is essential for you to take adequate physical, managerial, and technical safeguards on your end to maintain the integrity and security of your data, including personal information, while using the platforms. The company provides employees training on data security and security and privacy.
                                        </p>
                                    </div>

                                    <div className="privacy-pol-group">
                                        <div className="privacy-pol-head">
                                            VIII. Global Standards Adherence
                                        </div>

                                        <p className="privacy-content">
                                            This Privacy Policy has been formulated in accordance with the prevailing regulations and laws of the India. We are committed to upholding the highest standards of data protection and privacy for our users. In addition to complying with Indian laws, we recognize the importance of adhering to global privacy standards.
                                        </p>

                                        <p className="privacy-content">
                                            For residents of the European Union (EU), our practices align with the General Data Protection Regulation (GDPR), which establishes stringent guidelines for the processing and protection of personal data. For residents of the United States of America (USA), we adhere to the standards set forth by the California Consumer Privacy Act (CCPA) and other relevant regulations applicable to data privacy in the United States.
                                        </p>

                                        <p className="privacy-content">
                                            By utilizing our services, users from different regions implicitly consent to the terms outlined in this Privacy Policy, acknowledging the specific provisions tailored to their respective jurisdictions. We are committed to continually monitoring and updating our practices to ensure ongoing compliance with evolving global privacy standards.
                                        </p>
                                    </div>

                                    <div className="privacy-pol-group">
                                        <div className="privacy-pol-head">
                                            IX.	Minors
                                        </div>

                                        <p className="privacy-content">
                                            The Site is the intended solely for adults. The Site does not knowingly collect personally identifiable information from children under the age of 18. If you are a parent or a legal guardian of a child under the age of 13 and believe your child may have visited our platform, please contact us using the contact information provided at the end of this policy.
                                        </p>
                                    </div>

                                    <div className="privacy-pol-group">
                                        <div className="privacy-pol-head">
                                            X. Your Rights and Choices
                                        </div>

                                        <p className="privacy-content">
                                            We obtain your consent before collecting and processing your personal information, and we only use your information for the purposes outlined in this privacy policy. We provide you with the right to access, correct, and delete your data, as well as the right to object to processing and data portability. You can opt out of receiving marketing communications from us at any time.
                                        </p>

                                        <p className="privacy-content">
                                            If you would like to exercise any of these rights, or if you have questions or concerns about your personal information, please contact us using the contact information provided at the end of this policy. We will respond to your request as soon as reasonably possible, and in any event, within the timeframes required by applicable law.
                                        </p>

                                        <p className="privacy-content">
                                            We obtain your consent before collecting and processing your personal information, and we only use your information for the purposes outlined in this privacy policy. We provide you with the right to access, correct, and delete your data, as well as the right to object to processing and data portability. You can opt out of receiving marketing communications from us at any time.
                                        </p>

                                        <p className="privacy-content">
                                            If you request the deletion of your personal information, we may not be able to provide you with the services you have requested or may be required by law to retain certain information. You have choices about the types of marketing communications you receive from us. You may opt out of receiving marketing emails by following the instructions in the email or by contacting us using the contact information provided at the end of this policy. We may still need to send you certain transactional or administrative messages related to your account or the services we provide, even if you have opted out of marketing communications.
                                        </p>

                                        <p className="privacy-content-sub-title">
                                            You have the right to:
                                        </p>

                                        <ul className="privacy-content-list-area">
                                            <li>
                                                request that we provide you with a copy of the personal information we hold about you, and request that we transmit that information to another controller, where technically feasible;
                                            </li>
                                            <li>
                                                withdraw your consent to the processing of your personal information at any time. Withdrawing your consent may not affect the lawfulness of any processing we have conducted based on your consent before its withdrawal;
                                            </li>
                                            <li>
                                                lodge a complaint with a supervisory authority if you believe that we have violated your rights or applicable law.
                                            </li>
                                        </ul>

                                        <p className="privacy-content">
                                            We may need to verify your identity before responding to any request or inquiry related to your personal information.
                                        </p>

                                        <p className="privacy-content-sub-title">
                                            Deleting Your Information
                                        </p>

                                        <p className="privacy-content">
                                            If you wish to have the Personal Information that you have provided to us deleted, you can always do so by sending a request to us at the email address of our Customer Services department provided at the bottom of this page. You may note that the deletion of certain Personal Information may lead to the cancellation of your registration with our Platforms and your access to certain features of the Website.
                                        </p>
                                    </div>

                                    <div className="privacy-pol-group">
                                        <div className="privacy-pol-head">
                                            XI.	Confidentiality and Security
                                        </div>

                                        <p className="privacy-content">
                                            The platform uses ordinary industry-standard technology designed to help keep your Personal Information safe. We prioritize the security and confidentiality of your Personal Information. Stringent measures are implemented to protect your data, both physically and electronically.
                                        </p>

                                        <p className="privacy-content">
                                            The secure server software (SSL) encrypts all information you put in before it is sent to us. Furthermore, all of the customer data we collect is protected against unauthorized access. To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we will employ commercially reasonable and practicable security practices and procedures and security methods and technologies. We will also ensure on a reasonable commercial efforts basis that any agent or third party that we employ complies with the same security standards as us for the protection of your Personal Information.
                                        </p>

                                        <p className="privacy-content">
                                            Your information may be transferred to or be maintained on computers, computer systems, and computer resources located outside of your state or country where the privacy laws may not be as protective as those where you live. If you are located outside India and choose to provide information to us, please be aware that Platform keeps or transfers information to India and processes it there. Your submission of such information represents your agreement to that transfer.
                                        </p>

                                        <p className="privacy-content">
                                            Unfortunately, the transmission of information over the internet is not completely secure. Although we strive to protect your personal data, we cannot guarantee the security of your data while it is being transmitted to our site; any transmission is at your own risk. Once we have received your information, we have commercially reasonable procedures and security features in place to reasonably endeavor to prevent unauthorized access.
                                        </p>

                                        <p className="privacy-content-sub-title">
                                            Links to Other Sites / Financial Data
                                        </p>

                                        <p className="privacy-content">
                                            Our website links to other websites that may collect personally identifiable information about you. The company is not responsible for the privacy practices or the content of those linked websites.
                                        </p>

                                        <p className="privacy-content-sub-title">
                                            Phishing
                                        </p>

                                        <p className="privacy-content">
                                            Identity theft and the practice currently known as “phishing” are of great concern to Platform. Safeguarding information to help protect you from identity theft is a top priority. We do not and will not, at any time, request your credit card information or national identification numbers in a non-secure or unsolicited email or telephone communication.
                                        </p>
                                    </div>

                                    <div className="privacy-pol-group">
                                        <div className="privacy-pol-head">
                                            XII. Engagement on Social Media Platforms
                                        </div>

                                        <p className="privacy-content">
                                            At Skillety, we utilize various social media channels, pages, and accounts to engage, inform, and assist our users. We monitor and analyze comments and posts made on these platforms to enhance our products and services.
                                        </p>

                                        <p className="privacy-content">
                                            <b>Guidelines for Social Media Interaction:</b>
                                        </p>

                                        <p className="privacy-content">
                                            When engaging with us on social media, please refrain from sharing:w.
                                        </p>

                                        <ul className="privacy-content-list-area">
                                            <li>
                                                Personal data of sensitive nature, including racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic data, biometric data, health information, sexual orientation, criminal convictions, offenses, or national identification numbers.
                                            </li>
                                            <li>
                                                Content that is excessive, inappropriate, offensive, or defamatory.
                                            </li>
                                        </ul>

                                        <p className="privacy-content">
                                            Please note that Skillety holds responsibility only for the information posted by its authorized representatives. We are committed to using any personal information received through social media platforms responsibly and in accordance with our Privacy Policy.
                                        </p>

                                        <p className="privacy-content">
                                            Should you have any questions or concerns regarding social media interaction or the handling of personal information, please contact us directly.
                                        </p>
                                    </div>

                                    <div className="privacy-pol-group">
                                        <div className="privacy-pol-head">
                                            XIII. Changes to This Policy
                                        </div>

                                        <p className="privacy-content">
                                            We reserve the right to amend this Privacy Policy from time to time, as required or in pursuance of changes in relevant laws. If we decide to make any changes to this privacy policy, we will post those
                                        </p>

                                        <p className="privacy-content">
                                            changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it. However, any substantial change to this Privacy Policy will be communicated to you through our website or through email as provided on the website and/or App.
                                        </p>
                                    </div>

                                    <div className="privacy-pol-group">
                                        <div className="privacy-pol-head">
                                            XIV. Governing Law
                                        </div>

                                        <p className="privacy-content">
                                            The terms of this Privacy Policy shall be governed and construed in accordance with Govt. of India. Any dispute regarding or arising out of this Privacy Policy shall be subject to the exclusive jurisdiction of the courts in Hyderabad, Telangana.
                                        </p>
                                    </div>

                                    <div className="privacy-pol-group">
                                        <div className="privacy-pol-head">
                                            XV.	Limitation of Liability
                                        </div>

                                        <p className="privacy-content">
                                            At Skillety, we prioritize the security of your information. Here's our disclaimer:
                                        </p>

                                        <p className="privacy-content">
                                            SKILLETY DOES NOT RETAIN ANY ACCOUNT-RELATED INFORMATION OR CREDIT/DEBIT CARD DETAILS. WE HOLD NO LIABILITY FOR ANY LOSS OR DAMAGE RESULTING FROM THE INADVERTENT DISCLOSURE OF USER ACCOUNT INFORMATION, CREDIT CARDS, OR DEBIT CARDS DURING ONLINE TRANSACTIONS OR PAYMENTS MADE FOR PRODUCTS OR SERVICES OFFERED THROUGH OUR PLATFORM.
                                        </p>

                                        <p className="privacy-content">
                                            If you voluntarily share Personal Information with Skillety, not requested during registration, we shall not be held liable for any breaches or disclosures related to such information.
                                        </p>

                                        <p className="privacy-content text-decoration-underline">
                                            For inquiries regarding our Privacy Policy or the protection of your Personal Information, please contact Skillety’s Data Protection Officer/Grievance Officer provided in the following address:
                                        </p>
                                    </div>

                                    <div className="privacy-pol-group">
                                        <div className="privacy-pol-head">
                                            XVI. Grievance Officer
                                        </div>

                                        <p className="privacy-content">
                                            In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:
                                        </p>

                                        <div className="terms-con-updated mt-0">
                                            Name&nbsp;:&nbsp;<span>Mrs. Supriya Das</span>
                                        </div>
                                        <div className="terms-con-updated">
                                            Email Address&nbsp;:&nbsp;<span><a href="mailto:supriya@skillety.com" className="color-link">supriya@skillety.com</a></span>
                                        </div>
                                        <div className="terms-con-updated">
                                            Address&nbsp;:&nbsp;<span>Plot No. 45, Sarvasukhi Colony, West Marredpally, Secunderabad, Hyderabad, Telangana, 500026.</span>
                                        </div>
                                        <div className="terms-con-updated">
                                            Contact Number&nbsp;:&nbsp;<span><a href="tel:9731021066" className="color-link">9731021066</a></span>
                                        </div>

                                        <div className="terms-con-note mt-4">
                                            <b>Email:</b> <a href="mailto:policy@skillety.com" className="color-link">policy@skillety.com </a> ; Alternate mail ID: <a href="mailto:support@skillety.com" className="color-link">support@skillety.com</a>
                                        </div>
                                        <div className="terms-con-note mt-2">
                                            <b>Time:</b> Mon-Fri (09:00 to 18:00 Hours)
                                        </div>
                                        <div className="terms-con-note mt-2">
                                            <b>Contact Us:</b> <a href="tel:+919731021066" className="color-link">+91-9731021066</a>
                                        </div>
                                        <div className="terms-con-note mt-4">
                                            You can contact us via the links at the bottom of the page for any questions about this Privacy Policy, the practices of the Platform, or your dealings with the Website.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CandidateFooter />
        </div>
    );
};
export default PrivacyPolicyTalent;
