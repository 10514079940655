import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import $ from 'jquery';
import './Packages.css';
import './Packages-responsive.css';
import Layout from '../../components/Layout';
import { Footer } from '../../components/Footer';
import axios from 'axios';
import AuthContext from '../../context/AuthContext';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import TagManager from 'react-gtm-module';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';


const Packages = ({ companyId }) => {
  const navigate = useNavigate();
  const [clientToken, setclientToken] = useState("");

  const { getProtectedData, getClientChoosenPlan, packageSelectionDetail, handlePayment, paymentSucess, errorMsg, setErrorMsg, spinStatus, packageLogInUserData, setPackageLogInUserData, searchLoading, setsearchLoading } = useContext(AuthContext);

  const [employeeId, setEmployeeId] = useState("");
  const [loginClientDetail, setLoginClientDetail] = useState();
  const [currentPackage, setCurrentPackage] = useState();

  const [packageInfo, setPackageInfo] = useState();
  const [serviceInfo, setServiceInfo] = useState();
  const [valueAddedServiceInfo, setValueAddedServiceInfo] = useState();

  const [allPackages, setAllPackages] = useState([]);
  const [packageCantBuy, setPackageCantBuy] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [showLoginPassword, setshowLoginPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  const { loginUser, loginId } = useContext(AuthContext);
  const [loggedInUserData, setLoggedInUserData] = useState(null);
  const [checkValueResult, setCheckValueResult] = useState({
    email: false,
    mobile: false
  });
  const [clientInActiveStatus, setClientInActiveStatus] = useState(false);
  const [redirectURL, setRedirectURL] = useState(null);
  const location = useLocation();
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  // Retrieve the 'from' query parameter
  const query = new URLSearchParams(location.search);
  const pageFrom = query.get('from');

  const initialClientDetails = {
    fullName: "",
    // lastName:"",
    email: "",
    mobile: "",
    // companyName:"",
    GSTIN: "",
    password: "",
    confirmPassword: ""
  }
  const initialLoginCredentials = {
    userId: "",
    password: ""
  }
  const [clientDetail, setClientDetail] = useState(initialClientDetails);
  const [toggleLogin, setToggleLogin] = useState(false);
  const [loginCredentials, setLoginCredentials] = useState(initialLoginCredentials);

  const handleInput = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setClientDetail({ ...clientDetail, [name]: value });
  }

  const handlePhoneNumberChange = (phoneNumber) => {
    setClientDetail((prevCredentials) => ({
        ...prevCredentials,
        ['mobile']: phoneNumber,
    }));
    if (phoneNumber) {
        phoneNumber = phoneNumber.toString();
        const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
        setIsPhoneValid(parsedPhoneNumber ? parsedPhoneNumber.isValid() : false);
    } else {
        setIsPhoneValid(false);
    }
};

  useEffect(() => {
    // Regular expression to check for email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Regular expression to check for phone number
    const phoneRegex = /^\d{9,}$/;

    const checkingValue = {
      value: clientDetail.email
    }

    if (clientDetail.email) {
      setCheckValueResult({ ...checkValueResult, email: false });
      axios.post(`${process.env.REACT_APP_BASE_API}/check-email-phone-validity`, checkingValue)
        .then(res => {
          console.log(res.data);
          setCheckValueResult({ ...checkValueResult, email: res.data.emailExists });
        }).catch(err => {
          console.log(err);
        })
    }

  }, [clientDetail.email]);

  useEffect(() => {

    const checkingValue = {
      value: clientDetail.mobile
    }

    if (clientDetail.mobile) {
      setCheckValueResult({ ...checkValueResult, mobile: false });
      axios.post(`${process.env.REACT_APP_BASE_API}/check-email-phone-validity`, checkingValue)
        .then(res => {
          console.log(res.data);
          setCheckValueResult({ ...checkValueResult, mobile: res.data.phoneExists });
        }).catch(err => {
          console.log(err);
        })
    }

  }, [clientDetail.mobile]);

  const handleInputLogin = (e) => {
    setErrorMsg("");
    e.preventDefault();
    const { name, value } = e.target;
    setLoginCredentials({ ...loginCredentials, [name]: value });
  }

  useEffect(() => {

    const checkingValue = {
      value: loginCredentials.userId
    }

    if (loginCredentials.userId) {
      setClientInActiveStatus(false);
      axios.post(`${process.env.REACT_APP_BASE_API}/check-user-active-status`, checkingValue)
        .then(res => {
          console.log(res.data);
          setClientInActiveStatus(res.data.inActive);
        }).catch(err => {
          console.log(err);
        })
    }

  }, [loginCredentials.userId]);

  const handleRegister = (e) => {
    e.preventDefault();
    if(isPhoneValid){
      if ((clientDetail.password === clientDetail.confirmPassword) && (clientDetail.password.length >= 8)) {
        const { confirmPassword, ...detail } = clientDetail;
        axios.post(`${process.env.REACT_APP_BASE_API}/create-quick-client`, detail)
          .then(res => {
            console.log(res.data);
            setClientDetail(initialClientDetails);
            if (res.data.message === "New Client Registered Successfully & logged in.") {
              localStorage.setItem("clientToken", JSON.stringify(res.data.accessToken));
              setLoggedInUserData(res.data);
              // setToggleLogin(true);
              if (packageInfo) {
                handleBuy(res.data.companyId, res.data.accessToken);
              } else if (serviceInfo) {
                handleBuyService(res.data.companyId, res.data.accessToken)
              } else if (valueAddedServiceInfo) {
                handleBuyValueAddedService(res.data.companyId, res.data.accessToken)
              }
            } else {
              showSuccessMessage(res.data.message);
            }
          }).catch(err => {
            console.log(err);
            setClientDetail(initialClientDetails);
            showErrorMessage(err.response.data.error);
          })
      }
    }
  }


  const handleLogin = (e) => {
    e.preventDefault();
    loginUser([loginCredentials, "login-Client"], "fromPackage");
  }

  const handleTogglePassword = () => {
    setshowPassword(!showPassword);
  };

  const handleToggleConfirmPassword = () => {
    setshowConfirmPassword(!showConfirmPassword);
  };

  const handleToggleLoginPassword = () => {
    setshowLoginPassword(!showLoginPassword);
  };

  useEffect(() => {
    //for quantity input
    $(".sol-price-table-qty-input").val(0);

    $(".sol-price-table-qty-input").on("input", function () {
      var $input = $(this);
      var value = parseInt($input.val());

      if (value < 0) {
        $input.val(0);
      }
    });

    $(".sol-price-table-qty-button.decrement").on("click", function () {
      var $input = $(this).siblings(".sol-price-table-qty-input");
      var currentValue = parseInt($input.val());

      if (currentValue > 0) {
        $input.val(currentValue - 1);
      }
    });

    $(".sol-price-table-qty-button.increment").on("click", function () {
      var $input = $(this).siblings(".sol-price-table-qty-input");
      var currentValue = parseInt($input.val());
      $input.val(currentValue + 1);
    });
  }, [packageInfo]);

  const [activeTab, setActiveTab] = useState(1);
  const [activeServiceTab, setActiveServiceTab] = useState(1);
  const [activeValServiceTab, setActiveValServiceTab] = useState(1);

  const [quantity, setQuantity] = useState(
    {
      cvViews: 0,
      logins: 0,
      activeJobs: 0
    }
  );

  const [quantitySVAS, setQuantitySVAS] = useState(
    {
      OnlineTechnicalAssessment: 0,
      L1Interview_0to8yrs: 0,
      L1Interview_8to15yrs: 0,
      L1Interview_above15yrs: 0,
      BGVComprehensive: 0
    }
  );
  const [validity, setValidity] = useState(1);


  const [total, setTotal] = useState({
    cvViews: 0,
    logins: 0,
    activeJobs: 0
  })

  const [totalSVAS, setTotalSVAD] = useState({
    OnlineTechnicalAssessment: 0,
    L1Interview_0to8yrs: 0,
    L1Interview_8to15yrs: 0,
    L1Interview_above15yrs: 0,
    BGVComprehensive: 0
  })


  const switchTab = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const switchServiceTab = (tabIndex) => {
    setActiveServiceTab(tabIndex);
  };

  const switchValServiceTab = (tabIndex) => {
    setActiveValServiceTab(tabIndex);
  };

  const handleDoneclick = () => {
    // getClientChoosenPlan(loginClientDetail?.companyId, clientToken);
    window.location.href = `${process.env.REACT_APP_ADMIN_BASE_URL}/client-dashboard/${clientToken}?loginId=${loginId}`
    switchTab(1);

    window.scrollTo({
      top: 300,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (quantity.cvViews > 0) {
      const servicePrice = parseInt(quantity.cvViews) * 5
      // const discount = parseInt(validity.cvViews) <= 3 ? "0" : 3 < parseInt(validity.cvViews) <= 6 ? "5" : "10"
      // const discountAmount = parseInt(servicePrice) * parseInt(discount) / 100
      // const GSTAmount = (parseInt(servicePrice) - discountAmount) * (allPackages[0]?.GST) / 100

      setTotal({ ...total, cvViews: servicePrice ? servicePrice : 0 });
    } else {
      setTotal({ ...total, cvViews: 0 });
    }


  }, [quantity.cvViews])

  useEffect(() => {
    if (quantity.logins > 0) {
      const servicePrice = parseInt(quantity.logins) * 1000
      // const discount = parseInt(validity.logins) <= 3 ? "0" : 3 < parseInt(validity.logins) <= 6 ? "5" : "10"
      // const discountAmount = parseInt(servicePrice) * parseInt(discount) / 100
      // const GSTAmount = (parseInt(servicePrice) - discountAmount) * (allPackages[0]?.GST) / 100

      setTotal({ ...total, logins: servicePrice ? servicePrice : 0 });
    } else {
      setTotal({ ...total, logins: 0 });
    }


  }, [quantity.logins])

  useEffect(() => {
    if (quantity.activeJobs > 0) {
      const servicePrice = parseInt(quantity.activeJobs) * 100
      // const discount = parseInt(validity.activeJobs) <= 3 ? "0" : 3 < parseInt(validity.activeJobs) <= 6 ? "5" : "10"
      // const discountAmount = parseInt(servicePrice) * parseInt(discount) / 100
      // const GSTAmount = (parseInt(servicePrice) - discountAmount) * (allPackages[0]?.GST) / 100

      setTotal({ ...total, activeJobs: servicePrice ? servicePrice : 0 });
    } else {
      setTotal({ ...total, activeJobs: 0 });
    }


  }, [quantity.activeJobs])

  useEffect(() => {
    if (quantitySVAS.OnlineTechnicalAssessment) {
      const servicePrice = parseInt(quantitySVAS.OnlineTechnicalAssessment) * 120
      const GSTAmount = (parseInt(servicePrice)) * (allPackages[0]?.GST) / 100

      setTotalSVAD({ ...totalSVAS, OnlineTechnicalAssessment: servicePrice + GSTAmount ? servicePrice + GSTAmount : 0 });
    } else {
      setTotalSVAD({ ...totalSVAS, OnlineTechnicalAssessment: 0 });
    }


  }, [quantitySVAS.OnlineTechnicalAssessment])

  useEffect(() => {
    if (quantitySVAS.L1Interview_0to8yrs) {
      const servicePrice = parseInt(quantitySVAS.L1Interview_0to8yrs) * 1500
      const GSTAmount = (parseInt(servicePrice)) * (allPackages[0]?.GST) / 100

      setTotalSVAD({ ...totalSVAS, L1Interview_0to8yrs: servicePrice + GSTAmount ? servicePrice + GSTAmount : 0 });
    } else {
      setTotalSVAD({ ...totalSVAS, L1Interview_0to8yrs: 0 });
    }


  }, [quantitySVAS.L1Interview_0to8yrs])

  useEffect(() => {
    if (quantitySVAS.L1Interview_8to15yrs) {
      const servicePrice = parseInt(quantitySVAS.L1Interview_8to15yrs) * 2000
      const GSTAmount = (parseInt(servicePrice)) * (allPackages[0]?.GST) / 100

      setTotalSVAD({ ...totalSVAS, L1Interview_8to15yrs: servicePrice + GSTAmount ? servicePrice + GSTAmount : 0 });
    } else {
      setTotalSVAD({ ...totalSVAS, L1Interview_8to15yrs: 0 });
    }


  }, [quantitySVAS.L1Interview_8to15yrs])

  useEffect(() => {
    if (quantitySVAS.L1Interview_above15yrs) {
      const servicePrice = parseInt(quantitySVAS.L1Interview_above15yrs) * 2500
      const GSTAmount = (parseInt(servicePrice)) * (allPackages[0]?.GST) / 100

      setTotalSVAD({ ...totalSVAS, L1Interview_above15yrs: servicePrice + GSTAmount ? servicePrice + GSTAmount : 0 });
    } else {
      setTotalSVAD({ ...totalSVAS, L1Interview_above15yrs: 0 });
    }


  }, [quantitySVAS.L1Interview_above15yrs])

  useEffect(() => {
    if (quantitySVAS.BGVComprehensive) {
      const servicePrice = parseInt(quantitySVAS.BGVComprehensive) * 3500
      const GSTAmount = (parseInt(servicePrice)) * (allPackages[0]?.GST) / 100

      setTotalSVAD({ ...totalSVAS, BGVComprehensive: servicePrice + GSTAmount ? servicePrice + GSTAmount : 0 });
    } else {
      setTotalSVAD({ ...totalSVAS, BGVComprehensive: 0 });
    }


  }, [quantitySVAS.BGVComprehensive])

  ////for buy service
  const handleServiceBuyNowClick = () => {
    if (quantity.cvViews > 0 || quantity.logins > 0 || quantity.activeJobs > 0) {
      switchTab(2);
      let serviceNames = [];
      if (quantity.cvViews > 0) {
        serviceNames.push("CV Views")
      }
      if (quantity.logins > 0) {
        serviceNames.push("Login IDs")
      }
      if (quantity.activeJobs > 0) {
        serviceNames.push("Job Postings")
      }
      const servicePrice = parseInt(quantity.cvViews || 0) * 5 + parseInt(quantity.logins || 0) * 1000 + parseInt(quantity.activeJobs || 0) * 100
      const parsedValidity = parseInt(validity);

      const discount = parsedValidity <= 3 ? "0" : (parsedValidity <= 6 ? "5" : "10");
      const discountAmount = parseInt(servicePrice) * parseInt(discount) / 100
      const GSTAmount = (parseInt(servicePrice) - discountAmount) * (allPackages[0]?.GST) / 100

      setServiceInfo({
        // id: loginClientDetail?.companyId,
        serviceNames,
        quantities: {
          cvViews: parseInt(quantity.cvViews),
          logins: parseInt(quantity.logins),
          activeJobs: parseInt(quantity.activeJobs)
        },
        validity: parseInt(validity),
        servicePrice: servicePrice.toString(),
        finalAmount: (parseInt(servicePrice) - discountAmount + GSTAmount).toString(),
        discount,
        discountAmount: discountAmount.toString(),
        GST: allPackages[0]?.GST.toString(),
        GSTAmount: GSTAmount.toString()
      });

      setPackageInfo();
      setValueAddedServiceInfo();

      window.scrollTo({
        top: 300,
        behavior: 'smooth',
      });
    } else {
      showPackageBuyError(
        `Please select quantity!`
      );
    }
  };


  const handleServicePreviousClick = () => {
    setServiceInfo()
    if (activeServiceTab === 2) {
      switchServiceTab(1);
    } else if (activeServiceTab === 3) {
      switchServiceTab(2);
    }
  };

  const handleServicePayClick = (companyId, token) => {

    handleBuyService(companyId, token)

  };



  const handleServiceDoneclick = () => {
    switchServiceTab(1);
  };
  ///


  ////for buy value service buy
  const handleValServiceBuyNowClick = (serviceName, quantity) => {
    if (quantity) {
      switchTab(2);
      const unitPrice = serviceName === "OnlineTechnicalAssessment" ? 120 : serviceName === "L1Interview(0to8yrs)" ? 1500 : serviceName === "L1Interview(8to15yrs)" ? 2000 : serviceName === "L1Interview(>15yrs)" ? 2500 : 3500;
      const servicePrice = parseInt(quantity) * unitPrice

      const GSTAmount = (parseInt(servicePrice)) * (allPackages[0]?.GST) / 100
      setValueAddedServiceInfo({
        // id: loginClientDetail?.companyId,
        serviceName,
        quantity: parseInt(quantity),
        servicePrice: servicePrice.toString(),
        finalAmount: (parseInt(servicePrice) + GSTAmount).toString(),
        GST: allPackages[0]?.GST.toString(),
        GSTAmount: GSTAmount.toString()
      });

      setServiceInfo();
      setPackageInfo();

      window.scrollTo({
        top: 300,
        behavior: 'smooth',
      });
    } else {
      showPackageBuyError(
        `Please select quantity!`
      );
    }

  };

  const handleValServicePreviousClick = () => {
    setValueAddedServiceInfo()
    if (activeValServiceTab === 2) {
      switchValServiceTab(1);
    } else if (activeValServiceTab === 3) {
      switchValServiceTab(2);
    }
  };

  const handleValServicePayClick = (companyId, token) => {

    handleBuyValueAddedService(companyId, token)


  };

  const handleValServiceDoneclick = () => {
    switchValServiceTab(1);
  };
  ///

  // const handleBuyNowClick = () => {
  //   switchTab(2);
  // };

  // const handleNextClick = () => {
  //   if (activeTab === 2) {
  //     switchTab(3);
  //   }
  // };

  const handlePreviousClick = () => {
    if (activeTab === 2) {
      switchTab(1);
    } else if (activeTab === 3) {
      switchTab(2);
    }

    setPackageInfo();
    setLoggedInUserData(null);
    setPackageLogInUserData(null)

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const [isTestInfoHovered, setIsTestInfoHovered] = useState(false);
  const [isStarterInfoHovered, setIsStarterInfoHovered] = useState(false);
  const [isScaleInfoHovered, setIsScaleInfoHovered] = useState(false);
  const [isGrowInfoHovered, setIsGrowInfoHovered] = useState(false);

  useEffect(() => {

    axios.get(`${process.env.REACT_APP_BASE_API}//all-packages`)
      .then(res => {
        console.log(res.data);
        setAllPackages(res.data);
      }).catch(err => console.log(err));
  }, [clientToken])

  //for show success message for payment
  function showSuccessMessage(message) {
    Swal.fire({
      title: "Success!",
      text: message,
      icon: "success",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK",
    });
  }

  //for show error message for payment
  function showErrorMessage(message) {
    Swal.fire({
      title: "Error!",
      text: message,
      icon: "error",
      confirmButtonColor: "#d33",
      confirmButtonText: "OK",
    });
  }

  //for show error message for payment
  function showPackageBuyError(message) {
    Swal.fire({
      title: "Oops!",
      text: message,
      icon: "error",
      confirmButtonColor: "#d33",
      confirmButtonText: "OK",
    });
  }

  useEffect(() => {
    if (loggedInUserData) {
      setclientToken(loggedInUserData.accessToken);
      setLoggedInUserData(null);
    } else if (packageLogInUserData && !clientToken) {
      setclientToken(packageLogInUserData.accessToken);
      setPackageLogInUserData(null)
      if (packageInfo) {
        handleBuy(packageLogInUserData.companyId, packageLogInUserData.accessToken);
        setPackageLogInUserData(null)
      } else if (serviceInfo) {
        handleBuyService(packageLogInUserData.companyId, packageLogInUserData.accessToken)
        setPackageLogInUserData(null)
      } else if (valueAddedServiceInfo) {
        handleBuyValueAddedService(packageLogInUserData.companyId, packageLogInUserData.accessToken)
        setPackageLogInUserData(null)
      }
    } else {

      setclientToken(JSON.parse(localStorage.getItem("clientToken")));
    }
  }, [clientToken, loggedInUserData, packageLogInUserData]);

  useEffect(() => {
    if (clientToken) {
      const fetchData = async () => {
        try {
          const user = await getProtectedData(clientToken);
          console.log(user);
          setEmployeeId(user.id);
        } catch (error) {
          console.log(error);
          window.location.href = `${process.env.REACT_APP_WEBSITE_BASE_URL}/client-login`
        }
      };

      fetchData();
    }
  }, [clientToken]);

  const getLoginClientDetail = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_API}/client/${employeeId}`,
        {
          headers: {
            Authorization: `Bearer ${clientToken}`,
            Accept: "application/json",
          },
        }
      );
      const result = res.data;
      if (!result.error) {
        console.log(result);
        setLoginClientDetail(result);
      } else {
        console.log(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (employeeId) {
      getLoginClientDetail();
    }
  }, [employeeId]);

  useEffect(() => {
    if (loginClientDetail?.companyId) {
      getClientChoosenPlan(loginClientDetail?.companyId, clientToken);
    }
  }, [loginClientDetail?.companyId]);

  useEffect(() => {
    if (packageSelectionDetail) {
      console.log(packageSelectionDetail);
      setCurrentPackage(packageSelectionDetail);
    }
  }, [packageSelectionDetail]);

  const handleBuying = (packageType, cvViews, logins, activeJobs, validity, amount, realPrice, offerPrice, GST, GSTAmount) => {
    setPackageInfo({
      // id: loginClientDetail?.companyId,
      packageType,
      logins,
      cvViews,
      activeJobs,
      validity,
      amount,
      realPrice,
      offerPrice,
      GST,
      GSTAmount
    });

    switchTab(2);
    setValueAddedServiceInfo();
    setServiceInfo();

    window.scrollTo({
      top: 300,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (paymentSucess) {
      switchTab(3);
    }
  }, [paymentSucess])



  const handleBuy = (companyId, token) => {
    setsearchLoading(true);
    axios
      .post(`${process.env.REACT_APP_BASE_API}/client-package-plan`, { ...packageInfo, id: companyId }, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {

        console.log(res.data);

        const detail = {
          amount: parseFloat(res.data.amount.replace(/[^\d.]/g, '')),
          name: `${res.data.packageType} Plan`,
          description: "Skillety's Pick Your Plan",
          image: `${res.data.packageType === 'Test' ? "../assets/img/packages/test.png" :
            res.data.packageType === 'Start' ? "../assets/img/packages/Starter.png" :
              res.data.packageType === 'Scale' ? "../assets/img/packages/Professional.png" :
                res.data.packageType === 'Grow' ? "../assets/img/packages/premium.png" :
                  "../assets/img/packages/package.png"}`,
          token: token,
          mongooseId: res.data._id,
          packageType: "Main"
        }

        handlePayment(detail, redirectURL);

        // showSuccessMessage(
        //   `Thank you for choosing the ${packageInfo?.packageType} Package. Welcome aboard!`
        // );

        // if (activeTab === 2) {
        //   switchTab(3);
        // }

        // setTimeout(() => {
        //   window.location.reload();
        // }, 3000)

      })
      .catch((err) => {
        console.log(err);
        setsearchLoading(false);
        showErrorMessage(err.response.data.error);
      });



    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleBuyService = (companyId, token) => {
    setsearchLoading(true);
    axios
      .post(`${process.env.REACT_APP_BASE_API}/client-skillety-service`, { ...serviceInfo, id: companyId }, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);

        const detail = {
          amount: parseFloat(res.data.finalAmount.replace(/[^\d.]/g, '')),
          name: `${res.data.serviceNames.join(", ")} Services`,
          description: "Skillety's Customize Your Package",
          image: `https://i.ibb.co/bPpycTB/skillety-logo.png`,
          token: token,
          mongooseId: res.data._id,
          packageType: "Customized"
        }

        handlePayment(detail, redirectURL);

        // switchTab(3);
        // showSuccessMessage(
        //   `Your Payment is Successful. Welcome aboard!`
        // );

        // setTimeout(() => {
        //   switchServiceTab(3);
        //   // window.location.reload();
        // }, 3000)

      })
      .catch((err) => {
        console.log(err);
        setsearchLoading(false);
        showErrorMessage(err.response.data.error);
      });
  };

  const handleBuyValueAddedService = (companyId, token) => {
    setsearchLoading(true);
    axios
      .post(`${process.env.REACT_APP_BASE_API}/client-skillety-value-added-service`, { ...valueAddedServiceInfo, id: companyId }, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);

        const detail = {
          amount: parseFloat(res.data.finalAmount.replace(/[^\d.]/g, '')),
          name: `${res.data.serviceName} Service`,
          description: "Skillety's Value Added Service",
          image: `https://i.ibb.co/bPpycTB/skillety-logo.png`,
          token: token,
          mongooseId: res.data._id,
          packageType: "Value_Added"
        }

        handlePayment(detail, redirectURL);

        // switchTab(3);

        // showSuccessMessage(
        //   `Thank you for choosing the ${valueAddedServiceInfo?.serviceName}. Welcome aboard!`
        // );

        setTimeout(() => {
          switchValServiceTab(3);
          // window.location.reload();
        }, 3000)

      })
      .catch((err) => {
        console.log(err);
        setsearchLoading(false);
        showErrorMessage(err.response.data.error);
      });
  };

  useEffect(() => {
    if (pageFrom) {
      setRedirectURL(pageFrom);
    }
  }, [pageFrom]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        userProject: 'skillity',
        page: `packages`
      }
    });
  }, []);


  return (
    <>
      <Layout />
      <div>
        <div className='plans--section'>
          <div className='container-fluid'>
            <div className='container-fluid container-section'>
              <div className="custom--container">
                <div className="breadcrumb--area-dark" data-aos="fade-down">
                  <div className="breadcrumb--item-dark">
                    <a href="/client-home">Home</a>
                  </div>
                  <div className="breadcrumb--item-dark">
                    <p>Plans</p>
                  </div>
                </div>

                <div className="plans--container">
                  <div className="plans--head-area">
                    <h4 className='plans--heading' data-aos="fade-down">PICK YOUR PLAN</h4>
                    {packageCantBuy &&
                      <div className="package-alert-area" data-aos="fade">
                        <p>
                          Hi, We’re configuring our Payment Gateway right now. This will take a couple of days. Meanwhile, you can register with us here, so that we can reach out to you as soon as we’re ready.
                        </p>
                        {!clientToken &&
                          <div className="package-alert-register-btn-area">
                            <a href='/client-register' className='package-alert-register-btn'>Register with us</a>
                          </div>
                        }
                      </div>
                    }

                    <div className="plan--purchase-track-area">
                      <div className="pl--track-circle-area">
                        <div className={`pl--track-circle ${activeTab === 1 || activeTab === 2 || activeTab === 3 ? 'active' : ''}`}>
                          <span>1</span>
                        </div>
                        <h6 className="pl--track-status">Pick Plan</h6>
                      </div>

                      <div className={`pl--track-line  ${activeTab === 2 || activeTab === 3 ? 'active' : ''}`}></div>

                      <div className="pl--track-circle-area">
                        <div className={`pl--track-circle ${activeTab === 2 || activeTab === 3 ? 'active' : ''}`}>
                          <span>2</span>
                        </div>
                        <h6 className="pl--track-status">Add details</h6>
                      </div>

                      <div className={`pl--track-line  ${activeTab === 3 ? 'active' : ''}`}></div>

                      <div className="pl--track-circle-area">
                        <div className={`pl--track-circle ${activeTab === 3 ? 'active' : ''}`}>
                          <span>3</span>
                        </div>
                        <h6 className="pl--track-status">Pay</h6>
                      </div>
                    </div>
                  </div>

                  <div className="packages--area tab-content">
                    <div className={`plan--detail-area tab ${activeTab === 1 ? 'active-tab' : ''}`} id="tab1">
                      <div className="row package-row">
                        <div className="col-12 col-xl-2 col-lg-2 col-md-2 custom-width" data-aos="fade">
                          <div className="pl--package-title-area">
                            <div className="pl--package-head-area">
                              <img
                                src="../assets/img/packages/Starter.png"
                                className="pl--package-img-hidden"
                                alt=""
                              />
                              <h5 className="pl--package-name-hidden">
                                Quick Buy Packages
                              </h5>

                              <div>
                                <h6 className="pl--package-title">
                                  CV Views
                                </h6>
                              </div>

                              <div>
                                <h6 className="pl--package-title">
                                  Login ID
                                </h6>
                              </div>

                              <div>
                                <h6 className="pl--package-title">
                                  Job Postings
                                </h6>
                              </div>

                              <div>
                                <h6 className="pl--package-title">
                                  Validity in Days
                                </h6>
                              </div>

                              {/* <div>
                                      <h6 className="pl--package-title">
                                        <span className="with-bg">Total</span>
                                        Real Price
                                      </h6>
                                    </div> */}

                              <div>
                                <h6 className="pl--package-title no-wrap-false">
                                  Inaugural Offer Price
                                </h6>
                              </div>

                              {/* <div className="pt-4">
                                <h6 className="pl--package-title">
                                  <span className="with-bg">GST</span>
                                </h6>
                              </div> */}

                              {/* <div>
                                <h6 className="pl--package-title">
                                  <b>Total Amount</b>
                                </h6>
                              </div> */}
                            </div>
                          </div>
                        </div>

                        {allPackages.map((pack) => {
                          return (
                            <div className="col-12 col-xl-2 col-lg-2 col-md-2 custom-width1"
                              key={pack.id} data-aos="fade-left">
                              <div className={(currentPackage?.packageType === pack.packageType) ? "pl--package-detail-area active" : "pl--package-detail-area"}>
                                <div
                                  className={(currentPackage?.packageType === pack.packageType) ? "pl--package-info-area active" : `pl--package-info-area`}
                                >
                                  <img
                                    src={`${pack.packageType === 'Test' ? "../assets/img/packages/test.png" :
                                      pack.packageType === 'Start' ? "../assets/img/packages/Starter.png" :
                                        pack.packageType === 'Scale' ? "../assets/img/packages/Professional.png" :
                                          pack.packageType === 'Grow' ? "../assets/img/packages/premium.png" :
                                            "../assets/img/packages/package.png"}`}
                                    className="pl--package-img"
                                    alt=""
                                  />
                                  <h5 className="pl--package-name">{pack.packageType}</h5>

                                  <div className="pl--package-mobile-flex">
                                    <h6 className="pl--package-mobile-title">
                                      CV Views
                                    </h6>
                                    <h6 className="pl--package-info">
                                      {pack.cvViews}
                                    </h6>
                                  </div>

                                  <div className="pl--package-mobile-flex">
                                    <h6 className="pl--package-mobile-title">
                                      Login ID
                                    </h6>
                                    <h6 className="pl--package-info">
                                      {pack.logins}
                                    </h6>
                                  </div>

                                  <div className="pl--package-mobile-flex">
                                    <h6 className="pl--package-mobile-title">
                                      Active Jobs
                                    </h6>
                                    <h6 className="pl--package-info">{pack.activeJobs}</h6>
                                  </div>

                                  <div className="pl--package-mobile-flex">
                                    <h6 className="pl--package-mobile-title">
                                      Validity in Days
                                    </h6>
                                    <h6 className="pl--package-info">
                                      {pack.validity}
                                    </h6>
                                  </div>

                                  <div className="pl--package-mobile-flex">
                                    <h6 className="pl--package-mobile-title">
                                      Inaugural Offer Price
                                    </h6>
                                    <h6 className="pl--package-info is-price">
                                      <span className="line-through">INR {pack.realPrice}</span><br />
                                      INR {pack.offerPrice}
                                    </h6>
                                  </div>

                                  {/* <div className="pl--package-mobile-flex">
                                    <h6 className="pl--package-mobile-title">
                                      GST
                                    </h6>
                                    <h6 className="pl--package-info">
                                      {pack.GST}%
                                    </h6>
                                  </div> */}

                                  {/* <div className="pl--package-mobile-flex">
                                    <h6 className="pl--package-mobile-title">
                                      <b>Total Amount</b>
                                    </h6>
                                    <h6 className="pl--package-info">
                                      <b>{pack.amount}</b>
                                    </h6>
                                  </div> */}
                                </div>


                                <div className="pl--package-btn-area test-btn-area">
                                  <button
                                    disabled={packageCantBuy}
                                    className="pl--package-btn-sub buy-now"
                                    onClick={() => handleBuying(pack.packageType, pack.cvViews, pack.logins, pack.activeJobs, pack.validity, pack.amount, pack.realPrice, pack.offerPrice, pack.GST, (pack.offerPrice * (pack.GST / 100)).toFixed(2))}
                                  >
                                    <div className="pl--package-btn buy-now">
                                      Buy Now
                                    </div>
                                    <div className="pl--package-arrow-area buy-now pkg">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 27 27"
                                        fill="none"
                                      >
                                        <path
                                          d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832"
                                          stroke="white"
                                          stroke-width="2"
                                        />
                                        <path
                                          d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162"
                                          stroke="white"
                                          stroke-width="2"
                                        />
                                        <path
                                          d="M1 26L25.1667 1"
                                          stroke="white"
                                          stroke-width="2"
                                        />
                                      </svg>
                                    </div>
                                  </button>
                                </div>

                              </div>
                            </div>
                          )
                        })}

                      </div>

                      <div className="sol-price-container mb-0">
                        <div className="sol-price-area">
                          <h5 className="sol-price-heading">
                            DIY (Do It Yourself) Packages
                          </h5>
                          <div className='text-center'>Design your own packages according to your requirement and budget!</div>

                          <div className="table-responsive">
                            <table className="table sol-price-table">
                              <thead>
                                <tr className="sol-price-table-head-row">
                                  <th className="sol-price-table-head text-start">
                                    Services
                                  </th>
                                  <th className="sol-price-table-head text-center">
                                    Select Quantity
                                  </th>
                                  <th className="sol-price-table-head text-center">
                                    Total Price (INR)
                                  </th>
                                  <th className="sol-price-table-head text-center"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr className="sol-price-table-row">
                                  <td className="sol-price-table-data first-data text-start">
                                    CV Views
                                  </td>
                                  <td className="sol-price-table-data text-center sol-price-table-qty-area">
                                    <input
                                      type="number"
                                      className="sol-price-table-qty-input"
                                      value={quantity.cvViews}
                                      onChange={(e) => setQuantity({ ...quantity, cvViews: e.target.value })} />
                                  </td>

                                  <td className="sol-price-table-data price text-center">
                                    {total.cvViews}
                                  </td>
                                  <td className="text-center last-data sol-price-buy-now-btn-area">
                                  </td>
                                </tr>

                                <tr className="sol-price-table-row">
                                  <td className="sol-price-table-data first-data text-start">
                                    Login IDs
                                  </td>
                                  <td className="sol-price-table-data text-center sol-price-table-qty-area">
                                    <input
                                      type="number"
                                      className="sol-price-table-qty-input"
                                      value={quantity.logins}
                                      onChange={(e) => setQuantity({ ...quantity, logins: e.target.value })} />
                                  </td>

                                  <td className="sol-price-table-data price text-center">
                                    {total.logins}
                                  </td>
                                  <td className="text-center last-data sol-price-buy-now-btn-area">
                                  </td>
                                </tr>

                                <tr className="sol-price-table-row">
                                  <td className="sol-price-table-data first-data text-start">
                                    Job Postings
                                  </td>
                                  <td className="sol-price-table-data text-center sol-price-table-qty-area">
                                    <input
                                      type="number"
                                      className="sol-price-table-qty-input"
                                      value={quantity.activeJobs}
                                      onChange={(e) => setQuantity({ ...quantity, activeJobs: e.target.value })} />
                                  </td>

                                  <td className="sol-price-table-data price text-center">
                                    {total.activeJobs}
                                  </td>
                                  <td className="text-center last-data sol-price-buy-now-btn-area">
                                  </td>
                                </tr>

                                <tr className="sol-price-table-row">
                                  <td className="sol-price-table-data first-data text-start">
                                    Validity (In months)
                                  </td>
                                  <td className="sol-price-table-data text-center sol-price-table-qty-area">
                                    <select className="sol-price-table-qty-input"
                                      value={validity}
                                      onChange={(e) => setValidity(e.target.value)}>
                                      <option value="1" selected>1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="8">8</option>
                                      <option value="9">9</option>
                                      <option value="10">10</option>
                                      <option value="11">11</option>
                                      <option value="12">12</option>
                                    </select>
                                  </td>

                                  <td className="sol-price-table-data price text-center">
                                    {total.cvViews + total.logins + total.activeJobs}
                                  </td>

                                  <td className="text-center last-data sol-price-buy-now-btn-area">
                                    <button className="sol-price-buy-now-btn" disabled={packageCantBuy}
                                      onClick={handleServiceBuyNowClick}>
                                      <div className="sol-price-buy-now-btn-sub">
                                        Buy Now
                                      </div>
                                      <div className="sol-price-buy-now-arrow-area">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 27 27"
                                          fill="none"
                                        >
                                          <path
                                            d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832"
                                            stroke="#714F36"
                                            stroke-width="2"
                                          />
                                          <path
                                            d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162"
                                            stroke="#714F36"
                                            stroke-width="2"
                                          />
                                          <path
                                            d="M1 26L25.1667 1"
                                            stroke="#714F36"
                                            stroke-width="2"
                                          />
                                        </svg>
                                      </div>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="sol-price-area for-mobile">
                          <h5 className="sol-price-heading">
                            SKILLETY VALUE ADDED SERVICES PRICES
                          </h5>

                          <div className="table-responsive">
                            <table className="table sol-price-table">
                              <thead>
                                <tr className="sol-price-table-head-row">
                                  <th className="sol-price-table-head text-start">
                                    Value Added Services
                                  </th>
                                  <th className="sol-price-table-head text-center">
                                    Select Quantity
                                  </th>
                                  <th className="sol-price-table-head text-center">
                                    Total Price (INR)
                                  </th>
                                  <th className="sol-price-table-head text-center"></th>
                                </tr>
                              </thead>

                              <tbody>
                                <tr className="sol-price-table-row">
                                  <td className="sol-price-table-data first-data text-start">
                                    Online Technical Assessment
                                  </td>
                                  <td className="sol-price-table-data text-center sol-price-table-qty-area">
                                    <input
                                      type="number"
                                      className="sol-price-table-qty-input"
                                      value={quantitySVAS.OnlineTechnicalAssessment}
                                      onChange={(e) => setQuantitySVAS({ ...quantitySVAS, OnlineTechnicalAssessment: e.target.value })} />
                                  </td>
                                  <td className="sol-price-table-data price text-center">
                                    {totalSVAS.OnlineTechnicalAssessment}
                                  </td>
                                  <td className="text-center last-data sol-price-buy-now-btn-area">
                                    <button className="sol-price-buy-now-btn" disabled
                                      onClick={() => handleValServiceBuyNowClick("OnlineTechnicalAssessment", quantitySVAS.OnlineTechnicalAssessment)}>
                                      <div className="sol-price-buy-now-btn-sub">
                                        Coming soon
                                      </div>
                                      <div className="sol-price-buy-now-arrow-area">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 27 27"
                                          fill="none"
                                        >
                                          <path
                                            d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832"
                                            stroke="#714F36"
                                            stroke-width="2"
                                          />
                                          <path
                                            d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162"
                                            stroke="#714F36"
                                            stroke-width="2"
                                          />
                                          <path
                                            d="M1 26L25.1667 1"
                                            stroke="#714F36"
                                            stroke-width="2"
                                          />
                                        </svg>
                                      </div>
                                    </button>
                                  </td>
                                </tr>

                                <tr className="sol-price-table-row">
                                  <td className="sol-price-table-data first-data text-start">
                                    L1 Interview (0-8 yrs)
                                  </td>
                                  <td className="sol-price-table-data text-center sol-price-table-qty-area">
                                    <input
                                      type="number"
                                      className="sol-price-table-qty-input"
                                      value={quantitySVAS.L1Interview_0to8yrs}
                                      onChange={(e) => setQuantitySVAS({ ...quantitySVAS, L1Interview_0to8yrs: e.target.value })} />
                                  </td>
                                  <td className="sol-price-table-data price text-center">
                                    {totalSVAS.L1Interview_0to8yrs}
                                  </td>
                                  <td className="text-center last-data sol-price-buy-now-btn-area">
                                    <button className="sol-price-buy-now-btn" disabled
                                      onClick={() => handleValServiceBuyNowClick("L1Interview(0to8yrs)", quantitySVAS.L1Interview_0to8yrs)}>
                                      <div className="sol-price-buy-now-btn-sub">
                                        Coming soon
                                      </div>
                                      <div className="sol-price-buy-now-arrow-area">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 27 27"
                                          fill="none"
                                        >
                                          <path
                                            d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832"
                                            stroke="#714F36"
                                            stroke-width="2"
                                          />
                                          <path
                                            d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162"
                                            stroke="#714F36"
                                            stroke-width="2"
                                          />
                                          <path
                                            d="M1 26L25.1667 1"
                                            stroke="#714F36"
                                            stroke-width="2"
                                          />
                                        </svg>
                                      </div>
                                    </button>
                                  </td>
                                </tr>

                                <tr className="sol-price-table-row">
                                  <td className="sol-price-table-data first-data text-start">
                                    L1 Interview (8-15 yrs)
                                  </td>
                                  <td className="sol-price-table-data text-center sol-price-table-qty-area">
                                    <input
                                      type="number"
                                      className="sol-price-table-qty-input"
                                      value={quantitySVAS.L1Interview_8to15yrs}
                                      onChange={(e) => setQuantitySVAS({ ...quantitySVAS, L1Interview_8to15yrs: e.target.value })} />
                                  </td>
                                  <td className="sol-price-table-data price text-center">
                                    {totalSVAS.L1Interview_8to15yrs}
                                  </td>
                                  <td className="text-center last-data sol-price-buy-now-btn-area">
                                    <button className="sol-price-buy-now-btn" disabled
                                      onClick={() => handleValServiceBuyNowClick("L1Interview(8to15yrs)", quantitySVAS.L1Interview_8to15yrs)}>
                                      <div className="sol-price-buy-now-btn-sub">
                                        Coming soon
                                      </div>
                                      <div className="sol-price-buy-now-arrow-area">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 27 27"
                                          fill="none"
                                        >
                                          <path
                                            d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832"
                                            stroke="#714F36"
                                            stroke-width="2"
                                          />
                                          <path
                                            d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162"
                                            stroke="#714F36"
                                            stroke-width="2"
                                          />
                                          <path
                                            d="M1 26L25.1667 1"
                                            stroke="#714F36"
                                            stroke-width="2"
                                          />
                                        </svg>
                                      </div>
                                    </button>
                                  </td>
                                </tr>

                                <tr className="sol-price-table-row">
                                  <td className="sol-price-table-data first-data text-start">
                                    L1 Interview (above 15 yrs)
                                  </td>
                                  <td className="sol-price-table-data text-center sol-price-table-qty-area">
                                    <input
                                      type="number"
                                      className="sol-price-table-qty-input"
                                      value={quantitySVAS.L1Interview_above15yrs}
                                      onChange={(e) => setQuantitySVAS({ ...quantitySVAS, L1Interview_above15yrs: e.target.value })} />
                                  </td>
                                  <td className="sol-price-table-data price text-center">
                                    {totalSVAS.L1Interview_above15yrs}
                                  </td>
                                  <td className="text-center last-data sol-price-buy-now-btn-area">
                                    <button className="sol-price-buy-now-btn" disabled
                                      onClick={() => handleValServiceBuyNowClick("L1Interview(>15yrs)", quantitySVAS.L1Interview_above15yrs)}>
                                      <div className="sol-price-buy-now-btn-sub">
                                        Coming soon
                                      </div>
                                      <div className="sol-price-buy-now-arrow-area">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 27 27"
                                          fill="none"
                                        >
                                          <path
                                            d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832"
                                            stroke="#714F36"
                                            stroke-width="2"
                                          />
                                          <path
                                            d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162"
                                            stroke="#714F36"
                                            stroke-width="2"
                                          />
                                          <path
                                            d="M1 26L25.1667 1"
                                            stroke="#714F36"
                                            stroke-width="2"
                                          />
                                        </svg>
                                      </div>
                                    </button>
                                  </td>
                                </tr>

                                <tr className="sol-price-table-row">
                                  <td className="sol-price-table-data first-data text-start">
                                    BGV Comprehensive
                                  </td>
                                  <td className="sol-price-table-data text-center sol-price-table-qty-area">
                                    <input
                                      type="number"
                                      className="sol-price-table-qty-input"
                                      value={quantitySVAS.BGVComprehensive}
                                      onChange={(e) => setQuantitySVAS({ ...quantitySVAS, BGVComprehensive: e.target.value })} />
                                  </td>
                                  <td className="sol-price-table-data price text-center">
                                    {totalSVAS.BGVComprehensive}
                                  </td>
                                  <td className="text-center last-data sol-price-buy-now-btn-area">
                                    <button className="sol-price-buy-now-btn" disabled
                                      onClick={() => handleValServiceBuyNowClick("BGVComprehensive", quantitySVAS.BGVComprehensive)}>
                                      <div className="sol-price-buy-now-btn-sub">
                                        Coming soon
                                      </div>
                                      <div className="sol-price-buy-now-arrow-area">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 27 27"
                                          fill="none"
                                        >
                                          <path
                                            d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832"
                                            stroke="#714F36"
                                            stroke-width="2"
                                          />
                                          <path
                                            d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162"
                                            stroke="#714F36"
                                            stroke-width="2"
                                          />
                                          <path
                                            d="M1 26L25.1667 1"
                                            stroke="#714F36"
                                            stroke-width="2"
                                          />
                                        </svg>
                                      </div>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <h6 className="sol-price-information">
                            Beyond this, please contact the Sales department.
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div className={`pl--add-detail-area tab ${activeTab === 2 ? 'active-tab' : ''}`} id="tab2">
                      <div className="pl-package-detail-section">
                        <div className="pl-package-detail-head mt-5">
                          Package Detail
                        </div>
                        <div className="row w-100">
                          <div className={`col-12 ${!clientToken ? 'col-xxl-4 offset-xxl-1 col-xl-5 offset-xl-0 col-lg-5 offset-lg-0 col-md-12' : 'col-md-10 offset-md-1 col-lg-6 offset-lg-3 col-xl-6 col-xxl-4 offset-xxl-4 offset-xl-3'} col-md-6`}>
                            {packageInfo &&
                              <div className="pl-package-detail-area">
                                <div className="row pl-package-row">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      Package Name
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-view-area">
                                      <div className="pl-package-detail-title">
                                        {packageInfo?.packageType}
                                      </div>
                                      {/* <button
                                        type="button"
                                        className="pl-package-detail-view-btn"
                                        title="View More Detail.."
                                        data-bs-toggle="modal"
                                        data-bs-target="#package_detail_modal"
                                      >
                                        <i class="bi bi-eye-fill"></i>
                                      </button> */}
                                    </div>
                                  </div>
                                </div>

                                <div className="row pl-package-row">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      Package Includes
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-title bullet d-flex justify-content-between">
                                      <span className='pkg-incl-head'>CV Views :</span> <span className='pkg-incl'>{packageInfo?.cvViews}</span>
                                    </div>
                                    <div className="pl-package-detail-title bullet d-flex justify-content-between pt-2">
                                      <span className='pkg-incl-head'>Login ID :</span> <span className='pkg-incl'>{packageInfo?.logins}</span>
                                    </div>
                                    <div className="pl-package-detail-title bullet d-flex justify-content-between pt-2">
                                      <span className='pkg-incl-head'>Job Postings :</span> <span className='pkg-incl'>{packageInfo?.activeJobs}</span>
                                    </div>
                                  </div>
                                </div>

                                <div className="row pl-package-row">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      Validity in Days
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      {packageInfo?.validity}
                                    </div>
                                  </div>
                                </div>

                                <div className="row pl-package-row">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      Real Price
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-title line-through">
                                      {packageInfo?.realPrice}
                                    </div>
                                  </div>
                                </div>

                                <div className="row pl-package-row">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      Inaugural Offer Price
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      {packageInfo?.offerPrice}
                                    </div>
                                  </div>
                                </div>

                                <div className="row pl-package-row no-border">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      GST - {packageInfo?.GST}%
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      INR {packageInfo?.GSTAmount}
                                    </div>
                                  </div>
                                </div>

                                <div className="row pl-package-row bottom">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      Total Amount
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      {packageInfo?.amount}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }

                            {serviceInfo &&
                              <div className="pl-package-detail-area">
                                <div className="row pl-package-row">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      Package Name
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      DIY Package
                                    </div>
                                  </div>
                                </div>

                                <div className="row pl-package-row">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      Package Includes
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      {/* {serviceInfo?.serviceNames.join(", ")} */}
                                      <div className='w-100'>

                                        {serviceInfo.quantities.cvViews !== 0 &&
                                          <div className="pl-package-detail-title bullet d-flex justify-content-between pb-1">
                                            <span className='pkg-incl-head'>CV Views :</span> <span className='pkg-incl'>{serviceInfo.quantities.cvViews}</span>
                                          </div>
                                        }

                                        {serviceInfo.quantities.logins !== 0 &&
                                          <div className="pl-package-detail-title bullet d-flex justify-content-between pb-1">
                                            <span className='pkg-incl-head'>Login IDs :</span> <span className='pkg-incl'>{serviceInfo.quantities.logins}</span>
                                          </div>
                                        }

                                        {serviceInfo.quantities.activeJobs !== 0 &&
                                          <div className="pl-package-detail-title bullet d-flex justify-content-between pb-1">
                                            <span className='pkg-incl-head'>Job Postings:</span> <span className='pkg-incl'>{serviceInfo.quantities.activeJobs}</span>
                                          </div>
                                        }

                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row pl-package-row">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      Validity (In months)
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      {serviceInfo?.validity}
                                    </div>
                                  </div>
                                </div>


                                <div className="row pl-package-row">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      Service Price
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-title line-through">
                                      INR {serviceInfo?.servicePrice}
                                    </div>
                                  </div>
                                </div>

                                <div className="row pl-package-row">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      Discount
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      INR {serviceInfo?.discountAmount}
                                    </div>
                                  </div>
                                </div>

                                <div className="row pl-package-row">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      Final Price
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      INR {parseInt(serviceInfo?.servicePrice) - parseInt(serviceInfo?.discountAmount)}
                                    </div>
                                  </div>
                                </div>

                                <div className="row pl-package-row no-border">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      GST - {serviceInfo?.GST}%
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      INR {serviceInfo?.GSTAmount}
                                    </div>
                                  </div>
                                </div>

                                <div className="row pl-package-row bottom">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      Total Amount
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      INR {serviceInfo?.finalAmount}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }

                            {valueAddedServiceInfo &&
                              <div className="pl-package-detail-area">
                                <div className="row pl-package-row">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      Service
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-view-area">
                                      <div className="pl-package-detail-title">
                                        {valueAddedServiceInfo?.serviceName}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row pl-package-row">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      Service Price
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-title line-through">
                                      INR {valueAddedServiceInfo?.servicePrice}
                                    </div>
                                  </div>
                                </div>

                                <div className="row pl-package-row no-border">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      GST - {valueAddedServiceInfo?.GST}%
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      INR {valueAddedServiceInfo?.GSTAmount}
                                    </div>
                                  </div>
                                </div>

                                <div className="row pl-package-row bottom">
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      Total Amount
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="pl-package-detail-title">
                                      INR {valueAddedServiceInfo?.finalAmount}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>

                          {!clientToken &&
                            <div className="col-12 col-xxl-6 offset-xxl-1 col-xl-7 offset-xl-0 col-lg-7 offset-lg-0 col-md-12 mt-5 mt-lg-0">
                              <div className="pl-package-account-area">
                                <ul class="nav nav-pills mb-3 align-items-end justify-content-between custom-row-gap" id="pills-tab" role="tablist">
                                  <li class="nav-item" role="presentation">
                                    <button class="nav-link pl-pac-acc-nav-link active" id="create-account-tab" data-bs-toggle="pill" data-bs-target="#create-account" type="button" role="tab" aria-controls="create-account" aria-selected="true">Quick Register</button>
                                  </li>
                                  {/* <li className='nav-item ps-2'><p className='mb-0'>Already have an account?</p></li> */}
                                  <li class="nav-item d-flex " role="presentation">
                                    <p className='mb-0'>Already have an account?</p>
                                    <button class="nav-link is-login-toggle" id="login-tab" data-bs-toggle="pill" data-bs-target="#login" role="tab" aria-controls="login" aria-selected="false">Login</button>
                                  </li>
                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                  <hr />
                                  <div class="tab-pane fade show active" id="create-account" role="tabpanel" aria-labelledby="create-account-tab">
                                    <form action=""
                                      onSubmit={handleRegister}>
                                      <div className="row">
                                        <div className="col-12 col-md-6">
                                          <div className="mb-3">
                                            <label for="first_name" className="form-label pl-acc-form-label">Full Name <span className='form-required'>*</span></label>
                                            <input type="text" className="form-control pl-acc-form-input" id="first_name" placeholder="Enter your full name" required
                                              name='fullName'
                                              value={clientDetail.fullName}
                                              onChange={handleInput} />
                                          </div>
                                        </div>
                                        {/* <div className="col-12 col-md-6">
                                          <div className="mb-3">
                                            <label for="last_name" className="form-label pl-acc-form-label">Last Name <span className='form-required'>*</span></label>
                                            <input type="text" className="form-control pl-acc-form-input" id="last_name" placeholder="Enter your last name" required 
                                            name='lastName'
                                            value={clientDetail.lastName}
                                            onChange={handleInput}/>
                                          </div>
                                        </div> */}
                                        <div className="col-12 col-md-6">
                                          <div className="mb-3">
                                            <label for="email" className="form-label pl-acc-form-label">Email <span className='form-required'>*</span></label>
                                            <input type="email" className="form-control pl-acc-form-input" id="email" placeholder="Enter your email" required
                                              name='email'
                                              value={clientDetail.email}
                                              onChange={handleInput} />
                                            <small className='form-required'>{(!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(clientDetail.email)) && clientDetail.email) ? "Enter valid email" : ""}</small>
                                            <small className='form-required'>{(checkValueResult.email && clientDetail.email) ? "Email already exists" : ""}</small>
                                          </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                          <div className="mb-3">
                                            <label for="mobile" className="form-label pl-acc-form-label">Mobile <span className='form-required'>*</span></label>
                                            <PhoneInput
                                              placeholder="Enter your mobile number"
                                              value={clientDetail.mobile?clientDetail.mobile.toString():''}
                                              defaultCountry="IN"
                                              className='pl-acc-form-input-mobile'
                                              required
                                              name="mobile"
                                              id='mobile'
                                              onChange={(value) => handlePhoneNumberChange(value)} />
                                            {/* <input type="number" className="form-control pl-acc-form-input" id="mobile" placeholder="Enter your mobile number" required
                                              name='mobile'
                                              value={clientDetail.mobile}
                                              onChange={handleInput} /> */}
                                            {!isPhoneValid &&
                                              <small className='form-required'>Enter valid phone number</small>
                                            }
                                            <small className='form-required'>{(checkValueResult.mobile && clientDetail.mobile) ? "Phone Number already exists" : ""}</small>
                                          </div>
                                        </div>
                                        {/* <div className="col-12 col-md-6">
                                          <div className="mb-3">
                                            <label for="company_name" className="form-label pl-acc-form-label">Company Name (Optional)</label>
                                            <input type="text" className="form-control pl-acc-form-input" id="company_name" placeholder="Enter your company name" 
                                            name='companyName'
                                            value={clientDetail.companyName}
                                            onChange={handleInput}/>
                                          </div>
                                        </div> */}
                                        <div className="col-12 col-md-6">
                                          <div className="mb-3">
                                            <label for="gstin" className="form-label pl-acc-form-label">GSTIN (if available)</label>
                                            <input type="text" className="form-control pl-acc-form-input" id="gstin" placeholder="Enter your GSTIN"
                                              name='GSTIN'
                                              value={clientDetail.GSTIN}
                                              onChange={handleInput} />
                                          </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                          <div className="mb-3">
                                            <label for="password" className="form-label pl-acc-form-label">Password <span className='form-required'>*</span></label>
                                            <div className='position-relative'>
                                              <input type={showPassword ? 'text' : 'password'} className="form-control pl-acc-form-input" id="password" placeholder="Enter the password" required
                                                name='password'
                                                value={clientDetail.password}
                                                onChange={handleInput} />
                                              <i className={`show-password-icon bi ${showPassword ? 'bi-eye-fill' : 'bi-eye-slash-fill'}`} onClick={handleTogglePassword}></i>
                                            </div>
                                            <small className='form-required'>{(clientDetail.password.length < 8 && clientDetail.password) ? "Password must be atleast 8 characters long" : ""}</small>
                                          </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                          <div className="mb-3">
                                            <label for="confirm_password" className="form-label pl-acc-form-label">Confirm Password <span className='form-required'>*</span></label>
                                            <div className='position-relative'>
                                              <input type={showConfirmPassword ? 'text' : 'password'} className="form-control pl-acc-form-input" id="confirm_password" placeholder="Confirm the password" required
                                                name='confirmPassword'
                                                value={clientDetail.confirmPassword}
                                                onChange={handleInput} />
                                              <i className={`show-password-icon bi ${showConfirmPassword ? 'bi-eye-fill' : 'bi-eye-slash-fill'}`} onClick={handleToggleConfirmPassword}></i>
                                            </div>
                                            <small className='form-required'>{(clientDetail.password !== clientDetail.confirmPassword && clientDetail.confirmPassword) ? "Password & confirm password must be equal" : ""}</small>
                                          </div>
                                        </div>
                                        <div className="col-12">
                                          <div className="text-right mt-3">
                                            <button type='submit' className='btn btn-login'>Register & Checkout</button>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                  <div class="tab-pane fade" id="login" role="tabpanel" aria-labelledby="login-tab">
                                    <form action=""
                                      onSubmit={handleLogin}>
                                      <div className="row">
                                        <div className="col-12 col-lg-6">
                                          <div className="mb-3">
                                            <label for="email" className="form-label pl-acc-form-label">UserId <span className='form-required'>*</span></label>
                                            <input type="text" className="form-control pl-acc-form-input" id="email" placeholder="Enter your email or phone number" required
                                              name='userId'
                                              value={loginCredentials.userId}
                                              onChange={handleInputLogin} />
                                            <small className='text-danger'>{(clientInActiveStatus && loginCredentials.userId) ? "Your account is freezed!. Please contact Skillety support for more details." : ""}</small>
                                          </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                          <div className="mb-3">
                                            <label for="password" className="form-label pl-acc-form-label">Password <span className='form-required'>*</span></label>
                                            <div className='position-relative'>
                                              <input type={showLoginPassword ? 'text' : 'password'} className="form-control pl-acc-form-input" id="password" placeholder="Enter the password" required
                                                name='password'
                                                value={loginCredentials.password}
                                                onChange={handleInputLogin} />
                                              <i className={`show-password-icon bi ${showLoginPassword ? 'bi-eye-fill' : 'bi-eye-slash-fill'}`} onClick={handleToggleLoginPassword}></i>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-12">
                                          <div className="text-right mt-3">
                                            {errorMsg ?
                                              <p className='log-error-msg'>{errorMsg && errorMsg}</p>
                                              : null
                                            }
                                            <button type='submit' className='btn btn-login'>
                                              Login & Checkout
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      <div className="pl--package-btn-area">
                        <button className="pl--package-btn-sub previous" onClick={handlePreviousClick}>
                          <div className="pl--package-arrow-area buy-now prev custom-mobile-d-none">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 27 27"
                              fill="none"
                            >
                              <path
                                d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832"
                                stroke="white"
                                stroke-width="2"
                              />
                              <path
                                d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162"
                                stroke="white"
                                stroke-width="2"
                              />
                              <path
                                d="M1 26L25.1667 1"
                                stroke="white"
                                stroke-width="2"
                              />
                            </svg>
                          </div>
                          <div className="pl--package-btn buy-now"
                            onClick={() => {
                              setPackageInfo()

                            }}>
                            Previous
                          </div>
                        </button>

                        {(packageInfo && !loggedInUserData && !packageLogInUserData && clientToken) &&
                          <button
                            className="pl--package-btn-sub next"
                            onClick={() => handleBuy(loginClientDetail?.companyId, clientToken)}
                          // disabled={!clientToken}
                          >
                            <div className="pl--package-btn buy-now">
                              Proceed to Pay
                            </div>
                            <div className="pl--package-arrow-area buy-now custom-mobile-d-none">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 27 27"
                                fill="none"
                              >
                                <path
                                  d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832"
                                  stroke="white"
                                  stroke-width="2"
                                />
                                <path
                                  d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162"
                                  stroke="white"
                                  stroke-width="2"
                                />
                                <path
                                  d="M1 26L25.1667 1"
                                  stroke="white"
                                  stroke-width="2"
                                />
                              </svg>
                            </div>
                          </button>
                        }

                        {(serviceInfo && !loggedInUserData && !packageLogInUserData && clientToken) &&
                          <button
                            className="pl--package-btn-sub next"
                            onClick={() => handleServicePayClick(loginClientDetail?.companyId, clientToken)}
                          // disabled={!clientToken}
                          >
                            <div className="pl--package-btn buy-now">
                              Proceed to Pay
                            </div>
                            <div className="pl--package-arrow-area buy-now custom-mobile-d-none">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 27 27"
                                fill="none"
                              >
                                <path
                                  d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832"
                                  stroke="white"
                                  stroke-width="2"
                                />
                                <path
                                  d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162"
                                  stroke="white"
                                  stroke-width="2"
                                />
                                <path
                                  d="M1 26L25.1667 1"
                                  stroke="white"
                                  stroke-width="2"
                                />
                              </svg>
                            </div>
                          </button>
                        }

                        {(valueAddedServiceInfo && !loggedInUserData && !packageLogInUserData && clientToken) &&
                          <button
                            className="pl--package-btn-sub next"
                            onClick={() => handleValServicePayClick(loginClientDetail?.companyId, clientToken)}
                          // disabled={!clientToken}
                          >
                            <div className="pl--package-btn buy-now">
                              Proceed to Pay
                            </div>
                            <div className="pl--package-arrow-area buy-now custom-mobile-d-none">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 27 27"
                                fill="none"
                              >
                                <path
                                  d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832"
                                  stroke="white"
                                  stroke-width="2"
                                />
                                <path
                                  d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162"
                                  stroke="white"
                                  stroke-width="2"
                                />
                                <path
                                  d="M1 26L25.1667 1"
                                  stroke="white"
                                  stroke-width="2"
                                />
                              </svg>
                            </div>
                          </button>
                        }
                      </div>
                    </div>

                    {paymentSucess && <div className={`pl--pay-area tab ${activeTab === 3 ? 'active-tab' : ''}`} id="tab3">
                      <div className="pl--payment-section">
                        {/* if payment was success */}
                        <div className="pl-payment-area success">
                          <img
                            src="assets/img/packages/payment-success.png"
                            className="pl-payment-img"
                            alt=""
                          />
                          <div className="pl-payment-message success">
                            Payment Success..!
                          </div>
                        </div>
                      </div>
                      <div className="pl--package-btn-area">
                        <button className="pl--package-btn-sub" onClick={handleDoneclick}>
                          <div className="pl--package-btn buy-now">
                            Done
                          </div>
                        </button>
                      </div>
                    </div>}
                  </div>

                  <div className="sol-price-container">
                    <div className="term-con-area pb-0">
                      <p className="term-con">
                        By clicking on the Buy Now you are agreeing to
                        the&nbsp;
                        <a href="/terms-and-conditions" target="_blank">Terms & Conditions</a>
                      </p>

                      {/* <p className="term-con mt-4 font-14">
                        We understand that one size doesn't fit all. So, we
                        have designed our Packages based on your journey
                        level. Please choose any package that fits your needs.
                        If you want a Customised package, please talk to our
                        Sales guys, who would be happy to help.{" "}
                      </p> */}
                    </div>
                  </div>

                  {/* package detail modal here */}
                  <div
                    className="modal fade"
                    id="package_detail_modal"
                    tabindex="-1"
                    aria-labelledby="package_detail_modal"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content pl-pack-det-modal">
                        <div className="modal-header pl-pack-det-modal-header">
                          <h5
                            className="modal-title pl-pack-det-modal-head"
                            id="exampleModalLabel"
                          >
                            Package : <span>{packageInfo?.packageType}</span>
                          </h5>
                          <button type="button" class="btn-close text-danger" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pl-pack-det-modal-body">
                          <div className="row pr-3 pl-3">
                            <div className="table-responsive pl-pack-det-table-area">
                              <table className="table table-bordered pl-pack-det-table">
                                <thead>
                                  <tr className="pl-pack-det-table-row-head">
                                    <th>Includes</th>
                                    <th className="text-center">Quantity</th>
                                    {/* <th className="text-right">
                                        Price (Rs.)
                                      </th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="pl-pack-det-table-row">
                                    <td>CV Views</td>
                                    <td className="text-center">{packageInfo?.cvViews}</td>
                                    {/* <td className="text-right">20,000</td> */}
                                  </tr>
                                  <tr className="pl-pack-det-table-row">
                                    <td>Login ID</td>
                                    <td className="text-center">{packageInfo?.logins}</td>
                                    {/* <td className="text-right">300</td> */}
                                  </tr>
                                  <tr className="pl-pack-det-table-row">
                                    <td>Active Jobs</td>
                                    <td className="text-center">{packageInfo?.activeJobs}</td>
                                    {/* <td className="text-right">2,500</td> */}
                                  </tr>
                                  <tr className="pl-pack-det-table-row">
                                    <td>Validity in Days</td>
                                    <td className="text-center">{packageInfo?.validity}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer pl-pack-det-modal-footer">
                          <button
                            type="button"
                            className="btn btn-close-btn"
                            data-bs-dismiss="modal" aria-label="Close"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  {searchLoading &&
                    <div className="dot-spinner-area">
                      <div className="dot-spinner">
                        <div className="dot-spinner__dot"></div>
                        <div className="dot-spinner__dot"></div>
                        <div className="dot-spinner__dot"></div>
                        <div className="dot-spinner__dot"></div>
                        <div className="dot-spinner__dot"></div>
                        <div className="dot-spinner__dot"></div>
                        <div className="dot-spinner__dot"></div>
                        <div className="dot-spinner__dot"></div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>

  )
}

export default Packages