import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import $ from 'jquery';
import './JobSearch.css';
import './JobDetail.css';
import './JobSearch-responsive.css';
import LayoutNew from '../../components/LayoutNew';
import { CandidateFooter } from '../../components/CandidateFooter';
import axios from 'axios';
import AuthContext from '../../context/AuthContext';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import TagManager from 'react-gtm-module'

const JobSearch = () => {

    const location = useLocation();
    const [inCommingData, setInCommingData] = useState();
    const [loadingMainUseEffect, setLoadingMainUseEffect] = useState(false);

    const [candToken, setCandToken] = useState("");
    const candidateToken = JSON.parse(localStorage.getItem("candidateToken"))
    const { getClientImg, clientImg, getProtectedData } = useContext(AuthContext);
    const [candidateId, setCandidateId] = useState("");
    const [allJobs, setAllJobs] = useState([]);
    const [matchJobs, setMatchJobs] = useState([]);
    const [latestJob, setLatestJob] = useState([]);
    const [clients, setClients] = useState([])
    const [searchResult, setSearchResult] = useState(true);
    const [filteredSearchResults, setFilteredSearchResults] = useState([]);
    const [filteredSearchResultsMsg, setFilteredSearchResultsMsg] = useState("");
    const [checkBoxfilters, setCheckBoxFilters] = useState([]);
    const [checkBoxJobTitle, setCheckBoxJobTitle] = useState([]);
    const [checkBoxJobLocation, setCheckBoxJobLocation] = useState([]);
    const [checkBoxJobEducation, setCheckBoxJobEducation] = useState([]);

    const [skillArray, setSkillArray] = useState([]);
    const [jobRoleArray, setjobRoleArray] = useState([]);
    const [filteredList, setFilteredList] = useState();
    const [filteredJobTitleList, setFilteredjobTitleList] = useState([]);
    const [filteredLocationList, setFilteredLocationList] = useState([]);
    const [filteredEducationList, setFilteredEducationList] = useState([]);
    const [selectedResults, setSelectedResults] = useState([]);
    const [selectedJobTitleResults, setSelectedJobTitleResults] = useState([]);
    const [selectedLocationResults, setSelectedLocationResults] = useState([]);
    const [selectedEducationResults, setSelectedEducationResults] = useState([]);
    const [appliedJobDetail, setAppliedJobDetail] = useState([]);
    const [searchLoading, setsearchLoading] = useState(false);
    const [resultLoading, setResultLoading] = useState(false);
    const [apiResult, setApiResult] = useState();
    const [filteringData, setFilteringData] = useState();

    const [locationArray, setLocationArray] = useState([]);
    const [educationArray, setEducationArray] = useState([]);
    const [maxHeight, setMaxHeight] = useState(0);

    const [page, setpage] = useState(1);
    const [pageValue, setPageValue] = useState(1);

    const navigate = useNavigate();

    const [x, setX] = useState([0, 25]);

    const [focusedEducationIndex, setFocusedEducationIndex] = useState(-1);
    const resultEducationAreaRef = useRef(null);

    const [focusedLocationIndex, setFocusedLocationIndex] = useState(-1);
    const resultLocationAreaRef = useRef(null);

    const [focusedKeywordIndex, setFocusedKeywordIndex] = useState(-1);
    const resultKeywordAreaRef = useRef(null);

    const [focusedRoleIndex, setFocusedRoleIndex] = useState(-1);
    const resultRoleAreaRef = useRef(null);


    useEffect(() => {
        if (focusedEducationIndex !== -1 && resultEducationAreaRef.current) {
            const focusedEducationElement = resultEducationAreaRef.current.children[focusedEducationIndex];
            if (focusedEducationElement) {
                focusedEducationElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            }
        }
    }, [focusedEducationIndex]);

    useEffect(() => {
        if (focusedLocationIndex !== -1 && resultLocationAreaRef.current) {
            const focusedLocationElement = resultLocationAreaRef.current.children[focusedLocationIndex];
            if (focusedLocationElement) {
                focusedLocationElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            }
        }
    }, [focusedLocationIndex]);

    useEffect(() => {
        if (focusedKeywordIndex !== -1 && resultKeywordAreaRef.current) {
            const focusedKeywordElement = resultKeywordAreaRef.current.children[focusedKeywordIndex];
            if (focusedKeywordElement) {
                focusedKeywordElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            }
        }
    }, [focusedKeywordIndex]);

    useEffect(() => {
        if (focusedRoleIndex !== -1 && resultRoleAreaRef.current) {
            const focusedRoleElement = resultRoleAreaRef.current.children[focusedRoleIndex];
            if (focusedRoleElement) {
                focusedRoleElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            }
        }
    }, [focusedRoleIndex]);

    useEffect(() => {
        const calculateMaxHeight = () => {
            const windowHeight = window.innerHeight;
            const maxHeightPercentage = 0.77;
            const calculatedMaxHeight = windowHeight * maxHeightPercentage;
            setMaxHeight(calculatedMaxHeight);
        };

        calculateMaxHeight();
        window.addEventListener('resize', calculateMaxHeight);

        return () => {
            window.removeEventListener('resize', calculateMaxHeight);
        };
    }, []);

    const [filters, setFilters] = useState({
        searchInput: "",
        jobTitle: "",
        education: "",
        minExperience: "",
        maxExperience: "",
        location: "",
        currencyType: "",
        minSalary: "",
        maxSalary: "",
        keywordMandatoryCheck: false
    })

    //for show success message for payment
    function showSuccessMessage(message) {
        Swal.fire({
            title: 'Success!',
            text: message,
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
        });
    }

    //for show error message for payment
    function showErrorMessage(message) {
        Swal.fire({
            title: 'Error!',
            text: message,
            icon: 'error',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK',
        });
    }

    useEffect(() => {
        // $(document).ready(function () {
        ////change the toggle text and color
        $('.toggleSwitch').change(function () {
            var $label = $(this).closest('.cl-toggle-switch').find('.cl-toggle--switch-label');
            if ($(this).is(':checked')) {
                $label.text('Boolean On').css('color', '#714F36');
            } else {
                $label.text('Boolean Off').css('color', '#B3B3B3');
            }
        });
        ////

        ////for tooltip
        $('.info-icon-button').click(function () {
            // Toggle tooltip display on button click
            $('.tooltip').toggleClass('active');
        });
        ////

        ///for search filter toggle
        function handleFilterToggle() {
            var expandArea = $(this).closest('.cli-tal-pro-search-filter-content-section').find('.cli-tal-pro-search-filter-expand-area');

            if (expandArea.hasClass('opened')) {
                expandArea.slideUp();
                expandArea.removeClass('opened');
                $(this).removeClass('opened');
            } else {
                expandArea.slideDown();
                expandArea.addClass('opened');
                $(this).addClass('opened');
            }
        }
        ////


        ////for view more checkboxes
        var buttons = $(".jobs-view-more-btn");
        buttons.each(function () {
            var button = $(this);
            var hiddenDivs = button.closest(".job-search-multi-check-area").find(".cli--mark-keyword-area.job:nth-child(n+5)");

            button.on("click", function () {
                hiddenDivs.slideToggle(function () {
                    var buttonText = button.find("span");
                    if (hiddenDivs.is(":visible")) {
                        buttonText.text("View Less");
                    } else {
                        buttonText.text("View More");
                    }
                });
            });
        });


        //navigate to top while press buttons
        $(".tal--pro-slider-btn").on("click", function () {
            $("html, body").animate({ scrollTop: 0 }, "slow");
        });

        $(".custom-pagination-page-link").on("click", function () {
            $("html, body").animate({ scrollTop: 0 }, "slow");
        });

        $(".tal--search-submit-btn").on("click", function () {
            $("html, body").animate({ scrollTop: 0 }, "slow");
        });

        $('.cli-tal-pro-search-filter-toggle-area').on('click', handleFilterToggle);

        // Cleanup function to remove event listeners when the component unmounts
        return () => {
            $(".tal--pro-slider-btn").off("click");
            $(".custom-pagination-page-link").off("click");
            $(".tal--search-submit-btn").off("click");
            $('.cli-tal-pro-search-filter-toggle-area').off('click', handleFilterToggle);
        };
        // });
    }, [candidateToken, getClientImg, clientImg, getProtectedData, candidateId, allJobs, matchJobs, latestJob, clients, searchResult, filteredSearchResults, filteredSearchResultsMsg, checkBoxfilters, checkBoxJobTitle, checkBoxJobLocation, checkBoxJobEducation, skillArray, jobRoleArray, filteredList, selectedResults, locationArray, educationArray, filters, x]);

    // const handleSkillSearch = () => {
    //     if (selectedResults.length > 0 || checkBoxfilters.length > 0 || (filters.maxExperience && filters.maxExperience) || selectedJobTitleResults.length > 0 || selectedLocationResults.length > 0 || (filters.minSalary && filters.maxSalary) || selectedEducationResults.length > 0) {
    //         setX([0, 3]);
    //         setFilteredSearchResultsMsg("")
    //         // setAllJobs([])
    //         // setLatestJob([])
    //         // setMatchJobs([])
    //         // setSearchResult(true)
    //         const filteredResults = allJobs
    //             .filter(job => {
    //                 if (selectedResults.length > 0) {
    //                     return selectedResults.some(result =>
    //                         job.skills.includes(result) || job.jobRole.includes(result)
    //                     );
    //                 }
    //                 return true;
    //             })
    //             .filter(job => {
    //                 if (checkBoxfilters.length > 0) {
    //                     return checkBoxfilters.includes(job.jobCategory);
    //                 }
    //                 return true;
    //             })
    //             .filter(job => {
    //                 if (filters.minExperience && filters.maxExperience) {
    //                     return (job.minExperience
    //                         >= filters.minExperience && job.maxExperience <= filters.maxExperience)
    //                 }
    //                 return true;
    //             })
    //             .filter(job => {
    //                 if (selectedJobTitleResults.length > 0) {
    //                     return selectedJobTitleResults.some(result =>
    //                         job.jobRole[0].includes(result));
    //                 }
    //                 return true;
    //             })
    //             .filter(job => {
    //                 if (selectedLocationResults.length > 0) {
    //                     return selectedLocationResults.some(result =>
    //                         job.location.includes(result));
    //                 }
    //                 return true;
    //             })
    //             .filter(job => {
    //                 if (filters.currencyType) {
    //                     return job.currencyType === filters.currencyType
    //                 }
    //                 return true;
    //             })
    //             .filter(job => {
    //                 if (filters.minSalary && filters.maxSalary) {
    //                     return (job.minSalary >= filters.minSalary && job.maxSalary <= filters.maxSalary)
    //                 }
    //                 return true;
    //             })
    //             .filter(job => {
    //                 if (selectedEducationResults.length > 0) {
    //                     return selectedEducationResults.some(result =>
    //                         job.education.includes(result));
    //                 }
    //                 return true;
    //             })

    //         console.log(filteredResults)
    //         if (filteredResults.length > 0) {
    //             setFilteredSearchResults(filteredResults);
    //         } else {
    //             setFilteredSearchResultsMsg("No Jobs found")
    //         }
    //     } else {
    //         showErrorMessage("Choose one filter.")
    //     }
    // };

    useEffect(() => {
        if (filteringData) {
            const filterData = filteringData

            setsearchLoading(true);
            setpage(filteringData.page);
            setFilters({
                ...filters,
                minExperienceYr: filteringData.minExp,
                maxExperienceYr: filteringData.maxExp,
                currencyType: filteringData.currencyType,
                minSalary: filteringData.minSalary,
                maxSalary: filteringData.maxSalary,
                gender: filteringData.gender
            });
            setCheckBoxFilters(filterData.workMode);
            setSelectedResults(filteringData.keyWords);
            setSelectedLocationResults(filteringData.location);
            setSelectedEducationResults(filteringData.education);
            setSelectedJobTitleResults(filteringData.jobRole);

            axios.post(`${process.env.REACT_APP_BASE_API}/filtered-jobs`, filterData)
                .then(res => {
                    console.log(res.data);
                    setApiResult(res.data);
                    setFilteredSearchResults(res.data.data);
                    setsearchLoading(false);
                }).catch(err => {
                    console.log(err);
                    showErrorMessage(err.response.data.error);
                    setsearchLoading(false);
                })
        }
    }, [filteringData])

    const handleSkillSearch = () => {
        const hasFilters = (
            selectedResults.length > 0 ||
            checkBoxfilters.length > 0 ||
            filters.maxExperience ||
            filters.maxExperience ||
            selectedJobTitleResults.length > 0 ||
            selectedLocationResults.length > 0 ||
            (filters.currencyType && (filters.minSalary || filters.maxSalary)) ||
            selectedEducationResults.length > 0 ||
            (filters.keyWords && filters.keyWords.trim() !== '') // Check if keywords are present and not empty

        );
    
        if (hasFilters) {
            setpage(1);
            setPageValue(1);
            setsearchLoading(true);
            setFilteredSearchResults([]); // Clear previous search results
            setFilteredSearchResultsMsg(""); // Clear previous search message
    
            const filterData = {
                page: 1,
                limit: 25,
                keyWords: selectedResults,
                mandatory: filters.keywordMandatoryCheck,
                workMode: checkBoxfilters,
                minExp: filters.minExperience,
                maxExp: filters.maxExperience,
                jobRole: selectedJobTitleResults,
                location: selectedLocationResults,
                currencyType: filters.currencyType,
                minSalary: filters.minSalary,
                maxSalary: filters.maxSalary,
                education: selectedEducationResults
            };
    
            // Check if keywords are present and add to filter data
            if (filters.keyWords) {
                filterData.keyWords = filters.keyWords;
            }
    
            console.log(filterData);
    
            axios.post(`${process.env.REACT_APP_BASE_API}/filtered-jobs`, filterData)
                .then(res => {
                    console.log(res.data);
                    if (res.data.message) {
                        setFilteredSearchResultsMsg(res.data.message);
                    } else {
                        setApiResult(res.data);
                        setFilteredSearchResults(res.data.data);
                        console.log(res.data.data);
                    }
                })
                .catch(err => {
                    console.log(err);
                    showErrorMessage(err.response.data.error);
                })
                .finally(() => {
                    setsearchLoading(false);
                });
        } else {
            showErrorMessage("Choose one filter.");
        }
    };
    

    useEffect(() => {
        if (page) {
            const filterData = {
                page,
                limit: 25,
                ...(candidateId && { candidateId: candidateId }),
                ...((!candidateId && selectedResults.length === 0 && checkBoxfilters.length === 0 && !filters.minExperience && !filters.maxExperience && selectedJobTitleResults.length === 0 && selectedLocationResults.length === 0 && !filters.currencyType && !filters.minSalary && !filters.maxSalary && selectedEducationResults.length === 0) && { all: true }),
                ...(selectedResults.length > 0 && { keyWords: selectedResults }),
                ...(checkBoxfilters.length > 0 && { workMode: checkBoxfilters }),
                ...(filters.minExperience && { minExp: filters.minExperience }),
                ...(filters.maxExperience && { maxExp: filters.maxExperience }),
                ...(selectedJobTitleResults.length > 0 && { jobRole: selectedJobTitleResults }),
                ...(selectedLocationResults.length > 0 && { location: selectedLocationResults }),
                ...(filters.currencyType && { currencyType: filters.currencyType }),
                ...(filters.minSalary && { minSalary: filters.minSalary }),
                ...(filters.maxSalary && { maxSalary: filters.maxSalary }),
                ...(selectedEducationResults.length > 0 && { education: selectedEducationResults })
            }

            setsearchLoading(true);

            axios.post(`${process.env.REACT_APP_BASE_API}/filtered-jobs`, filterData)
                .then(res => {
                    console.log(res.data);
                    setApiResult(res.data);
                    setFilteredSearchResults(res.data.data);
                    setsearchLoading(false);
                }).catch(err => {
                    console.log(err);
                    showErrorMessage(err.response.data.error);
                    setsearchLoading(false);
                })
        }
    }, [page])

    // useEffect(() => {
    //     if (selectedResults.length > 0) {
    //         handleSkillSearch()

    //     //   document.getElementById('searchButton').addEventListener('click', handleSkillSearch);

    //     //   return () => {
    //     //     document.getElementById('searchButton').removeEventListener('click', handleSkillSearch);
    //     //   };
    //     }
    //   }, [selectedResults]); 

    const handleKeywordSearch = () => {
        if (inCommingData && selectedResults) {
            setpage(1);
            setPageValue(1);
            setsearchLoading(true);
            const filterData = {
                page: 1,
                limit: 25,
                keyWords: selectedResults
            }

            axios.post(`${process.env.REACT_APP_BASE_API}/filtered-jobs`, filterData)
                .then(res => {
                    console.log(res.data);
                    if (res.data.message) {
                        setFilteredSearchResultsMsg(res.data.message);
                        setInCommingData(null);
                    } else {
                        if (!loadingMainUseEffect) {
                            setApiResult(res.data);
                            setFilteredSearchResults(res.data.data);
                            setInCommingData(null);
                            setsearchLoading(false);
                        }
                    }
                }).catch(err => {
                    console.log(err);
                    showErrorMessage(err.response.data.error);
                })
        }
    }

    useEffect(() => {
        const { keywords, filterData } = location.state || {};
        keywords && setInCommingData(keywords);
        filterData && setFilteringData(filterData);
        console.log(filterData);
    }, [location.state])

    useEffect(() => {
        if (inCommingData) {
            setSelectedResults(inCommingData);
        }
    }, [inCommingData])

    useEffect(() => {
        handleKeywordSearch();
    }, [selectedResults, allJobs, loadingMainUseEffect]);

    console.log(filters)

    const getAllSkills = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BASE_API}/skills`, {
                headers: {

                    Accept: 'application/json'
                }
            });
            const result = res.data;
            if (!result.error) {
                console.log(result);
                setSkillArray(result);
            } else {
                console.log(result);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getAllJobRoles = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BASE_API}/designations`, {
                headers: {

                    Accept: 'application/json'
                }
            });
            const result = res.data;
            if (!result.error) {
                console.log(result);
                setjobRoleArray(result);
            } else {
                console.log(result);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getAllLocations = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BASE_API}/locations`, {
                headers: {

                    Accept: 'application/json'
                }
            });
            const result = res.data;
            if (!result.error) {
                console.log(result);
                setLocationArray(result);
            } else {
                console.log(result);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getAllEducation = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BASE_API}/educations`, {
                headers: {

                    Accept: 'application/json'
                }
            });
            const result = res.data;
            if (!result.error) {
                console.log(result);
                setEducationArray(result);
            } else {
                console.log(result);
            }
        } catch (err) {
            console.log(err);
        }
    };

    // useEffect(() => {
    //     const token = JSON.parse(localStorage.getItem("candidateToken"))
    //     if (token) {
    //         setCandidateToken(token)
    //     }
    // }, [])

    useEffect(() => {

        const fetchData = async () => {
            try {
                const user = await getProtectedData(candidateToken);
                console.log(user);
                setCandidateId(user.id || user?.responseData.uid);
                setCandToken(user.userToken);
            } catch (error) {
                console.log(error);

            }
        };

        fetchData();


    }, [candidateToken]);

    const getPostedjobs = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BASE_API}/posted-jobs`, {
                headers: {
                    Accept: 'application/json'
                }
            });
            const result = res.data;
            if (!result.error) {
                console.log(result);
                setAllJobs(result);
                setLatestJob(result.slice(0, 10))
            } else {
                console.log(result);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const getSkillMatchJobDetail = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_API}/skill-match-job-Detail/${candidateId}`, {
                headers: {
                    Authorization: `Bearer ${candidateToken || candToken}`,
                    Accept: 'application/json'
                }
            });
            const result = response.data;

            if (!result.error) {
                console.log(result.reverse());
                setMatchJobs(result.reverse().filter(job => job.percentage > 0));
            } else {
                console.log(result);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getAppliedjobs = async () => {
        try {

            const res = await axios.get(`${process.env.REACT_APP_BASE_API}/my-applied-jobs/${candidateId}`, {
                headers: {
                    Authorization: `Bearer ${candidateToken ? candidateToken : candToken}`,
                    Accept: 'application/json'
                }
            });
            const result = res.data;
            if (!result.error) {
                console.log(result);
                setAppliedJobDetail(result);
            } else {
                console.log(result);
            }

        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {

        const getAllJobs = async () => {

            try {
                const filterData = {
                    page: 1,
                    limit: 25,
                    all: true
                }

                setsearchLoading(true);
                const res = await axios.post(`${process.env.REACT_APP_BASE_API}/filtered-jobs`, filterData);
                const result = res.data;
                if (res.status == 200) {
                    setApiResult(res.data);
                    setFilteredSearchResults(res.data.data);
                    setsearchLoading(false);
                    setLoadingMainUseEffect(false);
                }

            } catch (err) {
                console.log(err)
                showErrorMessage(err.response.data.error);
                setsearchLoading(false);
                setLoadingMainUseEffect(false);
            }
        };

        setLoadingMainUseEffect(true);
        // getPostedjobs();
        getClientImg();
        getAllSkills();
        getAllJobRoles();
        getAllLocations();
        getAllEducation();
        getAllJobs();



        axios.get(`${process.env.REACT_APP_BASE_API}/clients`)
            .then(res => {
                console.log(res.data)
                setClients(res.data)
            })
            .catch(err => console.log(err))

    }, [])

    useEffect(() => {
        if (candidateId && !location.state?.keywords && !location.state?.filterData) {
            const filterData = {
                page: 1,
                limit: 25,
                candidateId: candidateId
            }

            setsearchLoading(true);

            axios.post(`${process.env.REACT_APP_BASE_API}/filtered-jobs`, filterData)
                .then(res => {
                    console.log(res.data);
                    setApiResult(res.data);
                    setFilteredSearchResults(res.data.data);
                    setsearchLoading(false);
                }).catch(err => {
                    console.log(err);
                    showErrorMessage(err.response.data.error);
                    setsearchLoading(false);
                })
            getAppliedjobs();
        }
    }, [candidateId])



    const handleCheckboxChange = (category) => {
        const updatedFilters = checkBoxfilters.includes(category)
            ? checkBoxfilters.filter((filter) => filter !== category)
            : [...checkBoxfilters, category];
        setCheckBoxFilters(updatedFilters);
    };

    // const handleCheckboxJobTitleChange = (category) => {
    //     const updatedFilters = checkBoxJobTitle.includes(category)
    //       ? checkBoxJobTitle.filter((filter) => filter !== category)
    //       : [...checkBoxJobTitle, category];
    //     setCheckBoxJobTitle(updatedFilters);
    // };

    // const handleCheckboxJobLocationChange = (category) => {
    //     const updatedFilters = checkBoxJobLocation.includes(category)
    //       ? checkBoxJobLocation.filter((filter) => filter !== category)
    //       : [...checkBoxJobLocation, category];
    //     setCheckBoxJobLocation(updatedFilters);
    // };

    // const handleCheckboxJobEducationChange = (category) => {
    //     const updatedFilters = checkBoxJobEducation.includes(category)
    //       ? checkBoxJobEducation.filter((filter) => filter !== category)
    //       : [...checkBoxJobEducation, category];
    //     setCheckBoxJobEducation(updatedFilters); 
    // };

    // const handleSearch = (e) => {
    //     const inputValue = e.target.value.trim();
    //     setFilters({ ...filters, searchInput: inputValue });

    //     if (inputValue.length > 0) {
    //         const lowerCaseInput = inputValue.toLowerCase();

    //         const skillsObj = skillArray.filter((obj) => {
    //             return obj.skill.toLowerCase().startsWith(lowerCaseInput) && obj.skill.length <= 40;
    //         });

    //         const jobRolesObj = jobRoleArray.filter((obj) => {
    //             return obj.designation.toLowerCase().startsWith(lowerCaseInput) && obj.designation.length <= 40;
    //         });

    //         const skills = skillsObj.map(skill => skill.skill);
    //         const jobRoles = jobRolesObj.map(jobRole => jobRole.designation);

    //         function combineArraysUnique(arr1, arr2) {
    //             const combinedSet = new Set([...arr1, ...arr2]);
    //             return Array.from(combinedSet);
    //         }

    //         const combinedResults = combineArraysUnique(skills, jobRoles);

    //         if (combinedResults.length > 0) {
    //             setFilteredList(combinedResults);
    //         } else {
    //             setFilteredList([]);
    //         }
    //     } else {
    //         setFilteredList([]);
    //     }
    // };


    const handleSearch = (e) => {
        const inputValue = e.target.value;
        setFilters({ ...filters, searchInput: inputValue });

        if (inputValue.length > 0) {
            setFocusedKeywordIndex(0);
        } else {
            setFocusedKeywordIndex(-1);
        }
    };

    useEffect(() => {
        const fetchResults = async () => {
            try {
                const keyword = {
                    keyWord: filters.searchInput
                };
                setResultLoading(true);
                const response = await axios.post(`${process.env.REACT_APP_BASE_API}/find-keyword-results`, keyword);
                if (response.data.length === 0) {
                    setFilteredList([]);
                } else {
                    setFilteredList(response.data);
                    setResultLoading(false);
                }
            } catch (error) {
                console.log(error);
                setFilteredList([]);
            }
        };

        if (filters.searchInput.trim() !== '') {
            fetchResults();
        } else if (filters.searchInput === '') {
            setFilteredList([]);
        }
    }, [filters.searchInput]);

    const handleFilteredClick = (clickResult) => {
        console.log(clickResult)
        if (selectedResults.includes(clickResult)) {
            setSelectedResults([...selectedResults]);
            setFilters({ ...filters, searchInput: "" });
            setFilteredList([]);

        } else {
            setSelectedResults([...selectedResults, clickResult]);
            setFilters({ ...filters, searchInput: "" });
            setFilteredList([]);
        }
    }

    const handleDeselect = (result) => {
        setSelectedResults(selectedResults.filter(selected => selected !== result));

    }


    const handleJobTitleSearch = (e) => {
        const inputValue = e.target.value;
        setFilters({ ...filters, jobTitle: inputValue });

        if (inputValue.length > 0) {
            const jobTitles = jobRoleArray.filter((obj) => {
                return obj.designation.toLowerCase().includes(inputValue.toLowerCase());
            });

            if (jobTitles.length > 0) {
                setFilteredjobTitleList(jobTitles);
                setFocusedRoleIndex(0);
            } else {
                setFilteredjobTitleList([]);
                setFocusedRoleIndex(-1);
            }
        } else {
            setFilteredjobTitleList([]);
            setFocusedRoleIndex(-1);
        }
    };

    const handleJobTitleFilteredClick = (clickResult) => {
        console.log(clickResult)
        if (selectedJobTitleResults.includes(clickResult)) {
            setSelectedJobTitleResults([...selectedJobTitleResults]);
            setFilters({ ...filters, jobTitle: "" });
            setFilteredjobTitleList([]);

        } else {
            setSelectedJobTitleResults([...selectedJobTitleResults, clickResult]);
            setFilters({ ...filters, jobTitle: "" });
            setFilteredjobTitleList([]);
        }
    }

    const handleJobTitleDeselect = (result) => {
        setSelectedJobTitleResults(selectedJobTitleResults.filter(selected => selected !== result));
    }

    const handleLocationSearch = (e) => {
        const inputValue = e.target.value;
        setFilters({ ...filters, location: inputValue });

        if (inputValue.length > 0) {
            const locations = locationArray.filter((obj) => {
                return obj.location.toLowerCase().includes(inputValue.toLowerCase());
            });

            if (locations.length > 0) {
                setFilteredLocationList(locations);
                setFocusedLocationIndex(0);
            } else {
                setFilteredLocationList([]);
                setFocusedLocationIndex(-1);
            }
        } else {
            setFilteredLocationList([]);
            setFocusedLocationIndex(-1);
        }
    };

    const handleLocationFilteredClick = (clickResult) => {
        console.log(clickResult)
        if (selectedLocationResults.includes(clickResult)) {
            setSelectedLocationResults([...selectedLocationResults]);
            setFilters({ ...filters, location: "" });
            setFilteredLocationList([]);

        } else {
            setSelectedLocationResults([...selectedLocationResults, clickResult]);
            setFilters({ ...filters, location: "" });
            setFilteredLocationList([]);
        }
    }

    const handleLocationDeselect = (result) => {
        setSelectedLocationResults(selectedLocationResults.filter(selected => selected !== result));
    }

    const handleEducationSearch = (e) => {
        const inputValue = e.target.value;
        setFilters({ ...filters, education: inputValue });

        if (inputValue.length > 0) {
            const educations = educationArray.filter((obj) => {
                return obj.education.toLowerCase().includes(inputValue.toLowerCase());
            });

            if (educations.length > 0) {
                setFilteredEducationList(educations);
                setFocusedEducationIndex(0);
            } else {
                setFilteredEducationList([]);
                setFocusedEducationIndex(-1);
            }
        } else {
            setFilteredEducationList([]);
            setFocusedEducationIndex(-1);
        }
    };

    const handleEducationFilteredClick = (clickResult) => {
        console.log(clickResult)
        if (selectedEducationResults.includes(clickResult)) {
            setSelectedEducationResults([...selectedEducationResults]);
            setFilters({ ...filters, education: "" });
            setFilteredEducationList([]);

        } else {
            setSelectedEducationResults([...selectedEducationResults, clickResult]);
            setFilters({ ...filters, education: "" });
            setFilteredEducationList([]);
        }
    }

    const handleEducationDeselect = (result) => {
        setSelectedEducationResults(selectedEducationResults.filter(selected => selected !== result));
    }

    //////////////////////
    const handleKeywordKeyDown = (e) => {
        if (filteredList.length === 0) return;

        switch (e.key) {
            case 'ArrowUp':
                e.preventDefault();
                setFocusedKeywordIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
                break;
            case 'ArrowDown':
                e.preventDefault();
                setFocusedKeywordIndex((prevIndex) => (prevIndex < filteredList.length - 1 ? prevIndex + 1 : prevIndex));
                break;
            case 'Enter':
                if (focusedKeywordIndex !== -1) {
                    handleFilteredClick(filteredList[focusedKeywordIndex]);
                }
                break;
            default:
                break;
        }
    };

    const handleRoleKeyDown = (e) => {
        if (filteredJobTitleList.length === 0) return;

        switch (e.key) {
            case 'ArrowUp':
                e.preventDefault();
                setFocusedRoleIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
                break;
            case 'ArrowDown':
                e.preventDefault();
                setFocusedRoleIndex((prevIndex) => (prevIndex < filteredJobTitleList.length - 1 ? prevIndex + 1 : prevIndex));
                break;
            case 'Enter':
                if (focusedRoleIndex !== -1) {
                    handleJobTitleFilteredClick(filteredJobTitleList[focusedRoleIndex].designation);
                }
                break;
            default:
                break;
        }
    };

    const handleLocationKeyDown = (e) => {
        if (filteredLocationList.length === 0) return;

        switch (e.key) {
            case 'ArrowUp':
                e.preventDefault();
                setFocusedLocationIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
                break;
            case 'ArrowDown':
                e.preventDefault();
                setFocusedLocationIndex((prevIndex) => (prevIndex < filteredLocationList.length - 1 ? prevIndex + 1 : prevIndex));
                break;
            case 'Enter':
                if (focusedLocationIndex !== -1) {
                    handleLocationFilteredClick(filteredLocationList[focusedLocationIndex].location);
                }
                break;
            default:
                break;
        }
    };

    const handleEducationKeyDown = (e) => {
        if (filteredEducationList.length === 0) return;

        switch (e.key) {
            case 'ArrowUp':
                e.preventDefault();
                setFocusedEducationIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
                break;
            case 'ArrowDown':
                e.preventDefault();
                setFocusedEducationIndex((prevIndex) => (prevIndex < filteredEducationList.length - 1 ? prevIndex + 1 : prevIndex));
                break;
            case 'Enter':
                if (focusedEducationIndex !== -1) {
                    handleEducationFilteredClick(filteredEducationList[focusedEducationIndex].education);
                }
                break;
            default:
                break;
        }
    };

    const handleChangePage = (event) => {
        setPageValue(parseInt(event.target.value));
    };

    const handlePageSubmit = (event) => {
        event.preventDefault();
        const totalPages = apiResult?.totalPages || 1;
        const newPage = Math.min(Math.max(1, pageValue), totalPages);
        setpage(newPage);

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    function formatSalary(salary) {
        if (salary >= 1000000) {
            return (salary / 1000000).toFixed(1) + 'M';
        } else if (salary >= 1000) {
            return (salary / 1000).toFixed(0) + 'K';
        } else {
            return salary;
        }
    }

    const renderPageNumbers = () => {
        const totalPages = apiResult?.totalPages;
        const currentPage = apiResult?.currentPage;
        const maxPagesToShow = 5;

        if (!totalPages) return null;

        const pages = [];
        let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
        let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        if (endPage - startPage + 1 < maxPagesToShow) {
            startPage = Math.max(1, endPage - maxPagesToShow + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <li key={i} className={`page-item custom-pagination-page-item ${currentPage === i ? 'active' : ''}`}>
                    <a className="page-link custom-pagination-page-link"
                        onClick={() => {
                            setpage(i);
                            setPageValue(i);
                        }}
                        href="#">{i}</a>
                </li>
            );
        }

        return pages;
    };

    useEffect(() => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'pageview',
            userProject: 'skillity',
            page: `job-search`
          }
        });
      }, []);


    return (
        <div>
            <LayoutNew searchJob={true} />
            <div className='cli--tal-pro-search-section'>
                <div className='container-fluid'>
                    <div className='container-fluid container-section'>
                        <div className="custom--container tal--pro-search">
                            <div className="breadcrumb--area-dark" data-aos="fade-down">
                                <div className="breadcrumb--item-dark">
                                    <a href="/">Home</a>
                                </div>
                                <div className="breadcrumb--item-dark">
                                    <p>Search Jobs</p>
                                </div>
                            </div>

                            {!searchResult ?
                                <div className='talent--profile-search-page-section'>
                                    <p className='job-search-head'>Search For Jobs</p>

                                    <div className="row row-border-custom job">
                                        <div className="col-12 col-lg-4 col-xl-4 col-md-4 custom-right-border-col">
                                            <div className="cli-tal-pro-search-filter-area">
                                                <div className="cli-tal-pro-search-filter-head-area justify-content-center gap-3">
                                                    <h6 className='cli-tal-pro-search-filter mb-0'>Filters</h6>
                                                    <img src="assets/img/talent-profile/filter.png" className='cli-tal-pro-filter-img' alt="" />
                                                </div>
                                                <div className="cli-tal-pro-search-filter-container with-custom-scroll">

                                                    <div className="cli-tal-pro-search-filter-content-section job">

                                                        <div className="cli-tal-pro-search-filter-content">
                                                            <div className="cli-tal-pro-search-filter-title-area">
                                                                <h6 className='cli-tal-pro-search-filter-title'>Keywords</h6>
                                                            </div>
                                                            <div className="cli--tal-pro-filter-input-area">
                                                                <input type="text" className='cli--tal-pro-filter-input' placeholder='Enter keywords like skills, designation'
                                                                    value={filters.searchInput}
                                                                    onChange={(e) => setFilters({ ...filters, searchInput: e.target.value })} />
                                                                <i className="bi bi-search cli--tal-pro-filter-search-icon"></i>
                                                            </div>

                                                            <div className="cli--mark-keyword-area">
                                                                <label className="cli--mark-keyword-check-input">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={filters.keywordMandatoryCheck}
                                                                        onChange={() => setFilters({ ...filters, keywordMandatoryCheck: !filters.keywordMandatoryCheck })}
                                                                    />
                                                                    <span className="cli--mark-keyword-checkmark"></span>
                                                                    Mark all keywords as mandatory
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="cli-tal-pro-search-filter-content-section job">
                                                        <div className="cli-tal-pro-search-filter-toggle-area job">
                                                            <h6 className='cli--emploment-detail-head job'>Work mode</h6>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className='' width="15" height="9" viewBox="0 0 15 9" fill="none">
                                                                <path d="M1 1L6.79289 6.79289C7.18342 7.18342 7.81658 7.18342 8.20711 6.79289L14 1" stroke="#714F36" stroke-width="2" stroke-linecap="round" />
                                                            </svg>
                                                        </div>
                                                        <div className="cli-tal-pro-search-filter-expand-area">
                                                            <div className="job-search-check-area">
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" checked={checkBoxfilters.includes('full time')}
                                                                            onChange={() => handleCheckboxChange('full time')} />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Full time
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" checked={checkBoxfilters.includes('part time')}
                                                                            onChange={() => handleCheckboxChange('part time')} />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Part time
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" checked={checkBoxfilters.includes('contract')}
                                                                            onChange={() => handleCheckboxChange('contract')} />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Contract
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" checked={checkBoxfilters.includes('freelancer')}
                                                                            onChange={() => handleCheckboxChange('freelancer')} />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        freelancer
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="cli-tal-pro-search-filter-content-section job">
                                                        <div className="cli-tal-pro-search-filter-toggle-area job">
                                                            <h6 className='cli--emploment-detail-head job'>Experience</h6>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className='' width="15" height="9" viewBox="0 0 15 9" fill="none">
                                                                <path d="M1 1L6.79289 6.79289C7.18342 7.18342 7.81658 7.18342 8.20711 6.79289L14 1" stroke="#714F36" stroke-width="2" stroke-linecap="round" />
                                                            </svg>
                                                        </div>
                                                        <div className="cli-tal-pro-search-filter-expand-area">
                                                            <div className="cli-tal-pro-exp-input-area search-results">
                                                                <input type="number" className='cli-tal-pro-exp-input text-center' placeholder='Min Experience' value={filters.minExperience}
                                                                    onChange={(e) => setFilters({ ...filters, minExperience: e.target.value })} />
                                                                <span className='cli-tal-pro-exp-input-text'>to</span>
                                                                <input type="number" className='cli-tal-pro-exp-input text-center' placeholder='Max Experience' value={filters.maxExperience}
                                                                    onChange={(e) => setFilters({ ...filters, maxExperience: e.target.value })} />
                                                                <span className='cli-tal-pro-exp-input-text'>months/years</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="cli-tal-pro-search-filter-content-section job">
                                                        <div className="cli-tal-pro-search-filter-toggle-area job">
                                                            <h6 className='cli--emploment-detail-head job'>Job Title</h6>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className='' width="15" height="9" viewBox="0 0 15 9" fill="none">
                                                                <path d="M1 1L6.79289 6.79289C7.18342 7.18342 7.81658 7.18342 8.20711 6.79289L14 1" stroke="#714F36" stroke-width="2" stroke-linecap="round" />
                                                            </svg>
                                                        </div>
                                                        <div className="cli-tal-pro-search-filter-expand-area">
                                                            <div className="job-search-multi-check-area">
                                                                {/* {jobRoleArray.map((job)=>{
                                                                        return <div                              className="cli--mark-keyword-area job">
                                                                                <label className="cli--mark-keyword-check-input jobs">
                                                                                    <input type="checkbox" checked={checkBoxJobTitle.includes(job.jobRole[0])}
                                                                                    onChange={() => handleCheckboxJobTitleChange(job.jobRole[0])}/>
                                                                                    <span className="cli--mark-keyword-checkmark"></span>
                                                                                    {job.jobRole[0]}
                                                                                </label>
                                                                        </div>
                                                                    })} */}

                                                                {/* <div className="cli--mark-keyword-area job">
                                                                        <label className="cli--mark-keyword-check-input jobs">
                                                                            <input type="checkbox" />
                                                                            <span className="cli--mark-keyword-checkmark"></span>
                                                                            UX,Design & Archie.........(82)
                                                                        </label>
                                                                    </div>
                                                                    <div className="cli--mark-keyword-area job">
                                                                        <label className="cli--mark-keyword-check-input jobs">
                                                                            <input type="checkbox" />
                                                                            <span className="cli--mark-keyword-checkmark"></span>
                                                                            UX,Design & Archie.........(82)
                                                                        </label>
                                                                    </div>
                                                                    <div className="cli--mark-keyword-area job">
                                                                        <label className="cli--mark-keyword-check-input jobs">
                                                                            <input type="checkbox" />
                                                                            <span className="cli--mark-keyword-checkmark"></span>
                                                                            UX,Design & Archie.........(82)
                                                                        </label>
                                                                    </div>


                                                                    <div className="cli--mark-keyword-area job">
                                                                        <label className="cli--mark-keyword-check-input jobs">
                                                                            <input type="checkbox" />
                                                                            <span className="cli--mark-keyword-checkmark"></span>
                                                                            UX,Design & Archie.........(82)
                                                                        </label>
                                                                    </div>
                                                                    <div className="cli--mark-keyword-area job">
                                                                        <label className="cli--mark-keyword-check-input jobs">
                                                                            <input type="checkbox" />
                                                                            <span className="cli--mark-keyword-checkmark"></span>
                                                                            UX,Design & Archie.........(82)
                                                                        </label>
                                                                    </div>
                                                                    <div className="cli--mark-keyword-area job">
                                                                        <label className="cli--mark-keyword-check-input jobs">
                                                                            <input type="checkbox" />
                                                                            <span className="cli--mark-keyword-checkmark"></span>
                                                                            UX,Design & Archie.........(82)
                                                                        </label>
                                                                    </div>
                                                                    <div className="cli--mark-keyword-area job">
                                                                        <label className="cli--mark-keyword-check-input jobs">
                                                                            <input type="checkbox" />
                                                                            <span className="cli--mark-keyword-checkmark"></span>
                                                                            UX,Design & Archie.........(82)
                                                                        </label>
                                                                    </div>
                                                                    <div className="cli--mark-keyword-area job">
                                                                        <label className="cli--mark-keyword-check-input jobs">
                                                                            <input type="checkbox" />
                                                                            <span className="cli--mark-keyword-checkmark"></span>
                                                                            UX,Design & Archie.........(82)
                                                                        </label>
                                                                    </div> */}
                                                                <button className="jobs-view-more-btn">
                                                                    <span>View more</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="cli-tal-pro-search-filter-content-section job">
                                                        <div className="cli-tal-pro-search-filter-toggle-area job">
                                                            <h6 className='cli--emploment-detail-head job'>Location</h6>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className='' width="15" height="9" viewBox="0 0 15 9" fill="none">
                                                                <path d="M1 1L6.79289 6.79289C7.18342 7.18342 7.81658 7.18342 8.20711 6.79289L14 1" stroke="#714F36" stroke-width="2" stroke-linecap="round" />
                                                            </svg>
                                                        </div>
                                                        <div className="cli-tal-pro-search-filter-expand-area">
                                                            <div className="job-search-multi-check-area">
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Delhi/NCR (76)
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Hyderabad (87)
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Delhi/NCR (76)
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Hyderabad (87)
                                                                    </label>
                                                                </div>


                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Delhi/NCR (76)
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Hyderabad (87)
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Delhi/NCR (76)
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Hyderabad (87)
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Delhi/NCR (76)
                                                                    </label>
                                                                </div>
                                                                <button className="jobs-view-more-btn">
                                                                    <span>View more</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="cli-tal-pro-search-filter-content-section job">
                                                        <div className="cli-tal-pro-search-filter-toggle-area job">
                                                            <h6 className='cli--emploment-detail-head job'>Salary</h6>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className='' width="15" height="9" viewBox="0 0 15 9" fill="none">
                                                                <path d="M1 1L6.79289 6.79289C7.18342 7.18342 7.81658 7.18342 8.20711 6.79289L14 1" stroke="#714F36" stroke-width="2" stroke-linecap="round" />
                                                            </svg>
                                                        </div>
                                                        <div className="cli-tal-pro-search-filter-expand-area">
                                                            <div className="cli-tal-pro-exp-input-area search-results salary-input-container">
                                                                <div className="cli--salary-inputs-area">
                                                                    <select name="" className='cli-tal-pro-select-input width-30' id="">
                                                                        <option value="" disabled>Select</option>
                                                                        <option value="1" selected>INR</option>
                                                                        <option value="2">LKR</option>
                                                                        <option value="3">USD</option>
                                                                        <option value="4">GBP</option>
                                                                    </select>
                                                                    <input type="number" className='cli-tal-pro-exp-input width-70' placeholder='Min salary' />
                                                                </div>
                                                                <span className='cli-tal-pro-exp-input-text'>to</span>
                                                                <input type="number" className='cli-tal-pro-exp-input text-center width-45 search-results' placeholder='Max salary' />
                                                                {/* <span className='cli-tal-pro-exp-input-text'>lacs</span> */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="cli-tal-pro-search-filter-content-section job">
                                                        <div className="cli-tal-pro-search-filter-toggle-area job">
                                                            <h6 className='cli--emploment-detail-head job'>Education</h6>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className='' width="15" height="9" viewBox="0 0 15 9" fill="none">
                                                                <path d="M1 1L6.79289 6.79289C7.18342 7.18342 7.81658 7.18342 8.20711 6.79289L14 1" stroke="#714F36" stroke-width="2" stroke-linecap="round" />
                                                            </svg>
                                                        </div>
                                                        <div className="cli-tal-pro-search-filter-expand-area">
                                                            <div className="job-search-multi-check-area">
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Any postgraduate
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        M.tech
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        B.tech
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Any graduate
                                                                    </label>
                                                                </div>


                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Any postgraduate
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        M.tech
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        B.tech
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Any graduate
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Any postgraduate
                                                                    </label>
                                                                </div>
                                                                <button className="jobs-view-more-btn">
                                                                    <span>View more</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="clear--all_button-area dash">
                                                        {/* <button className='tal--search-submit-btn'>Submit</button> */}
                                                        <button className='clear--all_button dash'>
                                                            Clear all
                                                        </button>
                                                        <button className="tal--search-submit-btn dash" onClick={handleSkillSearch}>
                                                            Search Jobs
                                                        </button>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        <div className="col-12 col-lg-8 col-xl-8 col-md-8 custom-border-top-sm">
                                            <div className="tal--pro-search-result-image-area">
                                                <img src="assets/img/jobs/filter-data-img-2.png" className='tal--pro-search-result-image' alt="" data-aos="fade" />
                                                <h6 className='tal--pro-search-result-title' data-aos="fade-up">Add Filter for the desired search</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div> :

                                <div className='talent--profile-search-results-section'>
                                    {/* <div className="cli-tal-pro-search-container">
                                            <div className="row">
                                                <div className="col-12 col-lg-12 col-xl-12 col-md-12">
                                                    <h4 class="company--heading candidate" data-aos="fade-left">
                                                        <span>Jobs</span> that need <br />
                                                        <span>Immediate Joiners</span>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div> */}

                                    {/* <button class="pl--package-btn-sub previous back-to-search-btn"  onClick={()=>setSearchResult(false)}>
                                            <div class="pl--package-arrow-area prev">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 27 27" fill="none"><path d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832" stroke="white" stroke-width="2"></path><path d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162" stroke="white" stroke-width="2"></path><path d="M1 26L25.1667 1" stroke="white" stroke-width="2"></path></svg>
                                            </div>
                                            <div class="pl--package-btn job">Back to Search
                                            </div>
                                        </button> */}

                                    <div className="row">
                                        <div className="col-12 col-lg-4 my-auto">
                                            <p className='job-search-head'>Job Search</p>
                                        </div>
                                        <div className="col-12 col-lg-8 mx-auto">
                                            <div className="cli-tal-pro-search-filter-content">
                                                <div className="cli-tal-pro-search-filter-title-area">
                                                    <h6 className='lg-font'>Keywords</h6>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12 col-sm-9 col-xxl-10">
                                                        <div className="cli--tal-pro-filter-input-area">

                                                            <div className="container_input_section with-more-radius">
                                                                <div className="container_search_icon_area">
                                                                    <i className="bi bi-search"></i>
                                                                </div>
                                                                <div className="container-input-area3">

                                                                    {selectedResults.length > 0 && (
                                                                        <>
                                                                            {selectedResults.map(selectResult => (
                                                                                <span className="form__badge2 candidate"
                                                                                    key={selectResult}
                                                                                ><span>{selectResult}</span>
                                                                                    <i className='bi bi-x' onClick={() => handleDeselect(selectResult)}></i>
                                                                                </span>
                                                                            ))}
                                                                        </>
                                                                    )}

                                                                    <div className='position-relative container__input_section'>
                                                                        <div className="container__input_with_label">
                                                                            <input type="search" className='container__input2'
                                                                                name='searcgKeywordInput'
                                                                                id='searcgKeywordInput'
                                                                                value={filters.searchInput}
                                                                                onChange={handleSearch}
                                                                                onKeyDown={handleKeywordKeyDown} />
                                                                            <label htmlFor="searcgKeywordInput" className={`container__input_label2 small-text ${filters.searchInput ? 'd-none' : ''}`}>Enter keywords like skills, designation</label>
                                                                        </div>

                                                                        {resultLoading &&
                                                                            <div className='tal-pro-search-result-data-area'>
                                                                                <div className='text-secondary text-center pl-2 pr-2 pt-3 pb-3 results-load-area'>Results loading. Please wait...</div>
                                                                            </div>
                                                                        }

                                                                        {filters.searchInput &&
                                                                            <div className='tal-pro-search-result-data-area' ref={resultKeywordAreaRef}>
                                                                                {Array.isArray(filteredList) &&
                                                                                    filteredList.length > 0 ?
                                                                                    filteredList.map((filterResult, index) => (
                                                                                        <div
                                                                                            className={`tal-pro-search-result-data candidate ${index === focusedKeywordIndex ? 'focused' : ''}`}
                                                                                            key={index}
                                                                                            onClick={() => handleFilteredClick(filterResult)}
                                                                                        >
                                                                                            {filterResult}
                                                                                        </div>
                                                                                    )) : null
                                                                                }
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-3 col-xxl-2 mt-3 mt-sm-0">
                                                        <button className='btn search_job-btn text-no-wrap' id="searchButton" onClick={handleSkillSearch}>Search Jobs</button>
                                                    </div>
                                                </div>

                                                <div className="cli--mark-keyword-area">
                                                    <label className="cli--mark-keyword-check-input">
                                                        <input
                                                            type="checkbox"
                                                            checked={filters.keywordMandatoryCheck}
                                                            onChange={() => setFilters({ ...filters, keywordMandatoryCheck: !filters.keywordMandatoryCheck })}
                                                        />
                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                        Mark all keywords as mandatory
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row row-border-custom">
                                        <div className="col-12 col-lg-4 col-xl-4 col-md-4 custom-right-border-col ps-lg-0 ps-md-1 col-width-lg-30 sticky--sidebar-area">
                                            <div className="cli-tal-pro-search-filter-area sticky--sidebar without-padding">
                                                <div className="cli-tal-pro-search-filter-head-area search-results">
                                                    <h6 className='cli-tal-pro-search-filter mb-0'>Filters</h6>
                                                    <img src="assets/img/talent-profile/filter.png" className='cli-tal-pro-filter-img' alt="" />
                                                </div>
                                                <div className="cli-tal-pro-search-filter-container with-custom-scroll mt-4"
                                                    style={{
                                                        maxHeight: `${maxHeight}px`,
                                                        overflowY: 'auto',
                                                        paddingRight: '10px'
                                                    }}>

                                                    {/* <div className="cli-tal-pro-search-filter-content-section job">

                                                        <div className="cli-tal-pro-search-filter-content">
                                                            <div className="cli-tal-pro-search-filter-title-area">
                                                                <h6 className='cli-tal-pro-search-filter-title'>Keywords</h6>
                                                            </div>

                                                            <div className="cli--tal-pro-filter-input-area">

                                                                <div className="container_input_section">
                                                                    <div className="container_search_icon_area">
                                                                        <i className="bi bi-search"></i>
                                                                    </div>
                                                                    <div className="container-input-area3">

                                                                        {selectedResults.length > 0 && (
                                                                            <>
                                                                                {selectedResults.map(selectResult => (
                                                                                    <span className="form__badge2 candidate"
                                                                                        key={selectResult}
                                                                                    ><span>{selectResult}</span>
                                                                                        <i className='bi bi-x' onClick={() => handleDeselect(selectResult)}></i>
                                                                                    </span>
                                                                                ))}
                                                                            </>
                                                                        )}

                                                                        <div className='position-relative container__input_section'>
                                                                            <div className="container__input_with_label">
                                                                                <input type="search" className='container__input2'
                                                                                    name='searcgKeywordInput'
                                                                                    id='searcgKeywordInput'
                                                                                    value={filters.searchInput}
                                                                                    onChange={handleSearch}
                                                                                    onKeyDown={handleKeywordKeyDown} />
                                                                                <label htmlFor="searcgKeywordInput" className={`container__input_label2 small-text ${filters.searchInput ? 'd-none' : ''}`}>Enter keywords like skills, designation</label>
                                                                            </div>

                                                                            {resultLoading &&
                                                                                <div className='tal-pro-search-result-data-area'>
                                                                                    <div className='text-secondary text-center pl-2 pr-2 pt-3 pb-3 results-load-area'>Results loading. Please wait...</div>
                                                                                </div>
                                                                            }

                                                                            {filters.searchInput &&
                                                                                <div className='tal-pro-search-result-data-area' ref={resultKeywordAreaRef}>
                                                                                    {Array.isArray(filteredList) &&
                                                                                        filteredList.length > 0 ?
                                                                                        filteredList.map((filterResult, index) => (
                                                                                            <div
                                                                                                className={`tal-pro-search-result-data candidate ${index === focusedKeywordIndex ? 'focused' : ''}`}
                                                                                                key={index}
                                                                                                onClick={() => handleFilteredClick(filterResult)}
                                                                                            >
                                                                                                {filterResult}
                                                                                            </div>
                                                                                        )) : null
                                                                                    }
                                                                                </div>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="cli--mark-keyword-area">
                                                                <label className="cli--mark-keyword-check-input">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={filters.keywordMandatoryCheck}
                                                                        onChange={() => setFilters({ ...filters, keywordMandatoryCheck: !filters.keywordMandatoryCheck })}
                                                                    />
                                                                    <span className="cli--mark-keyword-checkmark"></span>
                                                                    Mark all keywords as mandatory
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div> */}

                                                    <div className="cli-tal-pro-search-filter-content-section job">
                                                        <div className="cli-tal-pro-search-filter-toggle-area job">
                                                            <h6 className='cli--emploment-detail-head job'>Work mode</h6>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className='' width="15" height="9" viewBox="0 0 15 9" fill="none">
                                                                <path d="M1 1L6.79289 6.79289C7.18342 7.18342 7.81658 7.18342 8.20711 6.79289L14 1" stroke="#714F36" stroke-width="2" stroke-linecap="round" />
                                                            </svg>
                                                        </div>
                                                        <div className="cli-tal-pro-search-filter-expand-area job">
                                                            <div className="job-search-check-area">
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" checked={checkBoxfilters.includes('full time')}
                                                                            onChange={() => handleCheckboxChange('full time')} />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Full time
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" checked={checkBoxfilters.includes('part time')}
                                                                            onChange={() => handleCheckboxChange('part time')} />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Part time
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" checked={checkBoxfilters.includes('contract')}
                                                                            onChange={() => handleCheckboxChange('contract')} />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Contract
                                                                    </label>
                                                                </div>
                                                                <div className="cli--mark-keyword-area job">
                                                                    <label className="cli--mark-keyword-check-input jobs">
                                                                        <input type="checkbox" checked={checkBoxfilters.includes('freelancer')}
                                                                            onChange={() => handleCheckboxChange('freelancer')} />
                                                                        <span className="cli--mark-keyword-checkmark"></span>
                                                                        Freelancer
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="cli-tal-pro-search-filter-content-section job">
                                                        <div className="cli-tal-pro-search-filter-toggle-area job">
                                                            <h6 className='cli--emploment-detail-head job'>Experience</h6>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className='' width="15" height="9" viewBox="0 0 15 9" fill="none">
                                                                <path d="M1 1L6.79289 6.79289C7.18342 7.18342 7.81658 7.18342 8.20711 6.79289L14 1" stroke="#714F36" stroke-width="2" stroke-linecap="round" />
                                                            </svg>
                                                        </div>
                                                        <div className="cli-tal-pro-search-filter-expand-area job">
                                                            <div className="cli-tal-pro-exp-input-area search-results">
                                                                <select name="" className='cli-tal-pro-exp-input text-center select' id=""
                                                                    value={filters.minExperience}
                                                                    onChange={(e) => setFilters({ ...filters, minExperience: e.target.value })}
                                                                >
                                                                    <option value="" selected >Minimum Experience</option>
                                                                    <option value="0">0</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                    <option value="15">15</option>
                                                                    <option value="20">20</option>
                                                                    <option value="25">25</option>
                                                                    <option value="30">30</option>
                                                                    <option value="35">35</option>
                                                                    <option value="40">40</option>
                                                                    <option value="45">45</option>
                                                                    <option value="50">50</option>
                                                                </select>
                                                                <span className='cli-tal-pro-exp-input-text'>to</span>
                                                                <select name="" className='cli-tal-pro-exp-input text-center select' id=""
                                                                    value={filters.maxExperience}
                                                                    onChange={(e) => setFilters({ ...filters, maxExperience: e.target.value })}
                                                                >
                                                                    <option value="" selected >Max Experience</option>
                                                                    <option value="0">0</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                    <option value="15">15</option>
                                                                    <option value="20">20</option>
                                                                    <option value="25">25</option>
                                                                    <option value="30">30</option>
                                                                    <option value="35">35</option>
                                                                    <option value="40">40</option>
                                                                    <option value="45">45</option>
                                                                    <option value="50">50</option>
                                                                </select>
                                                                <span className='cli-tal-pro-exp-input-text'>years</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className="cli-tal-pro-search-filter-content-section job">
                                                        <div className="cli-tal-pro-search-filter-toggle-area job">
                                                            <h6 className='cli--emploment-detail-head job'>Job Title</h6>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className='' width="15" height="9" viewBox="0 0 15 9" fill="none">
                                                                <path d="M1 1L6.79289 6.79289C7.18342 7.18342 7.81658 7.18342 8.20711 6.79289L14 1" stroke="#714F36" stroke-width="2" stroke-linecap="round" />
                                                            </svg>
                                                        </div>
                                                        <div className="cli-tal-pro-search-filter-expand-area job">
                                                            
                                                            <div className="cli--tal-pro-filter-input-area">

                                                                <div className="container_input_section">
                                                                    <div className="container_search_icon_area">
                                                                        <i className="bi bi-search"></i>
                                                                    </div>
                                                                    <div className="container-input-area3">

                                                                        {selectedJobTitleResults.length > 0 && (
                                                                            <>
                                                                                {selectedJobTitleResults.map(selectResult => (
                                                                                    <span className="form__badge2 candidate"
                                                                                        key={selectResult}
                                                                                    ><span>{selectResult}</span>
                                                                                        <i className='bi bi-x' onClick={() => handleJobTitleDeselect(selectResult)}></i>
                                                                                    </span>
                                                                                ))}
                                                                            </>
                                                                        )}

                                                                        <div className='position-relative container__input_section'>
                                                                            <div className="container__input_with_label">
                                                                                <input type="search" className='container__input2'
                                                                                    name='searcgJobtitleInput'
                                                                                    id='searcgJobtitleInput'
                                                                                    value={filters.jobTitle}
                                                                                    onChange={handleJobTitleSearch}
                                                                                    onKeyDown={handleRoleKeyDown} />
                                                                                <label htmlFor="searcgJobtitleInput" className={`container__input_label2 small-text ${filters.jobTitle ? 'd-none' : ''}`}>Search Job title</label>
                                                                            </div>

                                                                            <div className='tal-pro-search-result-data-area' ref={resultRoleAreaRef}>
                                                                                {filteredJobTitleList.length > 0 &&
                                                                                    filteredJobTitleList.map((filterResult, index) => (
                                                                                        <div
                                                                                            className={`tal-pro-search-result-data candidate ${index === focusedRoleIndex ? 'focused' : ''}`}
                                                                                            key={filterResult._id}
                                                                                            onClick={() => handleJobTitleFilteredClick(filterResult.designation)}
                                                                                        >
                                                                                            {filterResult.designation}
                                                                                        </div>
                                                                                    ))}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                               

                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    <div className="cli-tal-pro-search-filter-content-section job">
                                                        <div className="cli-tal-pro-search-filter-toggle-area job">
                                                            <h6 className='cli--emploment-detail-head job'>Location</h6>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className='' width="15" height="9" viewBox="0 0 15 9" fill="none">
                                                                <path d="M1 1L6.79289 6.79289C7.18342 7.18342 7.81658 7.18342 8.20711 6.79289L14 1" stroke="#714F36" stroke-width="2" stroke-linecap="round" />
                                                            </svg>
                                                        </div>

                                                        <div className="cli-tal-pro-search-filter-expand-area job">
                                                            {/* <div className="job-search-multi-check-area">
                                                                    {locationArray.map((loc)=>{ 
                                                                        return  <div                              className="cli--mark-keyword-area job">
                                                                                <label className="cli--mark-keyword-check-input jobs">
                                                                                    <input type="checkbox" checked={checkBoxJobLocation.includes(loc.location)}
                                                                                    onChange={() => handleCheckboxJobLocationChange(loc.location)}/>
                                                                                    <span className="cli--mark-keyword-checkmark"></span>
                                                                                    {loc.location}
                                                                                </label> 
                                                                        </div>
                                                                    })}
                                                                    <button className="jobs-view-more-btn">
                                                                        <span>View more</span>
                                                                    </button>
                                                                </div> */}

                                                            {/* {selectedLocationResults.length > 0 && (
                                                                <div className='cli--tal-pro-badge-area mb-4'>
                                                                    {selectedLocationResults.map(selectResult => (
                                                                        <span className="tal-cand-reg-form-badge candidate"
                                                                            key={selectResult}
                                                                            onClick={() => handleLocationDeselect(selectResult)}
                                                                        >{selectResult}</span>
                                                                    ))}
                                                                </div>
                                                            )} */}

                                                            <div className="cli--tal-pro-filter-input-area">

                                                                <div className="container_input_section">
                                                                    <div className="container_search_icon_area">
                                                                        <i className="bi bi-search"></i>
                                                                    </div>
                                                                    <div className="container-input-area3">

                                                                        {selectedLocationResults.length > 0 && (
                                                                            <>
                                                                                {selectedLocationResults.map(selectResult => (
                                                                                    <span className="form__badge2 candidate"
                                                                                        key={selectResult}
                                                                                    ><span>{selectResult}</span>
                                                                                        <i className='bi bi-x' onClick={() => handleLocationDeselect(selectResult)}></i>
                                                                                    </span>
                                                                                ))}
                                                                            </>
                                                                        )}

                                                                        <div className='position-relative container__input_section'>
                                                                            <div className="container__input_with_label">
                                                                                <input type="search" className='container__input2'
                                                                                    name='searchLocationInput'
                                                                                    id='searchLocationInput'
                                                                                    value={filters.location}
                                                                                    onChange={handleLocationSearch}
                                                                                    onKeyDown={handleLocationKeyDown} />
                                                                                <label htmlFor="searchLocationInput" className={`container__input_label2 small-text ${filters.location ? 'd-none' : ''}`}>Search location</label>
                                                                            </div>

                                                                            <div className='tal-pro-search-result-data-area' ref={resultLocationAreaRef}>
                                                                                {filteredLocationList.length > 0 &&
                                                                                    filteredLocationList.map((filterResult, index) => (
                                                                                        <div
                                                                                            className={`tal-pro-search-result-data candidate ${index === focusedLocationIndex ? 'focused' : ''}`}
                                                                                            key={filterResult._id}
                                                                                            onClick={() => handleLocationFilteredClick(filterResult.location)}
                                                                                        >
                                                                                            {filterResult.location}
                                                                                        </div>
                                                                                    ))}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* <input type="search" className='cli--tal-pro-filter-input' placeholder='Enter location'
                                                                    value={filters.location}
                                                                    onChange={handleLocationSearch}
                                                                />
                                                                <i className="bi bi-search cli--tal-pro-filter-search-icon"></i>
                                                                <div className='tal-pro-search-result-data-area'>
                                                                    {filteredLocationList.length > 0 &&
                                                                        filteredLocationList.map((filterResult) => (
                                                                            <div
                                                                                className='tal-pro-search-result-data candidate'
                                                                                key={filterResult._id}
                                                                                onClick={() => handleLocationFilteredClick(filterResult.location)}
                                                                            >
                                                                                {filterResult.location}
                                                                            </div>
                                                                        ))}
                                                                </div> */}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="cli-tal-pro-search-filter-content-section job">
                                                        <div className="cli-tal-pro-search-filter-toggle-area job">
                                                            <h6 className='cli--emploment-detail-head job'>Salary</h6>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className='' width="15" height="9" viewBox="0 0 15 9" fill="none">
                                                                <path d="M1 1L6.79289 6.79289C7.18342 7.18342 7.81658 7.18342 8.20711 6.79289L14 1" stroke="#714F36" stroke-width="2" stroke-linecap="round" />
                                                            </svg>
                                                        </div>
                                                        <div className="cli-tal-pro-search-filter-expand-area job">
                                                            <div className="cli-tal-pro-exp-input-area search-results salary-input-container">
                                                                <div className="cli--salary-inputs-area">
                                                                    <select name="" className='cli-tal-pro-select-input width-30' id=""
                                                                        value={filters.currencyType}
                                                                        onChange={(e) => setFilters({ ...filters, currencyType: e.target.value })}>
                                                                        <option value="" disabled>Select</option>
                                                                        <option value="₹" >₹</option>
                                                                        <option value="$">$</option>
                                                                        <option value="€">€</option>
                                                                    </select>
                                                                    <input type="number" className='cli-tal-pro-exp-input width-70' placeholder='Min salary'
                                                                        value={filters.minSalary}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value.slice(0, 8);
                                                                            setFilters({
                                                                                ...filters,
                                                                                minSalary: value
                                                                            });
                                                                        }}
                                                                        onKeyPress={(e) => {
                                                                            if (e.key === 'e' || e.key === 'E') {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                        maxLength={8} />
                                                                </div>
                                                                <span className='cli-tal-pro-exp-input-text'>to</span>
                                                                <input type="number" className='cli-tal-pro-exp-input text-center width-45 search-page' placeholder='Max salary'
                                                                    value={filters.maxSalary}
                                                                    onChange={(e) => {
                                                                        const value = e.target.value.slice(0, 8);
                                                                        setFilters({
                                                                            ...filters,
                                                                            maxSalary: value
                                                                        });
                                                                    }}
                                                                    onKeyPress={(e) => {
                                                                        if (e.key === 'e' || e.key === 'E') {
                                                                            e.preventDefault();
                                                                        }
                                                                    }}
                                                                    maxLength={8} />
                                                                {/* <span className='cli-tal-pro-exp-input-text'>laks</span> */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="cli-tal-pro-search-filter-content-section job">
                                                        <div className="cli-tal-pro-search-filter-toggle-area job">
                                                            <h6 className='cli--emploment-detail-head job'>Education</h6>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className='' width="15" height="9" viewBox="0 0 15 9" fill="none">
                                                                <path d="M1 1L6.79289 6.79289C7.18342 7.18342 7.81658 7.18342 8.20711 6.79289L14 1" stroke="#714F36" stroke-width="2" stroke-linecap="round" />
                                                            </svg>
                                                        </div>
                                                        <div className="cli-tal-pro-search-filter-expand-area job">
                                                            {/* <div className="job-search-multi-check-area">
                                                                    {educationArray.map((edu)=>{ 
                                                                        return  <div                              className="cli--mark-keyword-area job">
                                                                                <label className="cli--mark-keyword-check-input jobs">
                                                                                    <input type="checkbox" checked={checkBoxJobEducation.includes(edu.education)}
                                                                                    onChange={() => handleCheckboxJobEducationChange(edu.education)}/>
                                                                                    <span className="cli--mark-keyword-checkmark"></span>
                                                                                    {edu.education}
                                                                                </label> 
                                                                        </div>
                                                                    })}
                                                                    <button className="jobs-view-more-btn">
                                                                        <span>View more</span>
                                                                    </button>
                                                                </div> */}

                                                            {/* {selectedEducationResults.length > 0 && (
                                                                <div className='cli--tal-pro-badge-area mb-4'>
                                                                    {selectedEducationResults.map(selectResult => (
                                                                        <span className="tal-cand-reg-form-badge candidate"
                                                                            key={selectResult}
                                                                            onClick={() => handleEducationDeselect(selectResult)}
                                                                        >{selectResult}</span>
                                                                    ))}
                                                                </div>
                                                            )} */}


                                                            <div className="cli--tal-pro-filter-input-area">

                                                                <div className="container_input_section">
                                                                    <div className="container_search_icon_area">
                                                                        <i className="bi bi-search"></i>
                                                                    </div>
                                                                    <div className="container-input-area3">

                                                                        {selectedEducationResults.length > 0 && (
                                                                            <>
                                                                                {selectedEducationResults.map(selectResult => (
                                                                                    <span className="form__badge2 candidate"
                                                                                        key={selectResult}
                                                                                    ><span>{selectResult}</span>
                                                                                        <i className='bi bi-x' onClick={() => handleEducationDeselect(selectResult)}></i>
                                                                                    </span>
                                                                                ))}
                                                                            </>
                                                                        )}

                                                                        <div className='position-relative container__input_section'>
                                                                            <div className="container__input_with_label">
                                                                                <input type="search" className='container__input2'
                                                                                    name='searchEducationInput'
                                                                                    id='searchEducationInput'
                                                                                    value={filters.education}
                                                                                    onChange={handleEducationSearch}
                                                                                    onKeyDown={handleEducationKeyDown} />
                                                                                <label htmlFor="searchEducationInput" className={`container__input_label2 small-text ${filters.education ? 'd-none' : ''}`}>Search education</label>
                                                                            </div>

                                                                            <div className='tal-pro-search-result-data-area' ref={resultEducationAreaRef}>
                                                                                {filteredEducationList.length > 0 &&
                                                                                    filteredEducationList.map((filterResult, index) => (
                                                                                        <div
                                                                                            className={`tal-pro-search-result-data candidate ${index === focusedEducationIndex ? 'focused' : ''}`}
                                                                                            key={filterResult._id}
                                                                                            onClick={() => handleEducationFilteredClick(filterResult.education)}
                                                                                        >
                                                                                            {filterResult.education}
                                                                                        </div>
                                                                                    ))}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* <input type="search" className='cli--tal-pro-filter-input' placeholder='Enter education'
                                                                    value={filters.education}
                                                                    onChange={handleEducationSearch}
                                                                />
                                                                <i className="bi bi-search cli--tal-pro-filter-search-icon"></i>
                                                                <div className='tal-pro-search-result-data-area'>
                                                                    {filteredEducationList.length > 0 &&
                                                                        filteredEducationList.map((filterResult) => (
                                                                            <div
                                                                                className='tal-pro-search-result-data candidate'
                                                                                key={filterResult._id}
                                                                                onClick={() => handleEducationFilteredClick(filterResult.education)}
                                                                            >
                                                                                {filterResult.education}
                                                                            </div>
                                                                        ))}
                                                                </div> */}

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="clear--all_button-area">
                                                        <button className='tal--search-submit-btn' id="searchButton" onClick={handleSkillSearch}>Search Jobs</button>
                                                        <button className='clear--all_button' onClick={() => {
                                                            window.location.reload()
                                                            // setInCommingData(null)
                                                        }}>
                                                            Clear all
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-lg-8 col-xl-8 col-md-8 pe-lg-0 pe-md-1 col-width-lg-70">
                                            {/* <div className="tal--pro-search-result-image-area">
                                                <img src="assets/img/jobs/filter-data-img.png" className='tal--pro-search-result-image' alt="" data-aos="fade"  />
                                                <h6 className='tal--pro-search-result-title' data-aos="fade-up">Add Filter for the desired search</h6>
                                            </div> */}
                                            <div className="cli--tal-pro-search-results-area">
                                                {apiResult && <div className="page__number_area">
                                                    <h6> No. of total jobs :{" "}
                                                        <span>
                                                            {filteredSearchResultsMsg ? "0" : apiResult?.totalJobs}
                                                        </span>
                                                    </h6>
                                                </div>}
                                                {filteredSearchResultsMsg ?
                                                    (
                                                        <div className="no-data-created-area">
                                                            <div className="no-data-created">
                                                                <img
                                                                    src="../assets/img/no-data/no-data-img.png"
                                                                    className="no-data-img"
                                                                    alt=""
                                                                />
                                                                <div className="no-data-text">
                                                                    {filteredSearchResultsMsg}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) :
                                                    filteredSearchResults.length > 0 ?
                                                        (filteredSearchResults.map((job) => {
                                                            const matchingImg = clientImg ? clientImg.find(img => img.id === job.companyId) : null;
                                                            const imgSrc = matchingImg ? matchingImg.image : "../assets/img/talents-images/avatar.jpg";
                                                            const companyName = clients.find(cli => cli.companyId === job.companyId)?.companyName

                                                            const calculateMatchPercentage = (skills1, skills2) => {
                                                                const matchingSkills = skills2.filter(skill => skills1.includes(skill));
                                                                return (matchingSkills.length / skills1.length) * 100;
                                                            }

                                                            const jobSkills = job.skills.map(skill => skill.toLowerCase());

                                                            const percentage = Math.round(calculateMatchPercentage(selectedResults, [...jobSkills, ...job.jobRole[0].toLowerCase()]));
                                                            const isApplied = appliedJobDetail.find(appJob => appJob.jobId === job.id);
                                                            return (
                                                                <article className='job--detail-card mb-4'>
                                                                    <div className="job--detail-card-top-area job">
                                                                        <div>
                                                                            <h5 className='job--detail-card-role'>{job.jobRole[0]}</h5>
                                                                            <div className="job--detail-card-review-area">
                                                                                <div className="job--detail-card-review">{companyName}</div>
                                                                                {/* <div className='job--detail-card-rating'>
                                                                        <i class="ri-star-fill"></i>
                                                                        <span>4.9</span>
                                                                    </div>
                                                                    <div className="job--detail-card-review-count">
                                                                        879&nbsp;
                                                                        <span>Reviews</span>
                                                                    </div> */}
                                                                            </div>

                                                                            <div className="job--detail-card-location-area">
                                                                                <div className="job--detail-card-experience">
                                                                                    <i class='bx bx-briefcase'></i>
                                                                                    <span>{job?.minExperience} - {job.maxExperience} years</span>
                                                                                </div>
                                                                                {/* <div className="job--detail-card-experience">
                                                                        <i class='bx bx-rupee'></i>
                                                                        <span>Not disclosed</span>
                                                                    </div> */}
                                                                                <div className="job--detail-card-experience">
                                                                                    <i class="bi bi-geo-alt-fill"></i>
                                                                                    <span>{job?.location.join(", ")}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex-reverse-md'>
                                                                            {selectedResults.length > 0 && job.percentage &&
                                                                                <div className="tal--pro-card-ability-number-left mobile-flex mb-3">
                                                                                    <h6 className='tal--pro-card-ability search'>Keywords matched</h6>
                                                                                    {/* <h2 className='tal--pro-card-percentage search custom-font-size'>{Array.isArray(job.skills) ? Math.round(percentage) : "0"}%</h2> */}
                                                                                    <h2 className='tal--pro-card-percentage search custom-font-size'>{job.percentage}%</h2>
                                                                                </div>}
                                                                            <div className="job--detail-card-img-area job">
                                                                                <img src={imgSrc} className='job--detail-card-img' alt="" />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="job--detail-card-desc-area">
                                                                        {/* <p className='job--detail-card-desc'>{job.jobDescription}</p> */}
                                                                        <div className="customize-job-description" dangerouslySetInnerHTML={{ __html: job?.jobDescription.replace(/<[^>]+>/g, '').substring(0, 300) + "..." }} />
                                                                    </div>
                                                                    <div className="job--detail-card-bottom-area">
                                                                        {Array.isArray(job.skills) && <div className='job--detail-card-tags-area'>
                                                                            {job?.skills.map((skill, index) => {
                                                                                return <div className="job--detail-card-tag" key={index}>{skill}</div>
                                                                            })}
                                                                        </div>}
                                                                        <div className="job--detail-card-know-more-btn-area"
                                                                        // onClick={() => {
                                                                        //     let filterData = null;
                                                                        //     if (selectedResults.length > 0 || checkBoxfilters.length > 0 || filters.minExperience || filters.maxExperience || filters.currencyType || selectedJobTitleResults.length > 0 || selectedLocationResults.length > 0 || filters.minSalary || filters.maxSalary || selectedEducationResults.length > 0) {
                                                                        //         filterData = {
                                                                        //             page,
                                                                        //             limit: 25,
                                                                        //             keyWords: selectedResults,
                                                                        //             workMode: checkBoxfilters,
                                                                        //             minExp: filters.minExperience,
                                                                        //             maxExp: filters.maxExperience,
                                                                        //             currencyType: filters.currencyType,
                                                                        //             jobRole: selectedJobTitleResults,
                                                                        //             location: selectedLocationResults,
                                                                        //             minSalary: filters.minSalary,
                                                                        //             maxSalary: filters.maxSalary,
                                                                        //             education: selectedEducationResults
                                                                        //         };
                                                                        //     }

                                                                        //     if (candidateToken || candToken) {
                                                                        //         navigate(`/job-detail/${job.id}`);
                                                                        //     } else if (filterData) {
                                                                        //         navigate("/candidate-login", { state: { filterData } });
                                                                        //     } else {
                                                                        //         navigate("/candidate-login");
                                                                        //     }
                                                                        // }}
                                                                        >
                                                                            <a href={(candidateToken || candToken) ? `/job-detail/${job.id}` : `/job-detail/${job.id}?wl=${true}`} className='job--detail-card-know-more-btn'>{isApplied ? "Applied" : "View Job"}</a>
                                                                        </div>

                                                                    </div>
                                                                </article>
                                                            )
                                                        })) : matchJobs.length > 0 ?
                                                            (matchJobs.map((job) => {
                                                                const matchingImg = clientImg ? clientImg.find(img => img.id === job.companyId) : null;
                                                                const imgSrc = matchingImg ? matchingImg.image : "../assets/img/talents-images/avatar.jpg";
                                                                const companyName = clients.find(cli => cli.companyId === job.companyId)?.companyName
                                                                const isApplied = appliedJobDetail.find(appJob => appJob.jobId === job.id);
                                                                return (
                                                                    <article className='job--detail-card mb-4'>
                                                                        <div className="job--detail-card-top-area job">
                                                                            <div>
                                                                                <h5 className='job--detail-card-role'>{job.jobRole[0]}</h5>
                                                                                <div className="job--detail-card-review-area">
                                                                                    <div className="job--detail-card-review">{companyName}</div>
                                                                                    {/* <div className='job--detail-card-rating'>
                                                                        <i class="ri-star-fill"></i>
                                                                        <span>4.9</span>
                                                                    </div>
                                                                    <div className="job--detail-card-review-count">
                                                                        879&nbsp;
                                                                        <span>Reviews</span>
                                                                    </div> */}
                                                                                </div>

                                                                                <div className="job--detail-card-location-area">
                                                                                    <div className="job--detail-card-experience">
                                                                                        <i class='bx bx-briefcase'></i>
                                                                                        <span>{job?.jobExperience}</span>
                                                                                    </div>
                                                                                    {/* <div className="job--detail-card-experience">
                                                                        <i class='bx bx-rupee'></i>
                                                                        <span>Not disclosed</span>
                                                                    </div> */}
                                                                                    <div className="job--detail-card-experience">
                                                                                        <i class="bi bi-geo-alt-fill"></i>
                                                                                        <span>{job?.jobLocation.join(", ")}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='flex-reverse-md'>
                                                                                <div className="tal--pro-card-ability-number-left mobile-flex  mb-3">
                                                                                    <h6 className='tal--pro-card-ability search'>Skills matched</h6>
                                                                                    <h2 className='tal--pro-card-percentage search custom-font-size'>{job?.percentage}%</h2>
                                                                                </div>
                                                                                <div className="job--detail-card-img-area job">
                                                                                    <img src={imgSrc} className='job--detail-card-img' alt="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="job--detail-card-desc-area">
                                                                            <p className='job--detail-card-desc'>{job.jobDescription}</p>
                                                                        </div>
                                                                        <div className="job--detail-card-bottom-area">
                                                                            <div className='job--detail-card-tags-area'>
                                                                                {job?.jobMandatorySkills.map((skill, index) => {
                                                                                    return <div className="job--detail-card-tag" key={index}>{skill}</div>
                                                                                })}
                                                                            </div>
                                                                            <div className="job--detail-card-know-more-btn-area">
                                                                                <a href={`/job-detail/${job.jobId}`} className='job--detail-card-know-more-btn'>{isApplied ? "Applied" : "View Job"}</a>
                                                                            </div>
                                                                        </div>
                                                                    </article>
                                                                )
                                                            })) : (latestJob.map((job) => {
                                                                const matchingImg = clientImg ? clientImg.find(img => img.id === job.companyId) : null;
                                                                const imgSrc = matchingImg ? matchingImg.image : "../assets/img/talents-images/avatar.jpg";
                                                                const companyName = clients.find(cli => cli.companyId === job.companyId)?.companyName

                                                                return (
                                                                    <article className='job--detail-card mb-4'>
                                                                        <div className="job--detail-card-top-area job">
                                                                            <div>
                                                                                <h5 className='job--detail-card-role'>{job.jobRole[0]}</h5>
                                                                                <div className="job--detail-card-review-area">
                                                                                    <div className="job--detail-card-review">{companyName}</div>
                                                                                    {/* <div className='job--detail-card-rating'>
                                                                        <i class="ri-star-fill"></i>
                                                                        <span>4.9</span>
                                                                    </div>
                                                                    <div className="job--detail-card-review-count">
                                                                        879&nbsp;
                                                                        <span>Reviews</span>
                                                                    </div> */}
                                                                                </div>

                                                                                <div className="job--detail-card-location-area">
                                                                                    <div className="job--detail-card-experience">
                                                                                        <i class='bx bx-briefcase'></i>
                                                                                        <span>{job?.minExperience} - {job.maxExperience} years</span>
                                                                                    </div>
                                                                                    {/* <div className="job--detail-card-experience">
                                                                        <i class='bx bx-rupee'></i>
                                                                        <span>Not disclosed</span>
                                                                    </div> */}
                                                                                    <div className="job--detail-card-experience">
                                                                                        <i class="bi bi-geo-alt-fill"></i>
                                                                                        <span>{job?.location.join(", ")}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="job--detail-card-img-area job">
                                                                                <img src={imgSrc} className='job--detail-card-img' alt="" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="job--detail-card-desc-area">
                                                                            <p className='job--detail-card-desc'>{job.jobDescription}</p>
                                                                        </div>
                                                                        <div className="job--detail-card-bottom-area">
                                                                            <div className='job--detail-card-tags-area'>
                                                                                {job?.skills.map((skill, index) => {
                                                                                    return <div className="job--detail-card-tag" key={index}>{skill}</div>
                                                                                })}
                                                                            </div>
                                                                            <div className="job--detail-card-know-more-btn-area">
                                                                                <a href={`/job-detail/${job.id}`} className='job--detail-card-know-more-btn'>View Job</a>
                                                                            </div>
                                                                        </div>
                                                                    </article>
                                                                )
                                                            }))}

                                                {/* <div className="tal--pro-paginate-btn-area" data-aos="fade-up">
                                                    <h6 className='tal--pro-total-result-text'>Total jobs : <span>{filteredSearchResultsMsg ? "0" : apiResult?.totalJobs}</span></h6>
                                                    <div className='tal--pro-slider-btn-sub'>
                                                        {(apiResult?.currentPage !== 1 && apiResult?.totalPages !== 1) && <button className="tal--pro-slider-btn" onClick={() => setpage(page - 1)}>
                                                            <svg className='arrow-left' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 27 27" fill="none">
                                                                <path d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832" stroke="#5C3B2E" stroke-width="2" />
                                                                <path d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162" stroke="#5C3B2E" stroke-width="2" />
                                                                <path d="M1 26L25.1667 1" stroke="#5C3B2E" stroke-width="2" />
                                                            </svg>
                                                        </button>}
                                                        {(apiResult?.currentPage !== apiResult?.totalPages) && < button className="tal--pro-slider-btn" onClick={() => setpage(page + 1)}>
                                                            <svg className='arrow-right' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 27 27" fill="none">
                                                                <path d="M2.56641 3.44987C6.17752 6.50543 15.5664 10.4499 24.2331 1.7832" stroke="#5C3B2E" stroke-width="2" />
                                                                <path d="M24.5618 1.45996C21.07 4.6512 15.9586 13.4593 23.4473 23.162" stroke="#5C3B2E" stroke-width="2" />
                                                                <path d="M1 26L25.1667 1" stroke="#5C3B2E" stroke-width="2" />
                                                            </svg>
                                                        </button>}
                                                    </div>
                                                </div> */}

                                                {(!filteredSearchResultsMsg && apiResult) &&
                                                    <div className="table-pagination-area" data-aos="fade-up">
                                                        <nav aria-label="Page navigation example pt-5">
                                                            <ul class="pagination justify-content-start">
                                                                <li className={`page-item ${(apiResult?.currentPage !== 1 && apiResult?.totalPages !== 1) ? "" : "disabled"} `}>
                                                                    <a className="page-link custom-pagination-page-link" href="#"
                                                                        onClick={() => {
                                                                            setpage(page - 1);
                                                                            setPageValue(page - 1);
                                                                        }}
                                                                    >Previous</a>
                                                                </li>

                                                                {renderPageNumbers()}

                                                                <li className={`page-item ${(apiResult?.currentPage !== apiResult?.totalPages) ? "" : "disabled"}`}>
                                                                    <a className="page-link custom-pagination-page-link" href="#"
                                                                        onClick={() => {
                                                                            setpage(page + 1);
                                                                            setPageValue(page + 1);
                                                                        }}
                                                                    >Next</a>
                                                                </li>
                                                            </ul>
                                                        </nav>

                                                        <div className="custom-pagination-input-area">
                                                            <p>Page</p>
                                                            <form onSubmit={handlePageSubmit}>
                                                                <input
                                                                    type="number"
                                                                    className="form-control custom-pagination-input"
                                                                    value={pageValue}
                                                                    min={1}
                                                                    max={apiResult?.totalPages || 1}
                                                                    onChange={handleChangePage}
                                                                />
                                                                <button type="submit" className="d-none">Submit</button>
                                                            </form>
                                                            <p>of {apiResult?.totalPages}</p>
                                                        </div>

                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>}

                            {searchLoading &&
                                <div className="dot-spinner-area">
                                    <div className="dot-spinner">
                                        <div className="dot-spinner__dot"></div>
                                        <div className="dot-spinner__dot"></div>
                                        <div className="dot-spinner__dot"></div>
                                        <div className="dot-spinner__dot"></div>
                                        <div className="dot-spinner__dot"></div>
                                        <div className="dot-spinner__dot"></div>
                                        <div className="dot-spinner__dot"></div>
                                        <div className="dot-spinner__dot"></div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <CandidateFooter />
        </div>
    )
}
export default JobSearch;